import axios from 'axios';

const baseUrl = 'https://buzzycashapi.myserviceagent.africa'; // Staging API base URL
//const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklkIjoiZGNhNDcyNWYtZmJhMS00YWY5LWFhOTAtOTc1OGE0ODAwYjdiIiwiaWF0IjoxNzAzMTIwMTQzLCJleHAiOjE3MDMxMjM3NDN9.BBA-R0apI94vdNdbFnijLIRHGEB8-4uzFOo9L7GT-hU';

export const login = async (email: string, password: string) => {
  const loginEndpoint = '/auth/login';                                                 // Endpoint for login API request
  const loginUrl = `${baseUrl}${loginEndpoint}`; // Full URL for the login request

  try {
    const response = await axios.post(loginUrl, {
      email: email,
      password: password,
    });

    // Handle the response from the API
    if (response.status === 200) {
      // Authentication successful, handle the response data here
      return response.data;
    } else {
      // Authentication failed, handle the error response here
      throw new Error('Authentication failed');
    }
  } catch (error) {
    // Handle errors that occur during the authentication process
    console.error('Authentication error:', error);
    throw error;
  }
};

export const forgotPassword = async (email: string) => {
  const resetPasswordEndpoint = '/auth/forget-password';                                                     // Endpoint for password reset API request
  const resetPasswordUrl = `${baseUrl}${resetPasswordEndpoint}`; // Full URL for the password reset request

  try {
    const response = await axios.post(resetPasswordUrl, {
      email: email,
    });

    // Handle the response from the API
    if (response.status === 200) {
      // Password reset request successful, handle the response data here
      return response.data;
    } else {
      // Password reset request failed, handle the error response here
      throw new Error('Password reset request failed');
    }
  } catch (error) {
    // Handle errors that occur during the password reset process
    console.error('Password reset error:', error);
    throw error;
  }
};

export const verifyCode = async (code: string) => {
  const verifyCodeEndpoint = '/auth/verify-otp';                                                           // Endpoint for code verification API request
  const verifyCodeUrl = `${baseUrl}${verifyCodeEndpoint}`; // Full URL for the code verification request

  try {
    const response = await axios.post(verifyCodeUrl, {
      code: code,
    });

    // Handle the response from the API
    if (response.status === 200) {
      // Code verification successful, handle the response data here
      return response.data;
    } else {
      // Code verification failed, handle the error response here
      throw new Error('Code verification failed');
    }
  } catch (error) {
    // Handle errors that occur during the code verification process
    console.error('Code verification error:', error);
    throw error;
  }
};

export const changePassword = async (newPassword: string) => {
  const changePasswordEndpoint = '/auth/change-password';                                                      // Endpoint for change password API request
  const changePasswordUrl = `${baseUrl}${changePasswordEndpoint}`; // Full URL for the change password request

  try {
    const response = await axios.post(changePasswordUrl, {
      newPassword: newPassword,
    });

    // Handle the response from the API
    if (response.status === 200) {
      // Password change successful, handle the response data here if needed
      return response.data;
    } else {
      // Password change failed, handle the error response here
      throw new Error('Password change failed');
    }
  } catch (error) {
    // Handle errors that occur during the password change process
    console.error('Password change error:', error);
    throw error;
  }
};

export const getSingleUser = async (userId: string) => {
  const endpoint = `/admin/user/${userId}`;                                                         // Endpoint for fetching a single user
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching single user:', error);
    throw error;
  }
};

export const getTotalSubscribers = async () => {
  const endpoint = '/admin/total-subscribers'; // Endpoint for total subscribers API request
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching total subscribers:', error);
    throw error;
  }
};

export const getTotalUnsubscribers = async () => {
  const endpoint = '/admin/total-unsubscribers';                                                 // Endpoint for total unsubscribers API request
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching total unsubscribers:', error);
    throw error;
  }
};

export const getTotalSubscribersLast7Days = async () => {
  const endpoint = '/admin/subscribers/last-7-days';                                         // Endpoint for total subscribers in the past 7 days API request
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching total subscribers in the past 7 days:', error);
    throw error;
  }
};

export const getTotalUnsubscribersLast7Days = async () => {
  const endpoint = '/admin/unsubscribers/last-7-days';                                       // Endpoint for total unsubscribers in the past 7 days API request
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching total unsubscribers in the past 7 days:', error);
    throw error;
  }
};


export const getActiveGames = async () => {
  const endpoint = '/games/total-active-games';                                             // Endpoint for active games API request
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching active games:', error);
    throw error;
  }
};

export const getTotalRevenue = async () => {
  const endpoint = '/admin/total-revenue?startDate=2023-01-01&endDate=2023-01-15';          // Endpoint for total revenue API request
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching total revenue:', error);
    throw error;
  }
};


export const getRevenueLast7Days = async () => {
  const endpoint = '/revenue-last-7-days';                                                // Endpoint for revenue in the past 7 days API request
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching revenue in the past 7 days:', error);
    throw error;
  }
};

export const getTodaysRevenue = async () => {
  const endpoint = '/admin/daily-data?startDate=2022-01-01&endDate=2023-09-30';                          // Endpoint for today's revenue API request
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching today\'s revenue:', error);
    throw error;
  }
};

export const getScheduledDraws = async () => {
  const endpoint = '/check-scheduled-draws';                                              // Endpoint to check scheduled draws API request
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching scheduled draws:', error);
    throw error;
  }
};

export const getAllUsers = async () => {
  const endpoint = '/admin/users';                                                                 // Endpoint for fetching all users
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching all users:', error);
    throw error;
  }
};

export const getDailyDataSyncRecords = async () => {
  const endpoint = '/admin/daily-datasync-records';                                                 // Endpoint for fetching daily datasync records
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching daily datasync records:', error);
    throw error;
  }
};

export const getMainDataSyncRecords = async () => {
  const endpoint = '/admin/datasync-records';                                                       // Endpoint for fetching main datasync records
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching main datasync records:', error);
    throw error;
  }
};

export const performInstantDraw = async () => {
  const endpoint = '/admin/instant-draw/006886bd-bbe1-456e-8186-66095ca7934f';                              // Endpoint for performing an instant draw
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.post(url);
    return response.data;
  } catch (error) {
    console.error('Error performing instant draw:', error);
    throw error;
  }
};

export const scheduleDraw = async (drawDetails: any, scheduledDateTime: string) => {
  const endpoint = '/admin/schedule-draw';                                                                 // Endpoint for scheduling a draw
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.post(url, {
      drawDetails: drawDetails,
      scheduledDateTime: scheduledDateTime,
    });

    return response.data;
  } catch (error) {
    console.error('Error scheduling draw:', error);
    throw error;
  }
};

export const getSingleDraw = async (drawId: string) => {
  const endpoint = `/admin/draw/8101-18101001710-17191/${drawId}`;                                          // Endpoint for fetching a single draw
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching single draw:', error);
    throw error;
  }
};

export const getDrawWinners = async (drawId: string) => {
  const endpoint = `/admin/draw/${drawId}/winners`;                                                       // Endpoint for fetching draw winners
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching draw winners:', error);
    throw error;
  }
};

export const getDrawHistory = async () => {
  const endpoint = '/admin/draw-history?page=10&pageSize=10';                                            // Endpoint for fetching draw history
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching draw history:', error);
    throw error;
  }
};

export const createDrawConfiguration = async (drawConfigData: any) => {
  const endpoint = '/admin/draw-configs';                                                          // Endpoint for creating draw configurations
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.post(url, drawConfigData);
    return response.data;
  } catch (error) {
    console.error('Error creating draw configuration:', error);
    throw error;
  }
};

export const getAllDrawConfigurations = async () => {
  const endpoint = '/admin/draw-configs';                                                              // Endpoint for fetching all draw configurations
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching all draw configurations:', error);
    throw error;
  }
};

export const getSingleDrawConfiguration = async (drawConfigId: string) => {
  const endpoint = `/admin/draw-configs/${drawConfigId}`;                                               // Endpoint for fetching a single draw configuration
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching single draw configuration:', error);
    throw error;
  }
};

export const updateSingleDrawConfiguration = async (drawConfigId: string, updatedDrawConfigData: any) => {
  const endpoint = `/admin/draw-config/${drawConfigId}`;                                                      // Endpoint for updating a single draw configuration
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.put(url, updatedDrawConfigData);
    return response.data;
  } catch (error) {
    console.error('Error updating single draw configuration:', error);
    throw error;
  }
};

export const deleteSingleDrawConfiguration = async (drawConfigId: string) => {
  const endpoint = `/admin/draw-configs/${drawConfigId}`;                                                  // Endpoint for deleting a single draw configuration
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.delete(url);
    return response.data;
  } catch (error) {
    console.error('Error deleting single draw configuration:', error);
    throw error;
  }
};

export const getPayouts = async () => {
  const endpoint = '/admin/payout';                                                                             // Endpoint for fetching payouts
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching payouts:', error);
    throw error;
  }
};

export const patchDisputedPayout = async (payoutId: string, updatedData: any) => {
  const endpoint = `/admin/payout/disputed/${payoutId}`;                                                      // Endpoint for updating a disputed payout
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.patch(url, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error patching disputed payout:', error);
    throw error;
  }
};

export const patchProcessedPayout = async (payoutId: string, updatedData: any) => {
  const endpoint = `/admin/payout/processed/${payoutId}`;                                                         // Endpoint for updating a processed payout
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.patch(url, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error patching processed payout:', error);
    throw error;
  }
};

export const createCashoutRequest = async (cashoutData: any) => {
  const endpoint = '/admin/cashout';                                                                       // Endpoint for creating cashout requests
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.post(url, cashoutData);
    return response.data;
  } catch (error) {
    console.error('Error creating cashout request:', error);
    throw error;
  }
};

export const addPartner = async (partnerData: any) => {
  const endpoint = '/partner';                                                                                 // Endpoint for adding partners
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.post(url, partnerData);
    return response.data;
  } catch (error) {
    console.error('Error adding partner:', error);
    throw error;
  }
};

export const updatePartner = async (partnerId: string, updatedPartnerData: any) => {
  const endpoint = `/partner/${partnerId}`;                                                                // Endpoint for updating partner details
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.put(url, updatedPartnerData);
    return response.data;
  } catch (error) {
    console.error('Error updating partner:', error);
    throw error;
  }
};

export const enablePartner = async (partnerId: string) => {
  const endpoint = `/partner/${partnerId}/enable`;                                                          // Endpoint for enabling a partner
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.put(url);
    return response.data;
  } catch (error) {
    console.error('Error enabling partner:', error);
    throw error;
  }
};

export const disablePartner = async (partnerId: string) => {
  const endpoint = `/partner/${partnerId}/disable`;                                                        // Endpoint for disabling a partner
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.put(url);
    return response.data;
  } catch (error) {
    console.error('Error disabling partner:', error);
    throw error;
  }
};

export const deletePartner = async (partnerId: string) => {
  const endpoint = `/partner/${partnerId}`;                                                                 // Endpoint for deleting a partner
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.delete(url);
    return response.data;
  } catch (error) {
    console.error('Error deleting partner:', error);
    throw error;
  }
};

export const initiatePayment = async (paymentData: any) => {
  const endpoint = '/partner/initiate';                                                                  // Endpoint for initiating payments
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.post(url, paymentData);
    return response.data;
  } catch (error) {
    console.error('Error initiating payment:', error);
    throw error;
  }
};

export const markPaymentAsProcessed = async (paymentId: string) => {
  const endpoint = `/partner/${paymentId}`;                                                              // Endpoint for updating payment status
  const url = `${baseUrl}${endpoint}`;

  const updatedPaymentData = {
    status: 'processed', // Set the payment status to 'processed'
  };

  try {
    const response = await axios.patch(url, updatedPaymentData);
    return response.data;
  } catch (error) {
    console.error('Error marking payment as processed:', error);
    throw error;
  }
};

export const markPaymentAsDisputed = async (paymentId: string) => {
  const endpoint = `/partner/${paymentId}`;                                                          // Endpoint for updating payment status
  const url = `${baseUrl}${endpoint}`;

  const updatedPaymentData = {
    status: 'disputed', // Set the payment status to 'disputed'
  };

  try {
    const response = await axios.patch(url, updatedPaymentData);
    return response.data;
  } catch (error) {
    console.error('Error marking payment as disputed:', error);
    throw error;
  }
};

export const markPaymentAsDisputedWithReason = async (paymentId: string, disputeReason: string) => {
  const endpoint = `/partner/${paymentId}`;                                                           // Endpoint for updating payment status and adding dispute reason
  const url = `${baseUrl}${endpoint}`;

  const updatedPaymentData = {
    status: 'disputed', // Set the payment status to 'disputed'
    disputeReason: disputeReason,  // Include the dispute reason in the updated data
  };

  try {
    const response = await axios.patch(url, updatedPaymentData);
    return response.data;
  } catch (error) {
    console.error('Error marking payment as disputed with reason:', error);
    throw error;
  }
};

export const getPayoutPartners = async () => {
  const endpoint = '/partner';                                                                 // Endpoint for fetching payout partners
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching payout partners:', error);
    throw error;
  }
};

export const listBanks = async () => {
  const endpoint = '/bank';                                                                   // Endpoint for listing banks
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error listing banks:', error);
    throw error;
  }
};

export const resolveBank = async (bankId: string, updatedBankData: any) => {
  const endpoint = `/bank/resolve/${bankId}`;                                                  // Endpoint for resolving bank
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url, updatedBankData);
    return response.data;
  } catch (error) {
    console.error('Error resolving bank:', error);
    throw error;
  }
};

export const createProduct = async (productData: any) => {
  const endpoint = '/admin/create-product';                                                   // Endpoint for creating products
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.post(url, productData);
    return response.data;
  } catch (error) {
    console.error('Error creating product:', error);
    throw error;
  }
};

export const enableProduct = async (productId: string) => {
  const endpoint = `/admin/enable-product/${productId}`;                                    // Endpoint for updating product status - enable
  const url = `${baseUrl}${endpoint}`;

  const updatedProductData = {
    status: 'enabled', // Set the product status to 'enabled'
  };

  try {
    const response = await axios.patch(url, updatedProductData);
    return response.data;
  } catch (error) {
    console.error('Error enabling product:', error);
    throw error;
  }
};

export const disableProduct = async (productId: string) => {
  const endpoint = `/admin/diable-product/${productId}`;                            // Endpoint for updating product status - disable
  const url = `${baseUrl}${endpoint}`;

  const updatedProductData = {
    status: 'disabled', // Set the product status to 'disabled'
  };

  try {
    const response = await axios.patch(url, updatedProductData);
    return response.data;
  } catch (error) {
    console.error('Error disabling product:', error);
    throw error;
  }
};

export const deleteProduct = async (productId: string) => {
  const endpoint = `/admin/delete-product/${productId}`;                             // Endpoint for deleting a product
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.delete(url);
    return response.data;
  } catch (error) {
    console.error('Error deleting product:', error);
    throw error;
  }
};

export const sendVerificationCode = async (phoneNumber: string) => {
  const endpoint = '/sms/send-code';                                                 // Endpoint for sending verification codes
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.post(url, {
      phoneNumber: phoneNumber,
    });
    return response.data;
  } catch (error) {
    console.error('Error sending verification code:', error);
    throw error;
  }
};

export const selectGame = async (gameId: string) => {
  const endpoint = '/sms/select-game';                                                  // Endpoint for selecting a game
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.post(url, {
      gameId: gameId,
    });
    return response.data;
  } catch (error) {
    console.error('Error selecting game:', error);
    throw error;
  }
};

export const processGame = async (gameId: string) => {
  const endpoint = '/sms/process-game';                                                  // Endpoint for processing a game
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.post(url, {
      gameId: gameId,
    });
    return response.data;
  } catch (error) {
    console.error('Error processing game:', error);
    throw error;
  }
};

export const addBank = async (bankData: any) => {
  const endpoint = '/sms/add-bank';                                                         // Endpoint for adding banks
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.post(url, bankData);
    return response.data;
  } catch (error) {
    console.error('Error adding bank:', error);
    throw error;
  }
};

export const getMonthlyData = async (year: number, month: number) => {
  const endpoint = `/admin/monthly-data?year=${year}&month=${month}`;                                       // Endpoint for monthly data API request
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching monthly data:', error);
    throw error;
  }
};

export const getDailyRevenueData = async (startDate: string, endDate: string) => {
  const endpoint = `/admin/daily-revenue?startDate=${startDate}&endDate=${endDate}`;                         // Endpoint for daily revenue data API request
  const url = `${baseUrl}${endpoint}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching daily revenue data:', error);
    throw error;
  }
};
