import { FunctionComponent, useState } from "react";
import { Link } from 'react-router-dom';
import styles from "./NewDraw.module.css";

const NewDraw: FunctionComponent = () => {
  const [showUserInfo, setShowUserInfo] = useState(false);
  const handleMoreInfoClick = () => {
    setShowUserInfo(!showUserInfo);
  };
  const [showUserInfo2, setShowUserInfo2] = useState(false);
  const handleMoreInfoClick2 = () => {
    setShowUserInfo2(!showUserInfo2);
  };
  const [showUserInfo3, setShowUserInfo3] = useState(false);

  const openPopup = () => {
    setShowUserInfo3(true);
    // Add any other logic you need for opening
  };

  const closePopup = () => {
    setShowUserInfo3(false);
    // Add any other logic you need for closing
  };
  return (
    <div className={styles.frameParent}>
      <div className={`${styles.frameGroup} ${showUserInfo3 ? styles.greyedOut : ''}`}>
        <div className={styles.frameContainer}>
          <div className={styles.arrowLeftLineParent}>
<Link to="/ManageDraw">
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/assets/arrow-left-line.svg"
            />
</Link>
            <div className={styles.goBack}>Go back</div>
          </div>
          <div className={styles.draw}>DRAW</div>
        </div>
        <div className={styles.schedulingADraw}>Scheduling a Draw</div>
        <div className={styles.inputFieldParent}>
          
          <div className={styles.inputField} onClick={openPopup}>
            <img className={styles.editIcon} alt="" src="/edit@2x.png" />
            <div className={styles.goBack}>Schedule this draw</div>
          </div>
          
          <div className={styles.inputField1}>
            <img className={styles.editIcon} alt="" src="/edit.svg" />
            <div className={styles.goBack}>{`Cancel `}</div>
          </div>
        </div>
        <div className={styles.inputFielsParent}>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue}>
              <div className={styles.gameType}>Game type</div>
            </div>
            <div className={styles.inputField2}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text}>
                  <div className={styles.selectAGame}>
                    Select a game from dropdown
                  </div>
                </div>
              </div>
              <img className={styles.rightIcon} alt="" src="/assets/right-icon.svg" onClick={handleMoreInfoClick} />
            </div>
          </div>
          <div className={styles.inputFiels1}>
            <div className={styles.headerValue1}>
              <div className={styles.gameType}>Date</div>
            </div>
            <div className={styles.inputField3}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text1}>
                  <div className={styles.selectAGame}>Select date</div>
                </div>
              </div>
              <img className={styles.rightIcon} alt="" src="/assets/right-icon.svg" onClick={handleMoreInfoClick2}/>
            </div>
          </div>
          <div className={styles.inputFiels2}>
            <div className={styles.headerValue2}>
              <div className={styles.gameType}>Time</div>
            </div>
            <div className={styles.inputField3}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text2}>
                  <div className={styles.selectAGame}>Select time</div>
                </div>
              </div>
              <img className={styles.rightIcon} alt="" src="/assets/time.svg" />
            </div>
          </div>
          <div className={styles.inputFiels3}>
            <div className={styles.headerValue3}>
              <div className={styles.gameType}>Number of draw winners</div>
            </div>
            <div>
            <input
    type="text"
    className={styles.inputField3} // Replace with your CSS class for the input field
    placeholder="Enter number of draw winners"
  />
            </div>
          </div>
          {showUserInfo && (
          <div className={styles.dropdown}>
            <div className={styles.oneMillionBagParent}>
              <div className={styles.goBack}>One Million bag</div>
              <div className={styles.frameChild} />
              <div className={styles.goBack}>ChopChop Instant Money</div>
              <div className={styles.frameChild} />
              <div className={styles.goBack}>Mega Money</div>
              <div className={styles.frameChild} />
              <div className={styles.goBack}>Fast Cash</div>
            </div>
          </div>
          )}
          {showUserInfo2 && (
          <div className={styles.dropdown2}>
            <div className={styles.dailyEverydayFromTodayParent2}>
              <div className={styles.goBack2}>Daily - Everyday from today</div>
              <div className={styles.frameChild2} />
              <div className={styles.goBack2}>
                Weekly - On this day every week
              </div>
              <div className={styles.frameChild2} />
              <div className={styles.goBack2}>
                Monthly - On this date Every month
              </div>
              <div className={styles.frameChild2} />
              <div className={styles.goBack2}>
                No recurrence - One time creation
              </div>
              <div className={styles.frameChild2} />
              <div className={styles.goBack2}>Custom</div>
            </div>
          </div>
          )}
        </div>
        <div className={styles.smallButtonParent}>
          <div className={styles.smallButton}>
            <div className={styles.subscribers}>Schedule a draw</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-2.svg"
            />
          </div>
<Link to="/ScheduledDraws">
          <div className={styles.smallButton1}>
            <div className={styles.adminOfficer}>View all scheduled draws</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-1.svg"
            />
          </div>
</Link>
        </div>
      </div>
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
<a href="/dashboard">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/dashboard.svg" />
            <div className={styles.goBack}>Dashboard</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
</a>
          <a href="/subscribers">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/user-02.svg" />
            <div className={styles.goBack}>Subscribers</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
</a>
          <a href="/subscription">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.goBack}>Subscriptions</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
</a>
          <div className={styles.gm}>
            <div className={styles.component6}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.subscribers}>Game Management</div>
              <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
            </div>
            <div className={styles.subNav}>
<a href="/ManageGame">
              <div className={styles.subNavComponents}>
                <div className={styles.component5Parent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/Component 5.svg"
                  />
                  <div className={styles.goBack}>Manage Games</div>
                </div>
              </div>
</a>
              <div className={styles.subNavComponents1}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/shuffle.svg"
                />
                <div className={styles.dashboard5}>Manage Draws</div>
              </div>
<a href="/DrawHistory">
              <div className={styles.subNavComponents}>
                <div className={styles.component5Parent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/history.svg"
                  />
                  <div className={styles.goBack}>Draw History</div>
                </div>
              </div>
</a>
              <a href="/ManagePayout">
              <div className={styles.subNavComponents}>
                <div className={styles.component5Parent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/Component 6.svg"
                  />
                  <div className={styles.goBack}>Manage Payout</div>
                </div>
              </div>
</a>
            </div>
          </div>
<a href="/partners">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/partnership.svg"
            />
            <div className={styles.goBack}>Partners</div>
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/assets/dropdown-down.svg"
            />
          </div>
</a>
          <a href="/AdminSettings">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
            <div className={styles.goBack}>Admin Management</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
</a>
          <a href="/sms">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/message.svg" />
            <div className={styles.goBack}>SMS</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
</a>
        </div>
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.rectangleIcon}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.adminOfficer}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img className={styles.setting02Icon} alt="" src="/assets/setting-02.svg" />
        </div>
      </div>
      {showUserInfo3 && (
      <div className={styles.pause}>
          <div className={styles.pauseChild} />
          <img className={styles.xCloseIcon} alt="" src="/assets/x-close.svg" onClick={closePopup} />
          <div className={styles.youAreAboutContainer}>
            <span className={styles.youAreAboutContainer1}>
              <span>{`You are about to schedule a `}</span>
              <span className={styles.daily}>daily</span>
              <span>{` draw at `}</span>
              <span className={styles.daily}>3:00pm</span>
              <span>{` for the `}</span>
              <span className={styles.daily}>Fast cash</span>
              <span>{` game, with `}</span>
              <span className={styles.daily}>4</span>
              <span> expected winners.</span>
            </span>
          </div>
          <div className={styles.phwarningDuotoneParent}>
            <img
              className={styles.phwarningDuotoneIcon}
              alt=""
              src="/assets/bang.svg"
            />
            <div
              className={styles.confirmationYouAre}
            >{`Confirmation: you are about to schedule a draw `}</div>
          </div>
          <div className={styles.inputFieldGroup}>
            <div className={styles.inputField22}>
              <img className={styles.editIcon2} alt="" src="/edit@2x.png" />
              <div className={styles.goBack}>Proceed</div>
            </div>
            
            <div className={styles.inputField7} onClick={closePopup}>
              <img className={styles.editIcon2} alt="" src="/edit@2x.png" />
              <div className={styles.goBack}>Cancel</div>
            </div>
            
          </div>
        </div>
      )}
    </div>
  );
};

export default NewDraw;
