import { FunctionComponent } from "react";
import styles from "./onemillionbag.module.css";
import {Link} from "react-router-dom";
//import './global1.css'

const onemillionbag: FunctionComponent = () => {
  return (
    <div className={styles.subscriptionsPopUp}>
      <Link to="/subscriptions">
  <img
    className={styles.xCloseIcon}
    alt=""
    src="/assets/x-close.svg"
  />
</Link>

      <div className={styles.oneMillionBag}>One Million Bag</div>
      <div className={styles.subscriptionsGroup}>
        <div className={styles.dashboardItems}>
          <div className={styles.frameParent}>
            <div className={styles.totalSubscriptionsParent}>
              <div className={styles.totalSubscriptions}>
                Total Subscriptions
              </div>
              <div className={styles.parent}>
                <div className={styles.div}>300,000</div>
                <div className={styles.riseFromLast}>
                  20% rise from last week
                </div>
              </div>
            </div>
            <img className={styles.component5Icon} alt="" src="/assets/Component 5.svg" />
          </div>
        </div>
        <div className={styles.dashboardItems}>
          <div className={styles.frameGroup}>
            <div className={styles.totalSubscriptionsParent}>
              <div className={styles.totalSubscriptions}>Renewals</div>
              <div className={styles.group}>
                <div className={styles.div}>20,000</div>
                <div className={styles.riseFromLast}>
                  20% rise from last week
                </div>
              </div>
            </div>
            <img className={styles.component5Icon} alt="" src="/assets/Component 5.svg" />
          </div>
        </div>
        <div className={styles.dashboardItems}>
          <div className={styles.frameContainer}>
            <div className={styles.totalSubscriptionsParent}>
              <div className={styles.totalSubscriptions}>opt-out</div>
              <div className={styles.group}>
                <div className={styles.div}>5000</div>
                <div className={styles.dropFromLast}>
                  20% drop from last week
                </div>
              </div>
            </div>
            <img className={styles.component5Icon} alt="" src="/assets/Component 5.svg" />
          </div>
        </div>
        <div className={styles.dashboardItems}>
          <div className={styles.frameDiv}>
            <div className={styles.totalSubscriptionsParent}>
              <div className={styles.totalSubscriptions}>one-shot</div>
              <div className={styles.group}>
                <div className={styles.div}>5000</div>
                <div className={styles.dropFromLast}>
                  20% drop from last week
                </div>
              </div>
            </div>
            <img className={styles.component5Icon} alt="" src="/assets/Component 5.svg" />
          </div>
        </div>
      </div>
      <div className={styles.subscriptionsPopUpChild} />
      <div className={styles.subscriptionsPopUpItem} />
      <div className={styles.revenueDetails}>
        <div className={styles.totalRevenueParent}>
          <div className={styles.totalSubscriptions}>Total Revenue</div>
          <div className={styles.wrapper}>
            <div className={styles.div4}>₦500,000,00</div>
          </div>
        </div>
        <div className={styles.totalRevenueParent}>
          <div className={styles.totalSubscriptions}>
            Revenue in the past 7 days
          </div>
          <div className={styles.parent2}>
            <div className={styles.div4}>₦500,000</div>
            <div className={styles.arrowDownLineParent}>
              <img
                className={styles.arrowDownLineIcon}
                alt=""
                src="/assets/arrow-down-line.svg"
              />
              <div className={styles.filterBy}>20% drop from last week</div>
            </div>
          </div>
        </div>
        <div className={styles.totalRevenueParent}>
          <div className={styles.todaysRevenueWrapper}>
            <div className={styles.todaysRevenue}>Today’s Revenue</div>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.div4}>₦50,000</div>
          </div>
        </div>
      </div>
      <div className={styles.frameParent1}>
        <div className={styles.showing12Of10000000EntriWrapper}>
          <div className={styles.showing12Of}>
            Showing 12 of 10,000,000 entries
          </div>
        </div>
        <div>
        <input
    type="text"
    className={styles.frameParent2} // Replace with your CSS class for the input field
    placeholder="Search"
  />
        </div>
        <div className={styles.filterParent}>
          <img className={styles.filterIcon} alt="" src="/assets/filter.svg" />
          <div className={styles.filterBy}>Filter by</div>
          <img
            className={styles.chevronArrowDownIcon}
            alt=""
            src="/assets/chevron-arrow-down.svg"
          />
        </div>
        <div className={styles.export}>
          <div className={styles.showing12Of}>Export as .csv</div>
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.column}>
          <div className={styles.cellHeader}>
            <div className={styles.sn}>S/N</div>
          </div>
          <div className={styles.cellItem}>
            <div className={styles.div7}>1</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>2</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>3</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>4</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>5</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>6</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>7</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>8</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>9</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>10</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>11</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>12</div>
          </div>
        </div>
        <div className={styles.column1}>
          <div className={styles.cellHeader1}>
            <div className={styles.sn}>Mobile Number</div>
          </div>
          <div className={styles.cellItem}>
            <div className={styles.text1}>
              <div className={styles.div19}>08100000000</div>
            </div>
          </div>
          <div className={styles.cellItem}>
            <div className={styles.text1}>
              <div className={styles.div19}>08100000000</div>
            </div>
          </div>
          <div className={styles.cellItem}>
            <div className={styles.text1}>
              <div className={styles.div19}>08100000000</div>
            </div>
          </div>
          <div className={styles.cellItem}>
            <div className={styles.text1}>
              <div className={styles.div19}>08100000000</div>
            </div>
          </div>
          <div className={styles.cellItem}>
            <div className={styles.text1}>
              <div className={styles.div19}>08100000000</div>
            </div>
          </div>
          <div className={styles.cellItem}>
            <div className={styles.text1}>
              <div className={styles.div19}>08100000000</div>
            </div>
          </div>
          <div className={styles.cellItem}>
            <div className={styles.text1}>
              <div className={styles.div19}>08100000000</div>
            </div>
          </div>
          <div className={styles.cellItem}>
            <div className={styles.text1}>
              <div className={styles.div19}>08100000000</div>
            </div>
          </div>
          <div className={styles.cellItem}>
            <div className={styles.text1}>
              <div className={styles.div19}>08100000000</div>
            </div>
          </div>
          <div className={styles.cellItem}>
            <div className={styles.text1}>
              <div className={styles.div19}>08100000000</div>
            </div>
          </div>
          <div className={styles.cellItem}>
            <div className={styles.text1}>
              <div className={styles.div19}>08100000000</div>
            </div>
          </div>
          <div className={styles.cellItem}>
            <div className={styles.text1}>
              <div className={styles.div19}>08100000000</div>
            </div>
          </div>
        </div>
        <div className={styles.column2}>
          <div className={styles.cellHeader}>
            <div className={styles.sn}>Update date and time</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>12/03/2023</p>
              <p className={styles.p}>9:00pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>12/03/2023</p>
              <p className={styles.p}>9:00pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>12/03/2023</p>
              <p className={styles.p}>9:00pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>12/03/2023</p>
              <p className={styles.p}>9:00pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>12/03/2023</p>
              <p className={styles.p}>9:00pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>12/03/2023</p>
              <p className={styles.p}>9:00pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>12/03/2023</p>
              <p className={styles.p}>9:00pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>12/03/2023</p>
              <p className={styles.p}>9:00pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>12/03/2023</p>
              <p className={styles.p}>9:00pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>12/03/2023</p>
              <p className={styles.p}>9:00pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>12/03/2023</p>
              <p className={styles.p}>9:00pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>12/03/2023</p>
              <p className={styles.p}>9:00pm</p>
            </div>
          </div>
        </div>
        <div className={styles.column3}>
          <div className={styles.cellHeader3}>
            <div className={styles.sn}>Expiry date/time</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>22/04/2023</p>
              <p className={styles.p}>10pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>-</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>22/04/2023</p>
              <p className={styles.p}>10pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>-</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>-</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>22/04/2023</p>
              <p className={styles.p}>10pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>22/04/2023</p>
              <p className={styles.p}>10pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>
              <p className={styles.p}>22/04/2023</p>
              <p className={styles.p}>10pm</p>
            </div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>-</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>-</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>-</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.sn}>-</div>
          </div>
        </div>
        <div className={styles.column4}>
          <div className={styles.cellHeader}>
            <div className={styles.sn}>Total amount spent</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.div38}>₦5,000</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.div38}>₦5,000</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.div38}>₦5,000</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.div38}>₦5,000</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.div38}>₦5,000</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.div38}>₦5,000</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.div38}>₦5,000</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.div38}>₦5,000</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.div38}>₦5,000</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.div38}>₦5,000</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.div38}>₦5,000</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.div38}>₦5,000</div>
          </div>
        </div>
        <div className={styles.column5}>
          <div className={styles.cellHeader3}>
            <div className={styles.sn}>Update type</div>
          </div>
          <div className={styles.cellItem60}>
            <div className={styles.renewal}>Renewal</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.renewal}>One-shot</div>
          </div>
          <div className={styles.cellItem60}>
            <div className={styles.renewal}>Renewal</div>
          </div>
          <div className={styles.cellItem63}>
            <div className={styles.renewal}>Subscribed</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.renewal}>One-shot</div>
          </div>
          <div className={styles.cellItem60}>
            <div className={styles.renewal}>Renewal</div>
          </div>
          <div className={styles.cellItem66}>
            <div className={styles.renewal}>Opt-out</div>
          </div>
          <div className={styles.cellItem66}>
            <div className={styles.renewal}>Opt-out</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.renewal}>One-shot</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.renewal}>One-shot</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.renewal}>One-shot</div>
          </div>
          <div className={styles.cellItem1}>
            <div className={styles.renewal}>One-shot</div>
          </div>
        </div>
      </div>
      <div className={styles.previousParent}>
        <div className={styles.showing12Of}>Previous</div>
        <div className={styles.next}>Next</div>
        <div className={styles.last}>Last</div>
      </div>
    </div>
  );
};

export default onemillionbag;
