import { FunctionComponent, useState, useCallback } from "react";
import styles from "./NewGame.module.css";
import { Link } from 'react-router-dom';
//import './globalN.css'

const NewGame: FunctionComponent = () => {
  const [showUserInfo, setShowUserInfo] = useState(false);
  const handleMoreInfoClick = () => {
    setShowUserInfo(!showUserInfo);
  };
  const [showUserInfo2, setShowUserInfo2] = useState(false);
  const handleMoreInfoClick2 = () => {
    setShowUserInfo2(!showUserInfo2);
  };
  const [showUserInfo3, setShowUserInfo3] = useState(false);

  const openPopup = () => {
    setShowUserInfo3(true);
    // Add any other logic you need for opening
  };

  const closePopup = () => {
    setShowUserInfo3(false);
    // Add any other logic you need for closing
  };

  const onForgottenYourPasswordClick = useCallback(() => {
    // Please sync "Password recovery 1" to the project
  }, []);
  return (
    <div className={styles.frameParent}>
      <div className={`${styles.frameGroup} ${showUserInfo3 ? styles.greyedOut : ''}`}>
        <div className={styles.frameContainer}>
          <div className={styles.arrowLeftLineParent}>
<Link to="/ManageGame">
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/assets/arrow-left-line.svg"
            />
</Link>
            <div className={styles.goBack}>Go back</div>
          </div>
          <div className={styles.gameManagement}>{`GAME MANAGEMENT `}</div>
        </div>
        <div className={styles.startingANew}>Starting a New Game</div>
        <div className={styles.inputFielsParent}>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue}>
              <div className={styles.gameType}>Game type</div>
            </div>
            <div className={styles.inputField}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text}>
                  <div className={styles.selectGameType}>
                    Select game type from dropdown
                  </div>
                </div>
              </div>
              <img className={styles.rightIcon} alt="" src="/assets/right-icon.svg" onClick={handleMoreInfoClick}/>
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue}>
              <div className={styles.gameType}>Recurrence</div>
            </div>
            <div className={styles.inputField}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text}>
                  <div className={styles.selectGameType}>
                    Select how often game should repeat
                  </div>
                </div>
              </div>
              <img className={styles.rightIcon} alt="" src="/assets/right-icon.svg" onClick={handleMoreInfoClick2}/>
            </div>
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.selectorParent}>
              <img className={styles.selectorIcon} alt="" src="/assets/selector.svg" />
              <div className={styles.goBack}>Single Pool</div>
            </div>
            <div className={styles.selectorParent}>
              <div className={styles.frameChild} />
              <div className={styles.goBack}>Multiple Pools</div>
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue1}>
              <div className={styles.gameType}>Maximum players</div>
            </div>
            <div>
            <input
    type="text"
    className={styles.inputField1} // Replace with your CSS class for the input field
    placeholder="Max numbers of players allowed in the game"
  />
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue2}>
              <div className={styles.gameType}>Maximum amount</div>
            </div>
            <div>
            <input
    type="text"
    className={styles.inputField1} // Replace with your CSS class for the input field
    placeholder="Max amount of money allowed in the pool"
  />
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue3}>
              <div className={styles.gameType}>Winning %</div>
            </div>
            <div>
            <input
    type="text"
    className={styles.inputField1} // Replace with your CSS class for the input field
    placeholder="% of money shared among winners"
  />
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue4}>
              <div className={styles.gameType}>Winning Number</div>
            </div>
            <div>
            <input
    type="text"
    className={styles.inputField4} // Replace with your CSS class for the input field
    placeholder="Select a number between 0-45(Only active for Instant 3/4)"
  />
            </div>
          </div>
        </div>
        <div className={styles.inputFieldParent}>
          
        <div className={styles.inputField5} onClick={openPopup}>
  <img className={styles.editIcon} alt="" src="/edit.svg" />
  <div className={styles.goBack}>Start this game</div>
</div>


          <div className={styles.inputField6}>
            <img className={styles.editIcon} alt="" src="/edit.svg" />
            <div className={styles.goBack}>Cancel progress</div>
          </div>
        </div>
        {showUserInfo && (
        <div className={styles.dropdown}>
          <div className={styles.oneMillionBagParent}>
            <div className={styles.goBack}>One Million bag</div>
            <div className={styles.frameItem} />
            <div className={styles.goBack}>ChopChop Instant Money</div>
            <div className={styles.frameItem} />
            <div className={styles.goBack}>Mega Money</div>
            <div className={styles.frameItem} />
            <div className={styles.goBack}>Instant 3/4</div>
            <div className={styles.frameItem} />
            <div className={styles.goBack}>Fast Cash</div>
          </div>
        </div>
        )}
        {showUserInfo2 && (
        <div className={styles.dropdown2} >
        <div className={styles.dailyEverydayFromTodayParent2}>
          <div className={styles.goBack2}>Daily - Everyday from today</div>
          <div className={styles.frameItem2} />
          <div className={styles.goBack2}>Weekly - On this day every week</div>
          <div className={styles.frameItem2} />
          <div className={styles.goBack2}>
            Monthly - On this date Every month
          </div>
          <div className={styles.frameItem2} />
          <div className={styles.goBac2k}>
            No recurrence - One time creation
          </div>
        </div>
      </div>
        )}
      </div>
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
<a href="/dashboard">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/dashboard.svg" />
            <div className={styles.goBack}>Dashboard</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
          </a>
          <a href="/subscribers">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/user-02.svg" />
            <div className={styles.goBack}>Subscribers</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
</a>
            <a href="/subscriptions">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.goBack}>Subscriptions</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
</a>
          <div className={styles.gm}>
            <div className={styles.component6}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.dashboard3}>Game Management</div>
              <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
            </div>
            <div className={styles.subNav}>
              <div className={styles.subNavComponents}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/Component 5.svg"
                />
                <div className={styles.dashboard4}>Manage Games</div>
              </div>
<a href="/ManagePayout">
              <div className={styles.subNavComponents1}>
                <div className={styles.shuffleParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/shuffle.svg"
                  />
                  <div className={styles.goBack}>Manage Draws</div>
                </div>
              </div>
</a>
                <a href="/DrawHistory">
              <div className={styles.subNavComponents1}>
                <div className={styles.shuffleParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/history.svg"
                  />
                  <div className={styles.goBack}>Draw History</div>
                </div>
              </div>
</a>
                <a href="/ManagePayout">
              <div className={styles.subNavComponents1}>
                <div className={styles.shuffleParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/Component 6.svg"
                  />
                  <div className={styles.goBack}>Manage Payout</div>
                </div>
              </div>
              </a>
            </div>
          </div>
          <a href="/partners">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/partnership.svg"
            />
            <div className={styles.goBack}>Partners</div>
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/assets/dropdown-down.svg"
            />
          </div>
</a>
            <a href="/AdminSettings">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
            <div className={styles.goBack}>Admin Management</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
</a>
            <a href="/sms">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/message.svg" />
            <div className={styles.goBack}>SMS</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
          </a>
        </div>
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.rectangleIcon}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.adminOfficer}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img className={styles.setting02Icon} alt="" src="/assets/setting-02.svg" />
        </div>
      </div>
      {showUserInfo3 && (
      <div className={styles.pause}>
        <div className={styles.pauseChild} />
        <img className={styles.xCloseIcon} alt="" src="/xclose@2x.png" />
        <div className={styles.enterAdminCredentials}>
          Enter Admin Credentials
        </div>
        <div className={styles.inputFieldGroup}>
          <div className={styles.inputField66}>
            <img className={styles.editIcon2} alt="" src="/edit@2x.png" />
            <div className={styles.goBackk}>Continue</div>
          </div>
          <div className={styles.inputField9} onClick={closePopup}>
            <img className={styles.editIcon2} alt="" src="/edit@2x.png" />
            <div className={styles.goBackk}>Cancel</div>
          </div>
        </div>
        <div className={styles.inputFielsGroup}>
          <div className={styles.inputFiels6}>
            <div className={styles.headerValue6}>
              <div className={styles.gameType}>Username</div>
            </div>
            <div>
            <input
          className={styles.inputField10}
          placeholder="Enter Admin ID"
          type="email"
        />
            </div>
          </div>
          <div className={styles.inputFielsContainer}>
            <div className={styles.inputFiels7}>
              <div className={styles.headerValue7}>
                <div className={styles.gameType}>Password</div>
              </div>
              <div>
              <input
          className={styles.inputField11}
          placeholder="Enter Admin Password"
          type="email"
        />
              </div>
            </div>
            <div
              className={styles.forgottenYourPasswordContainer}
              onClick={onForgottenYourPasswordClick}
            >
              <span
                className={styles.forgottenYourPassword}
              >{`Forgotten your password? `}</span>
              <b className={styles.resetNow}>
                <span>Reset now</span>
                <span className={styles.span}>{` `}</span>
              </b>
            </div>
          </div>
        </div>
        <div
          className={styles.toCreateThis}
        >{`To create this game you must enter your admin credentials `}</div>
      </div>
      )}
    </div>
  );
};

export default NewGame;