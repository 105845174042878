//import { FunctionComponent, useCallback, useState } from "react";
import styles from "./subscriber.module.css";
import { getTotalSubscribers, getTotalSubscribersLast7Days, getTotalUnsubscribersLast7Days } from "../api";
import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';

const Subscriber: FunctionComponent = () => {
  const [totalSubscribers, setTotalSubscribers] = useState(0);
  const [subscribersLast7Days, setSubscribersLast7Days] = useState(0);
  const [unsubscribersLast7Days, setUnsubscribersLast7Days] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showUserInfo, setShowUserInfo] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const onXCloseIconClick = () => {
    setShowUserInfo(false);
  };

  const handleMoreInfoClick = () => {
    setShowUserInfo(!showUserInfo);
  };
  

  const onFrameContainer22Click: () => void = useCallback(() => {
    // Handle frame container click
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subscribers = await getTotalSubscribers();
        const subscribersLastWeek = await getTotalSubscribersLast7Days();
        const unsubscribersLastWeek = await getTotalUnsubscribersLast7Days();
        setTotalSubscribers(subscribers);
        setSubscribersLast7Days(subscribersLastWeek);
        setUnsubscribersLast7Days(unsubscribersLastWeek);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  console.log(isLoading); // temporary fix

  return (
    <div className={styles.dashboard}>
      <main className={styles.subscribersParent}>
        <div className={styles.subscribers}>SUBSCRIBERS</div>
        <nav className={styles.dashboardItemsParent}>
          <div className={styles.dashboardItems}>
            <div className={styles.frameParent}>
            <div className={styles.totalNumberOfSubscribersParent}>
  <div className={styles.totalNumberOf}>
    Total Number of Subscribers
  </div>
  <div className={styles.div}>{totalSubscribers}</div>
</div>
              <img className={styles.user01Icon} alt="" src="/assets/user-01.svg" />
            </div>
          </div>
          <div className={styles.dashboardItems}>
            <div className={styles.frameGroup}>
              <div className={styles.numberOfSubscribersWrapper}>
                <div className={styles.totalNumberOf}>
                  Number of Subscribers
                </div>
              </div>
              <img className={styles.addUserIcon} alt="" src="/assets/add-user.svg" />
              <div className={styles.past7DaysParent}>
  <div className={styles.past7Days}>Past 7 days</div>
  <h2 className={styles.subscribers1}>+{subscribersLast7Days} Subscribers</h2>
</div>
            </div>
          </div>
          <div className={styles.dashboardItems}>
            <div className={styles.frameContainer}>
              <div className={styles.numberOfSubscribersWrapper}>
                <div className={styles.totalNumberOf}>
                  Number of Unsubscribers
                </div>
              </div>
              <img
                className={styles.addUserIcon}
                alt=""
                src="/assets/user-02.svg"
              />
              <div className={styles.past7DaysParent}>
  <div className={styles.past7Days}>Past 7 days</div>
  <h2 className={styles.subscribers2}>-{unsubscribersLast7Days} Unsubscribers</h2>
</div>
            </div>
          </div>
        </nav>
        <section className={styles.table}>
          <div className={styles.column}>
            <h5 className={styles.cellHeader}>
              <div className={styles.sn}>S/N</div>
            </h5>
            <h5 className={styles.cellItem}>
              <div className={styles.sn}>1</div>
            </h5>
            <h5 className={styles.cellItem}>
              <div className={styles.sn}>2</div>
            </h5>
            <h5 className={styles.cellItem}>
              <div className={styles.sn}>3</div>
            </h5>
            <h5 className={styles.cellItem}>
              <div className={styles.sn}>4</div>
            </h5>
            <h5 className={styles.cellItem}>
              <div className={styles.sn}>5</div>
            </h5>
            <div className={styles.cellItem5}>
              <div className={styles.sn}>6</div>
            </div>
            <div className={styles.cellItem5}>
              <div className={styles.sn}>7</div>
            </div>
            <div className={styles.cellItem5}>
              <div className={styles.sn}>8</div>
            </div>
            <div className={styles.cellItem5}>
              <div className={styles.sn}>9</div>
            </div>
            <h5 className={styles.cellItem}>
              <div className={styles.sn}>10</div>
            </h5>
          </div>
          <div className={styles.column1}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Phone Number</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000000</div>
              </div>
            </div>
            <textarea className={styles.cellItem11} placeholder="08100000000" />
            <textarea className={styles.cellItem11} placeholder="08100000000" />
            <textarea className={styles.cellItem11} placeholder="08100000000" />
            <textarea className={styles.cellItem11} placeholder="08100000000" />
            <textarea className={styles.cellItem11} placeholder="08100000000" />
            <textarea className={styles.cellItem11} placeholder="08100000000" />
            <textarea className={styles.cellItem11} placeholder="08100000000" />
            <textarea className={styles.cellItem11} placeholder="08100000000" />
            <textarea className={styles.cellItem11} placeholder="08100000000" />
          </div>
          <div className={styles.column2}>
            <div className={styles.cellHeader2}>
              <div className={styles.sn}>Account Details</div>
            </div>
            <div className={styles.cellItem20}>
              <div className={styles.accessBank}>Access Bank - 0000000001</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>Access Bank - 0000000001</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>Access Bank - 0000000001</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>Access Bank - 0000000001</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>Access Bank - 0000000001</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>Access Bank - 0000000001</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>Access Bank - 0000000001</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>Access Bank - 0000000001</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>Access Bank - 0000000001</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>Access Bank - 0000000001</div>
            </div>
          </div>
          <div className={styles.column3}>
            <div className={styles.cellHeader2}>
              <div className={styles.sn}>Service Provider</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>MTN</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>GLO</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>Airtel</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>9mobile</div>
            </div>
            <textarea className={styles.cellItem34} placeholder="GLO" />
            <textarea className={styles.cellItem34} placeholder="MTN" />
            <textarea className={styles.cellItem34} placeholder="9mobile" />
            <textarea className={styles.cellItem34} placeholder="Airtel" />
            <textarea className={styles.cellItem34} placeholder="GLO" />
            <textarea className={styles.cellItem34} placeholder="MTN" />
          </div>
          <div className={styles.column4}>
            <div className={styles.cellHeader2}>
              <div className={styles.sn}>Number of Games Played</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>27</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>100</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>50</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>1</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>1777</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>0</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.sn}>9</div>
            </div>
            <input
              className={styles.cellItem47}
              placeholder="45"
              type="number"
            />
            <input
              className={styles.cellItem47}
              placeholder="3"
              type="number"
            />
            <input
              className={styles.cellItem47}
              placeholder="68"
              type="number"
            />
          </div>
          <div className={styles.column5}>
            <div className={styles.cellHeader2}>
              <div className={styles.sn}>Amount in Wallet</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.nil}>₦5,000</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.nil}>₦5,000</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.nil}>₦5,000</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.nil}>₦5,000</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.nil}>₦5,000</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.nil}>₦5,000</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.nil}>₦5,000</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.nil}>₦5,000</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.nil}>₦5,000</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.nil}>₦5,000</div>
            </div>
          </div>
          <div className={styles.column6}>
            <div className={styles.cellHeader2}>
              <div className={styles.sn}>Total Payout</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.nil}>Nil</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.nil}>₦5,000</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.nil}>Nil</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.nil}>₦5,000</div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.nil}>₦5,000</div>
            </div>
            <textarea className={styles.cellItem65} placeholder="Nil" />
            <div className={styles.cellItem21}>
              <div className={styles.nil}>₦5,000</div>
            </div>
            <textarea className={styles.cellItem65} placeholder="Nil" />
            <div className={styles.cellItem21}>
              <div className={styles.nil}>₦5,000</div>
            </div>
            <textarea className={styles.cellItem65} placeholder="Nil" />
          </div>
          <div className={styles.column7}>
            <div className={styles.cellHeader7}>
              <div className={styles.advancedInfo}>Advanced Info</div>
            </div>
            <div className={styles.cellItem21}>
            <button className={styles.smallButton} onClick={handleMoreInfoClick}>
  <div className={styles.past7Days}>More info</div>
  <img
    className={styles.smallButtonChild}
    alt=""
    src="/group-1.svg"
  />
</button>



            </div>
            <div className={styles.cellItem21}>
              <div className={styles.smallButton}>
                <div className={styles.past7Days}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-11.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.smallButton}>
                <div className={styles.past7Days}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-12.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.smallButton}>
                <div className={styles.past7Days}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-13.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.smallButton}>
                <div className={styles.past7Days}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-14.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.smallButton}>
                <div className={styles.past7Days}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-15.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.smallButton}>
                <div className={styles.past7Days}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-16.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.smallButton}>
                <div className={styles.past7Days}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-17.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.smallButton}>
                <div className={styles.past7Days}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-18.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem21}>
              <div className={styles.smallButton}>
                <div className={styles.past7Days}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-19.svg"
                />
              </div>
            </div>
          </div>
        </section>
        <section className={styles.frameSection}>
        <div>
          <input
            className={styles.frameDiv} // Apply appropriate styles here
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={handleInputChange}
          />
            <img className={styles.rightIcon} alt="" src="/righticon.svg" />
          </div>
          <button className={styles.showing10Of10000000EntriWrapper}>
            <div className={styles.showing10Of}>
              Showing 10 of 10,000,000 entries
            </div>
          </button>
          <div className={styles.materialSymbolsdownloadParent}>
            <img
              className={styles.materialSymbolsdownloadIcon}
              alt=""
              src="/assets/material-symbols_download.svg"
            />
            <div className={styles.advancedInfo}>
              Export Subscribers List (.csv)
            </div>
          </div>
        </section>
        <div className={styles.previousParent}>
          <div className={styles.advancedInfo}>Previous</div>
          <div className={styles.next}>Next</div>
          <div className={styles.last}>Last</div>
        </div>
      </main>
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
        <a href="/dashboard"> {/* Replace '/dashboard' with the actual URL or route */}
  <div className={styles.component4}>
    <img className={styles.dashboardIcon} alt="" src="/assets/dashboard.svg" />
    <div className={styles.advancedInfo}>Dashboard</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/dropdowndown.svg"
    />
  </div>
</a>

          <div className={styles.component5}>
            <img className={styles.dashboardIcon} alt="" src="/assets/user-02.svg" />
            <div className={styles.dashboard2}>Subscribers</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown1.svg"
            />
          </div>
          <a href="/subscriptions"> {/* Replace '/subscriptions' with the actual URL or route */}
  <div className={styles.component4}>
    <img
      className={styles.dashboardIcon}
      alt=""
      src="/assets/subscription.svg"
    />
    <div className={styles.advancedInfo}>Subscriptions</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/dropdowndown2.svg"
    />
  </div>
</a>

          <div className={styles.gm}>
          <a href="/ManageGame"> {/* Replace '/game-management' with the actual URL or route */}
  <div className={styles.component4}>
    <img
      className={styles.dashboardIcon}
      alt=""
      src="/assets/Component 5.svg"
    />
    <div className={styles.advancedInfo}>Game Management</div>
    <img
      className={styles.materialSymbolsdownloadIcon}
      alt=""
      src="/assets/dropdown-down.svg"
    />
  </div>
</a>

          </div>
          <a href="/partners"> {/* Replace '/partners' with the actual URL or route */}
  <div className={styles.component4}>
    <img
      className={styles.dashboardIcon}
      alt=""
      src="/assets/partnership.svg"
    />
    <div className={styles.advancedInfo}>Partners</div>
    <img
      className={styles.materialSymbolsdownloadIcon}
      alt=""
      src="/assets/dropdown-down.svg"
    />
  </div>
</a>

<a href="/AdminSettings" className={styles.component4}>
  <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
  <div className={styles.advancedInfo}>Admin Management</div>
  <img className={styles.dropdownDownIcon} alt="" src="/dropdowndown5.svg" />
</a>


<a href="/sms"> {/* Replace '/sms' with the actual URL or route */}
  <div className={styles.component4}>
    <img className={styles.dashboardIcon} alt="" src="/assets/message.svg" />
    <div className={styles.advancedInfo}>SMS</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/dropdowndown6.svg"
    />
  </div>
</a>

        </div>
        <a href="/AdminProfile">
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameChild}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.past7Days}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img className={styles.setting02Icon} alt="" src="/assets/setting-02.svg" />
        </div>
        </a>
      </div>
      
      {showUserInfo && (
      <div className={styles.userInfo}>
        <div className={styles.xCloseParent}
        onClick={onXCloseIconClick}
        >
          <img
            className={styles.xCloseIcon}
            alt=""
            src="/assets/x-close.svg"
            
          />
          <div className={styles.userInformationParent}>
            <div className={styles.userInformation}>User Information</div>
            <div className={styles.dateJoinedDdmmyyContainer}>
              <span className={styles.dateJoined}>{`Date Joined: `}</span>
              <span className={styles.ddmmyy}>dd/mm/yy</span>
            </div>
            <div className={styles.frameWrapper}>
              <div className={styles.activeGamesParent}>
                <div className={styles.totalNumberOf}>2 Active Games</div>
                <div className={styles.oneMillionBagParent}>
                  <div className={styles.totalNumberOf}>
                    <ul className={styles.oneMillionBag}>One million bag</ul>
                  </div>
                  <div className={styles.chopchopInstantWinContainer}>
                    <ul className={styles.oneMillionBag}>
                      ChopChop Instant win
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.phoneNumber08100000000Parent}>
              <div className={styles.phoneNumber08100000000Container}>
                <span className={styles.dateJoined}>{`Phone Number: `}</span>
                <span className={styles.ddmmyy}>08100000000</span>
              </div>
              <div className={styles.phoneNumber08100000000Container}>
                <span
                  className={styles.dateJoined}
                >{`Service Provider: `}</span>
                <span className={styles.ddmmyy}>MTN</span>
              </div>
              <div className={styles.phoneNumber08100000000Container}>
                <span className={styles.dateJoined}>{`Account Details: `}</span>
                <span className={styles.accessBank10}>
                  Access Bank - 0000000001
                </span>
              </div>
            </div>
            <div className={styles.totalNumberOfGamesPlayedParent}>
              <div className={styles.phoneNumber08100000000Container}>
                <span
                  className={styles.dateJoined}
                >{`Total Number of Games played: `}</span>
                <span className={styles.ddmmyy}>10</span>
              </div>
              <div className={styles.table1}>
                <div className={styles.column8}>
                  <div className={styles.cellHeader2}>
                    <div className={styles.sn}>Game type</div>
                  </div>
                  <div className={styles.cellItem80}>
                    <div className={styles.sn}>Wins</div>
                  </div>
                  <div className={styles.cellItem80}>
                    <div className={styles.sn}>Losses</div>
                  </div>
                </div>
                <div className={styles.column9}>
                  <div className={styles.cellHeader2}>
                    <div className={styles.sn}>One Million Bag</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>2</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>0</div>
                  </div>
                </div>
                <div className={styles.column10}>
                  <div className={styles.cellHeader2}>
                    <div className={styles.sn}>ChopChop Instant win</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>2</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>0</div>
                  </div>
                </div>
                <div className={styles.column8}>
                  <div className={styles.cellHeader2}>
                    <div className={styles.sn}>Mega money</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>2</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>0</div>
                  </div>
                </div>
                <div className={styles.column8}>
                  <div className={styles.cellHeader2}>
                    <div className={styles.sn}>Instant 3/4</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>2</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>0</div>
                  </div>
                </div>
                <div className={styles.column8}>
                  <div className={styles.cellHeader2}>
                    <div className={styles.sn}>Fast Cash</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>2</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>0</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.adminOfficerParent}>
                <div className={styles.phoneNumber08100000000Container}>
                  <span
                    className={styles.dateJoined}
                  >{`Total Amount Spent: `}</span>
                  <span className={styles.accessBank10}>₦5,000</span>
                </div>
                <div className={styles.phoneNumber08100000000Container}>
                  <span className={styles.dateJoined}>{`Total Payout: `}</span>
                  <span className={styles.accessBank10}>₦44,000</span>
                </div>
              </div>
              <div className={styles.table1}>
                <div className={styles.column8}>
                  <div className={styles.cellHeader2}>
                    <div className={styles.sn}>Game type</div>
                  </div>
                  <div className={styles.cellItem80}>
                    <div className={styles.sn}>Amount Spent</div>
                  </div>
                  <div className={styles.cellItem80}>
                    <div className={styles.sn}>Payout</div>
                  </div>
                </div>
                <div className={styles.column9}>
                  <div className={styles.cellHeader2}>
                    <div className={styles.sn}>One Million Bag</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>₦1,000</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>₦11,000</div>
                  </div>
                </div>
                <div className={styles.column10}>
                  <div className={styles.cellHeader2}>
                    <div className={styles.sn}>ChopChop Instant win</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>₦1,000</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>₦11,000</div>
                  </div>
                </div>
                <div className={styles.column8}>
                  <div className={styles.cellHeader2}>
                    <div className={styles.sn}>Mega money</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>₦1,000</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>₦11,000</div>
                  </div>
                </div>
                <div className={styles.column8}>
                  <div className={styles.cellHeader2}>
                    <div className={styles.sn}>Instant 3/4</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>₦1,000</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>₦11,000</div>
                  </div>
                </div>
                <div className={styles.column8}>
                  <div className={styles.cellHeader2}>
                    <div className={styles.sn}>Fast Cash</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>2</div>
                  </div>
                  <div className={styles.cellItem21}>
                    <div className={styles.sn}>0</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={styles.advancedDetailsParent}
              onClick={onFrameContainer22Click}
            >
              <div className={styles.advancedDetails}>Advanced Details</div>
              <img
                className={styles.materialSymbolsdownloadIcon}
                alt=""
                src="/chevronarrowdown.svg"
              />
            </div>
            <div className={styles.export}>
              <div className={styles.advancedInfo}>Export user data.csv</div>
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default Subscriber;
