import { FunctionComponent, useState } from "react";
import styles from "./AddPartners.module.css";
import {Link} from 'react-router-dom';
//import { addPartner } from "../../api";
//import './globalAD.css'

const AddPartners: FunctionComponent = () => {
  const [showUserInfo, setShowUserInfo] = useState(false);
  const handleMoreInfoClick = () => {
    setShowUserInfo(!showUserInfo);
  };
  
  return (
    <div className={styles.partners}>
      <div className={styles.partnersParent}>
        <div className={styles.partners1}>PARTNERS</div>
        <div className={styles.addBuzzycashPartners}>
          Add BuzzyCash Partners
        </div>
        <div className={styles.frameChild} />
        <div className={styles.inputFielsParent}>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue}>
              <div className={styles.companyName}>Company Name</div>
            </div>
            <div className={styles.inputField}>
            <input
    type="text"
    className={styles.inputField} // Replace with your CSS class for the input field
    placeholder="Enter name of company"
  />
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue1}>
              <div className={styles.companyName}>Bank</div>
            </div>
            <div className={styles.inputField1}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text1}>
                  <div className={styles.veryverycol}>
                    Select Bank
                  </div>
                </div>
              </div>
              <img
                className={styles.chevronArrowUpIcon}
                alt=""
                src="/assets/chevron-arrow-down.svg"
                onClick={handleMoreInfoClick}
                              />
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue2}>
              <div className={styles.companyName}>Account Number</div>
            </div>
            <div>
            <input
    type="text"
    className={styles.inputField2} // Replace with your CSS class for the input field
    placeholder="Enter company accounts number"
  />
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue3}>
              <div className={styles.companyName}>Company’s Address</div>
            </div>
            <div>
            <input
    type="text"
    className={styles.inputField2} // Replace with your CSS class for the input field
    placeholder="Enter Company address"
  />
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue4}>
              <div className={styles.companyName}>% of earnings</div>
            </div>
            <div>
            <input
    type="text"
    className={styles.inputField2} // Replace with your CSS class for the input field
    placeholder="% of earning per game"
  />

            </div>
          </div>
          <div className={styles.frameWrapper}>
            <div className={styles.frameParent}>
              <div className={styles.partnershipContractWrapper}>
                <div className={styles.partnershipContract}>
                  Partnership Contract
                </div>
              </div>
              <div className={styles.frameContainer}>
                <div className={styles.uploadFileParent}>
                  <div className={styles.uploadFile}>Upload file</div>
                  <div className={styles.documentUpload}>
                    <div className={styles.uploadParent}>
                      <img
                        className={styles.uploadIcon}
                        alt=""
                        src="/assets/Upload.svg"
                      />
                      <div className={styles.tapToUploadDocumentParent}>
                        <div className={styles.uploadFile}>
                          Tap to Upload Document
                        </div>
                        <div className={styles.jpgPdfGifPngParent}>
                          <div className={styles.jpgPdfGif}>
                            JPG, PDF, GIF, PNG
                          </div>
                          <div className={styles.jpgPdfGif}>Max Size: 10MB</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameWrapper1}>
            <div className={styles.frameGroup}>
              <div className={styles.logoOptionalWrapper}>
                <div className={styles.partnershipContract}>
                  Logo (optional)
                </div>
              </div>
              <div className={styles.frameWrapper2}>
                <div className={styles.uploadFileParent}>
                  <div className={styles.uploadFile}>Upload file</div>
                  <div className={styles.documentUpload}>
                    <div className={styles.uploadParent}>
                      <img
                        className={styles.uploadIcon}
                        alt=""
                        src="/assets/Upload.svg"
                      />
                      <div className={styles.tapToUploadDocumentParent}>
                        <div className={styles.uploadFile}>
                          Tap to Upload Document
                        </div>
                        <div className={styles.jpgPdfGifPngParent}>
                          <div className={styles.jpgPdfGif}>
                            JPG, PDF, GIF, PNG
                          </div>
                          <div className={styles.jpgPdfGif}>Max Size: 10MB</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {showUserInfo && (
              <div className={styles.frameParent1}>
                <div className={styles.frameParent2}>
                  <div className={styles.frameWrapper3}>
                    <input
    type="text"
    className={styles.frameParent3} // Replace with your CSS class for the input field
    placeholder="Search"
  />
                  </div>
                  <div className={styles.frameParent4}>
                    <div className={styles.frameParent5}>
                      <div className={styles.groupParent}>
                        <div className={styles.ellipseParent}>
                          <div className={styles.groupChild} />
                          <div className={styles.div}>9</div>
                        </div>
                        <div className={styles.paymentServiceBank}>
                          9 Payment Service Bank
                        </div>
                      </div>
                      <div className={styles.div1}>
                        <div className={styles.child} />
                        <div className={styles.a}>#</div>
                      </div>
                    </div>
                    <div className={styles.frameItem} />
                    <div className={styles.frameParent6}>
                      <div className={styles.frameParent5}>
                        <div className={styles.groupParent}>
                          <div className={styles.ellipseParent}>
                            <div className={styles.groupChild} />
                            <div className={styles.aa}>AA</div>
                          </div>
                          <div className={styles.aaaFinanceAnd}>
                            AAA Finance and Investment Company Limited
                          </div>
                        </div>
                        <div className={styles.div1}>
                          <div className={styles.child} />
                          <div className={styles.a}>A</div>
                        </div>
                      </div>
                      <div className={styles.frameWrapper4}>
                        <div className={styles.groupParent1}>
                          <div className={styles.ellipseParent}>
                            <div className={styles.groupChild} />
                            <div className={styles.aa}>AB</div>
                          </div>
                          <div className={styles.aaaFinanceAnd}>
                            AB Microfinance Bank
                          </div>
                        </div>
                      </div>
                      <div className={styles.frameWrapper4}>
                        <div className={styles.groupParent1}>
                          <div className={styles.ellipseParent}>
                            <div className={styles.groupChild} />
                            <div className={styles.ac}>AC</div>
                          </div>
                          <div className={styles.aaaFinanceAnd}>
                            Access Bank
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.frameItem} />
                    <div className={styles.frameParent6}>
                      <div className={styles.frameParent5}>
                        <div className={styles.groupParent}>
                          <div className={styles.ellipseParent}>
                            <div className={styles.groupChild} />
                            <div className={styles.aa}>BA</div>
                          </div>
                          <div
                            className={styles.aaaFinanceAnd}
                          >{`Balogun Access Limited `}</div>
                        </div>
                        <div className={styles.div1}>
                          <div className={styles.child} />
                          <div className={styles.a}>B</div>
                        </div>
                      </div>
                      <div className={styles.frameWrapper4}>
                        <div className={styles.groupParent1}>
                          <div className={styles.ellipseParent}>
                            <div className={styles.groupChild} />
                            <div className={styles.aa}>BB</div>
                          </div>
                          <div className={styles.aaaFinanceAnd}>
                            Burkinafaso Bank
                          </div>
                        </div>
                      </div>
                      <div className={styles.frameWrapper4}>
                        <div className={styles.groupParent1}>
                          <div className={styles.ellipseParent}>
                            <div className={styles.groupChild} />
                            <div className={styles.aa}>BC</div>
                          </div>
                          <div className={styles.aaaFinanceAnd}>
                            Borderline Company
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.frameItem} />
                    <div className={styles.frameParent6}>
                      <div className={styles.frameParent5}>
                        <div className={styles.groupParent}>
                          <div className={styles.ellipseParent}>
                            <div className={styles.groupChild} />
                            <div className={styles.aa}>CA</div>
                          </div>
                          <div className={styles.aaaFinanceAnd}>
                            Capstone Microfinance Bank
                          </div>
                        </div>
                        <div className={styles.div1}>
                          <div className={styles.child} />
                          <div className={styles.a}>C</div>
                        </div>
                      </div>
                      <div className={styles.frameWrapper4}>
                        <div className={styles.groupParent1}>
                          <div className={styles.ellipseParent}>
                            <div className={styles.groupChild} />
                            <div className={styles.aa}>CA</div>
                          </div>
                          <div className={styles.aaaFinanceAnd}>
                            Cashconnect MFB
                          </div>
                        </div>
                      </div>
                      <div className={styles.frameWrapper4}>
                        <div className={styles.groupParent1}>
                          <div className={styles.ellipseParent}>
                            <div className={styles.groupChild} />
                            <div className={styles.aa}>CE</div>
                          </div>
                          <div className={styles.aaaFinanceAnd}>
                            Central Bank of Nigeria
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.frameItem} />
                    <div className={styles.frameParent6}>
                      <div className={styles.frameParent5}>
                        <div className={styles.groupParent}>
                          <div className={styles.ellipseParent}>
                            <div className={styles.groupChild} />
                            <div className={styles.aa}>DA</div>
                          </div>
                          <div className={styles.aaaFinanceAnd}>
                            DAL Microfinance Bank
                          </div>
                        </div>
                        <div className={styles.div1}>
                          <div className={styles.child} />
                          <div className={styles.a}>D</div>
                        </div>
                      </div>
                      <div className={styles.frameWrapper4}>
                        <div className={styles.groupParent1}>
                          <div className={styles.ellipseParent}>
                            <div className={styles.groupChild} />
                            <div className={styles.aa}>DE</div>
                          </div>
                          <div className={styles.aaaFinanceAnd}>
                            Delta Trust Mortgage Bank
                          </div>
                        </div>
                      </div>
                      <div className={styles.frameWrapper4}>
                        <div className={styles.groupParent1}>
                          <div className={styles.ellipseParent}>
                            <div className={styles.groupChild} />
                            <div className={styles.aa}>DO</div>
                          </div>
                          <div
                            className={styles.aaaFinanceAnd}
                          >{`DOT Microfinance Bank `}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.selectBank}>Select Bank</div>
              </div>
              )}

            </div>
          </div>
        </div>
      </div>
      <div className={styles.partnersChild} />
      <div className={styles.inputFieldParent}>
<Link to="/Add">
        <div className={styles.inputField5}>
          <img className={styles.editIcon} alt="" src="/edit.svg" />
          <div className={styles.uploadFile}>Add this partner</div>
        </div>
</Link>
        <div className={styles.inputField6}>
          <img className={styles.editIcon} alt="" src="/edit.svg" />
          <div className={styles.uploadFile}>{`Cancel `}</div>
        </div>
      </div>
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
<a href="/dashboard">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/dashboard.svg" />
            <div className={styles.uploadFile}>Dashboard</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
</a>

<a href="/subscribers">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/user-02.svg" />
            <div className={styles.uploadFile}>Subscribers</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
</a>

<a href="/subscriptions">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.uploadFile}>Subscriptions</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown2.svg" />
          </div>
</a>

          <div className={styles.gm}>
<a href="/ManageGame">
            <div className={styles.component4}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.uploadFile}>Game Management</div>
              <img
                className={styles.dropdownUpIcon}
                alt=""
                src="/assets/dropdown-down.svg"
              />
            </div>
</a>

          </div>
          <div className={styles.component15}>
            <div className={styles.component14}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/partnership.svg"
              />
              <div className={styles.dashboard4}>Partners</div>
              <img
                className={styles.dropdownUpIcon}
                alt=""
                src="/assets/dropdown-down.svg"
              />
            </div>
            <div className={styles.subComponents}>
              <div className={styles.subNavComponents}>
<a href="/partners">
                <div className={styles.checklistParent}>
                  <img
                    className={styles.checklistIcon}
                    alt=""
                    src="/assets/checklist.svg"
                  />
                  <div className={styles.uploadFile}>View All Partners</div>
                </div>
</a>

              </div>
              <div className={styles.subNavComponents1}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/add-user.svg"
                />
                <div className={styles.dashboard6}>Add Partner</div>
              </div>
              <div className={styles.subNavComponents}>
<a href="/PartnerHistory">
                <div className={styles.checklistParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/history.svg"
                  />
                  <div className={styles.uploadFile}>Payout History</div>
                </div>
</a>

              </div>
            </div>
          </div>
<a href="/AdminSettings">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
            <div className={styles.uploadFile}>Admin Management</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
</a>

<a href="/sms">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/message.svg" />
            <div className={styles.uploadFile}>SMS</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
</a>

        </div>
<a href="/AdminProfile">
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.rectangleIcon}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.jpgPdfGif}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img className={styles.setting02Icon} alt="" src="/assets/setting-02.svg" />
        </div>
</a>
      </div>
    </div>
  );
};

export default AddPartners;
