import { FunctionComponent, useState } from "react";
import styles from "./PayPartners.module.css";
//import PaymentPopup from "./PaymentPopup";
import { Link } from 'react-router-dom';
//import './globalPY.css'

const PayPartner: FunctionComponent = () => {
  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <div className={styles.frameParent}>
      <div className={styles.frameGroup}>
        <div className={styles.frameContainer}>
          <div className={styles.arrowLeftLineParent}>
            <Link to="/PartnerDetails">
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/assets/arrow-left-line.svg"
            />
            </Link>
            <div className={styles.goBack}>Go back</div>
          </div>
          <div className={styles.partners}>PARTNERS</div>
        </div>
        <div className={styles.frameDiv}>
          <div className={styles.frameParent1}>
            <div className={styles.payingPartnersParent}>
              <div className={styles.payingPartners}>Paying Partners</div>
              <div className={styles.sendMoneyTo}>
                Send money to BuzzyCash Partners with ease
              </div>
            </div>
            <div className={styles.exxonmobilAccountBalanceParent}>
              <div className={styles.exxonmobilAccountBalance}>
                ExxonMobil Account Balance
              </div>
              <div className={styles.wallet}>
                <div className={styles.walletChild} />
                <img
                  className={styles.wallet01Icon}
                  alt=""
                  src="/assets/wallet-01.svg"
                />
                <div className={styles.div}>₦500,000,00</div>
              </div>
            </div>
          </div>
          <div className={styles.inputFielsParent}>
            <div className={styles.inputFiels}>
              <div className={styles.headerValue}>
                <div className={styles.bank}>Bank</div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.leftIconParent}>
                  <img
                    className={styles.leftIcon}
                    alt=""
                    src="/lefticon@2x.png"
                  />
                  <div className={styles.text}>
                    <div className={styles.sterlingBank}>Sterling Bank</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.payingPartnersParent}>
              <div className={styles.headerValue1}>
                <div className={styles.bank}>Account Number</div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.leftIconParent}>
                  <img
                    className={styles.leftIcon}
                    alt=""
                    src="/lefticon@2x.png"
                  />
                  <div className={styles.text}>
                    <div className={styles.sterlingBank}>0000000001</div>
                  </div>
                </div>
                <div className={styles.exxonmobil}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.inputFiels2}>
              <div className={styles.headerValue2}>
                <div className={styles.bank}>Amount to Send</div>
              </div>
              <div className={styles.inputField2}>
                <div className={styles.inputFieldChild} />
                <div className={styles.div2}>₦</div>
              </div>
            </div>
            <div className={styles.payingPartnersParent}>
              <div className={styles.headerValue3}>
                <div className={styles.bank}>Narration (Optional)</div>
              </div>
              <div>
                <input
            className={styles.inputField3} // Apply appropriate styles here
            type="text"
            placeholder="Enter short narration"
            value={searchValue}
            onChange={handleInputChange}
          />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.inputFieldParent}>
          <Link to='/PayPopup'>
          <div className={styles.inputField4}>
            <img className={styles.editIcon} alt="" src="/edit@2x.png" />
            <div className={styles.goBack}>Send Money</div>
          </div>
          </Link>
          <div className={styles.inputField5}>
            <img className={styles.editIcon} alt="" src="/edit@2x.png" />
            <div className={styles.goBack}>{`Cancel `}</div>
          </div>
        </div>
      </div>
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
          <a href="/dashboard">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/dashboard.svg"
            />
            <div className={styles.goBack}>Dashboard</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/subscriber">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/user-02.svg" />
            <div className={styles.goBack}>Subscribers</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/subscription">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.goBack}>Subscriptions</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/ManageGame">
          <div className={styles.gm}>
            <div className={styles.component4}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.goBack}>Game Management</div>
              <img
                className={styles.arrowLeftLineIcon}
                alt=""
                src="/assets/dropdown-up.svg"
              />
            </div>
          </div>
          </a>
          <div className={styles.component15}>
            <div className={styles.component14}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/partnership.svg"
              />
              <div className={styles.dashboard4}>Partners</div>
              <img
                className={styles.arrowLeftLineIcon}
                alt=""
                src="/assets/dropdown-up.svg"
              />
            </div>
            <div className={styles.subComponents}>
              <div className={styles.subNavComponents}>
                <img
                  className={styles.checklistIcon}
                  alt=""
                  src="/assets/checklist.svg"
                />
                <div className={styles.dashboard5}>View All Partners</div>
              </div>
              <a href="/AddPartners">
              <div className={styles.subNavComponents1}>
                <div className={styles.addUserParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/add-user.svg"
                  />
                  <div className={styles.goBack}>Add Partner</div>
                </div>
              </div>
              </a>
              <a href="/PayoutHistory">
              <div className={styles.subNavComponents1}>
                <div className={styles.addUserParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/history.svg"
                  />
                  <div className={styles.goBack}>Payout History</div>
                </div>
              </div>
              </a>
            </div>
          </div>
          <a href="/AdminSettings">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
            <div className={styles.goBack}>Admin Management</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/sms">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/message.svg"
            />
            <div className={styles.goBack}>SMS</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
        </div>
        <a href="/AdminProfile">
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameChild}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.payingPartners}>ADMIN OFFICER</div>
              <b className={styles.sendMoneyTo}>Gilbert Adams</b>
            </div>
          </div>
          <img
            className={styles.setting02Icon}
            alt=""
            src="/assets/setting-02.svg"
          />
        </div>
        </a>
      </div>
    </div>
  );
};

export default PayPartner;
