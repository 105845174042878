import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { getTotalSubscribers, getActiveGames, getTotalRevenue, getRevenueLast7Days, getTodaysRevenue, getTotalSubscribersLast7Days } from "../api";
import styles from "./Dashboard.module.css";
//import './global.css';


const Dashboard: FunctionComponent = () => {
const [totalSubscribers, setTotalSubscribers] = useState<number>(0);
  const [activeGames, setActiveGames] = useState<number>(0);
  const [totalRevenue, setTotalRevenue] = useState<number>(0);
  const [revenueLast7Days, setRevenueLast7Days] = useState<number>(0);
  const [todaysRevenue, setTodaysRevenue] = useState<number>(0);
  const [sevendaysSubscribers, setsevendaysSubscribers] = useState<number>(0);

  const fetchData = useCallback(async () => {
    try {
      const subscribers = await getTotalSubscribers();
      const games = await getActiveGames();
      const revenue = await getTotalRevenue();
      const revenue7Days = await getRevenueLast7Days();
      const todayRevenue = await getTodaysRevenue();
      const sevendaysSubscribersData = await getTotalSubscribersLast7Days();
      setTotalSubscribers(subscribers);
      setActiveGames(games);
      setTotalRevenue(revenue);
      setRevenueLast7Days(revenue7Days);
      setTodaysRevenue(todayRevenue);
      setsevendaysSubscribers(sevendaysSubscribersData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);
  

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [buttonText, setButtonText] = useState('Generate Report');
  const [showDropdown, setShowDropdown] = useState(false);

  const handleButtonClick = () => {
    if (buttonText === 'Generate Report') {
      setButtonText('Report is generating');

      // Simulate an asynchronous operation (e.g., API call, data processing)
      setTimeout(() => {
        setButtonText('Export');
      }, 2000);
    } else if (buttonText === 'Export') {
      setShowDropdown(!showDropdown);
    }
  };

  const handleExportOption = (format: string) => {
    // Implement your logic for export here
    console.log(`Exporting as ${format}`);
    
    // Additional logic as needed

    // Reset the state and change the button text back to 'Generate Report'
    setButtonText('Generate Report');
    setShowDropdown(false);
  };

  const onDropdownContainerClick = () => {
    // Handle dropdown container click (optional)
  };

  return (
    <div className={styles.dashboard}>
      <main className={styles.dashboardParent}>
        <h3 className={styles.dashboard1}>DASHBOARD</h3>
        <nav className={styles.dashboardItemsParent}>
          <div className={styles.dashboardItems}>
            <div className={styles.frameParent}>
              <div className={styles.totalNumberOfSubscribersParent}>
                <div className={styles.totalNumberOf}>
                  Total Number of Subscribers
                </div>
                <div className={styles.div}>{totalSubscribers}</div>
              </div>
              <img className={styles.addUserIcon} alt="" src="/assets/add-user.svg" />
            </div>
            <div className={styles.dashboardItemsChild} />
            <div className={styles.past7DaysParent}>
              <div className={styles.past7Days}>Past 7 days</div>
              <div className={styles.frameGroup}>
                <div className={styles.arrowUpLineParent}>
                  <img
                    className={styles.arrowUpLineIcon}
                    alt=""
                    src="/assets/arrow-up-line.svg"
                  />
                  <div className={styles.totalNumberOf}>+{revenueLast7Days} Subscribers</div>
                </div>
                <div className={styles.arrowDownLineParent}>
                  <img
                    className={styles.arrowUpLineIcon}
                    alt=""
                    src="/assets/arrow-down-line.svg"
                  />
                  <div className={styles.totalNumberOf}> + {sevendaysSubscribers} Unsubscribed</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.dashboardItems}>
            <div className={styles.frameContainer}>
              <div className={styles.numberOfActiveGamesParent}>
                <div className={styles.totalNumberOf}>
                  Number of active games
                </div>
                <div className={styles.div1}>{activeGames}</div>
              </div>
              <img
                className={styles.addUserIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
            </div>
            <div className={styles.dashboardItemsChild} />
            <div className={styles.past7DaysParent}>
              <div className={styles.past7Days}>Past 7 days</div>
              <div className={styles.frameWrapper}>
                <div className={styles.arrowUpLineParent}>
                  <img
                    className={styles.arrowUpLineIcon}
                    alt=""
                    src="/assets/arrow-up-line.svg"
                  />
                  <div className={styles.totalNumberOf}>
                    +{todaysRevenue} active new games
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.dashboardItems2}>
            <div className={styles.monthlyReportGenerator}>
              Monthly Report Generator
            </div>
            <div className={styles.frameDiv}>
              <div className={styles.dateParent} style={{ cursor: 'pointer' }}>
                <div className={styles.date}>Date</div>
                  <img className={styles.chevronArrowDown} alt="" src="/assets/chevron-arrow-down.svg" />
              </div>
              <div className={styles.dateParent} style={{ cursor: 'pointer' }}>
                <div className={styles.date}>January</div>
            
                  <img
                    className={styles.chevronArrowDown}
                    alt=""
                    src="/assets/chevron-arrow-down.svg"
                  />
              
              </div>
              <div className={styles.dateParent} style={{ cursor: 'pointer' }}>
                <div className={styles.date}>2023</div>
                <img
                  className={styles.chevronArrowDown}
                  alt=""
                  src="/assets/chevron-arrow-down.svg"
                />
              </div>
            </div>
        
      <div className={styles.inputField} >
        <button className={styles.smallButton} onClick={handleButtonClick} style={{ cursor: 'pointer' }}>
          <div className={styles.subscribers}>{buttonText}</div>
          <img className={styles.smallButtonChild} alt="" src="/group-1.svg" />
        </button>
      

      {showDropdown && (
        <div className={styles.dropdown} onClick={onDropdownContainerClick}>
          <div className={styles.exportParent}>
            <div
              className={styles.export}
              onClick={() => handleExportOption('csv')}
            >
              <div className={styles.exportAsCsv2}>Export as .csv</div>
              <img className={styles.smallButtonChild} alt="" src="/group-1.svg" />
            </div>
            <div className={styles.frameChild} />
            <div
              className={styles.export}
              onClick={() => handleExportOption('pdf')}
            >
              <div className={styles.date}>Export as .pdf</div>
              <img className={styles.exportAsCsv2} alt="" src="/group-1.svg" />
            </div>
          </div>
        </div>
      )}
      </div>

          </div>
        </nav>
        <section className={styles.graph}>
          <div className={styles.horizontalLinesParent}>
            <img
              className={styles.horizontalLinesIcon}
              alt=""
              src="/assets/horizontal lines.svg"
            />
            <img className={styles.dividerIcon} alt="" src="/assets/divider.svg" />
            <div className={styles.values}>
              <div className={styles.div3}>0</div>
              <div className={styles.div4}>10</div>
              <div className={styles.div5}>20</div>
              <div className={styles.div6}>30</div>
              <div className={styles.div7}>40</div>
              <div className={styles.div8}>50</div>
              <div className={styles.div9}>60</div>
            </div>
            <div className={styles.graphicMain}>
              <div className={styles.graphicMask}>
                <img
                  className={styles.bgTodayIcon}
                  alt=""
                  src="/assets/bg today.svg"
                />
                <img
                  className={styles.todayGraphIcon}
                  alt=""
                  src="/assets/today graph.svg"
                />
                <div className={styles.pointer}>
                  <div className={styles.circlePointer2} />
                  <div className={styles.circlePointer1} />
                  <div className={styles.value}>
                    <img className={styles.sheetIcon} alt="" src="/assets/sheet.svg" />
                    <div className={styles.pointerValue}>38</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.oct2020Parent}>
            <div className={styles.oct2020}>
              <div className={styles.jan2022}>JAN 2022</div>
            </div>
            <div className={styles.oct2020}>
              <div className={styles.jan2022}>FEB 2022</div>
            </div>
            <div className={styles.oct2020}>
              <div className={styles.mar2022}>MAR 2022</div>
            </div>
            <div className={styles.oct2020}>
              <div className={styles.apr2022}>APR 2022</div>
            </div>
            <div className={styles.oct2020}>
              <div className={styles.apr2022}>MAY 2022</div>
            </div>
            <div className={styles.oct2020}>
              <div className={styles.apr2022}>JUN 2022</div>
            </div>
            <div className={styles.oct2020}>
              <div className={styles.jul2022}>JUL 2022</div>
            </div>
            <div className={styles.oct2020}>
              <div className={styles.apr2022}>AUG 2022</div>
            </div>
          </div>
          <div className={styles.smallButtonParent}>
            <div className={styles.smallButton1}>
              <div className={styles.date}>SUBSCRIBERS</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-11.svg"
              />
            </div>
            <button className={styles.smallButton2}>
              <div className={styles.subscribers3}>RENEWALS</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-2.svg"
              />
            </button>
            <div className={styles.smallButton3}>
              <div className={styles.date}>UNSUBSCRIBERS</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-12.svg"
              />
            </div>
            <div className={styles.smallButton3}>
              <div className={styles.date}>GAMES PLAYED</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-13.svg"
              />
            </div>
          </div>
          <h3 className={styles.overview}>OVERVIEW</h3>
          <div className={styles.renewalsParent}>
            <div className={styles.totalNumberOf}>RENEWALS</div>
            <div className={styles.div10}>38</div>
          </div>
        </section>
        <div className={styles.frameParent1}>
          <button className={styles.allWrapper}>
            <div className={styles.all}>All</div>
          </button>
          <div className={styles.oneMillionBagWrapper}>
            <div className={styles.date}>One million bag</div>
          </div>
          <div className={styles.oneMillionBagWrapper}>
            <div className={styles.date}>ChopChop Instant Win</div>
          </div>
          <div className={styles.oneMillionBagWrapper}>
            <div className={styles.megaMoney}>Mega Money</div>
          </div>
          <div className={styles.oneMillionBagWrapper}>
            <div className={styles.megaMoney}>Instant 3/4</div>
          </div>
          <div className={styles.oneMillionBagWrapper}>
            <div className={styles.megaMoney}>Fast Cash</div>
          </div>
        </div>
        <h3 className={styles.revenueStatistics}>Revenue Statistics</h3>
        <div className={styles.frameParent2}>
          <div className={styles.totalRevenueParent}>
            <div className={styles.totalRevenue}>Total Revenue</div>
            <div className={styles.wrapper}>
              <div className={styles.totalNumberOf}>₦{totalRevenue.toFixed(2)}</div>
            </div>
          </div>
          <div className={styles.totalRevenueParent}>
            <div className={styles.totalRevenue}>
              Revenue in the past 7 days
            </div>
            <div className={styles.group}>
              <div className={styles.totalNumberOf}>₦{revenueLast7Days.toFixed(2)}</div>
              <div className={styles.arrowDownLineGroup}>
                <img
                  className={styles.chevronArrowDown}
                  alt=""
                  src="/assets/arrow-down-line.svg"
                />
                <div className={styles.date}>
          {revenueLast7Days > 0 ? '+' : ''} {revenueLast7Days.toFixed(2)} ({Math.abs(((revenueLast7Days - totalRevenue) / totalRevenue) * 100).toFixed(2)}% {revenueLast7Days > 0 ? 'increase' : 'decrease'} from last week)
        </div>
              </div>
            </div>
          </div>
          <div className={styles.totalRevenueParent}>
            <div className={styles.todaysRevenueWrapper}>
              <div className={styles.todaysRevenue}>Todays Revenue</div>
            </div>
            <div className={styles.wrapper}>
              <div className={styles.totalNumberOf}>₦{todaysRevenue.toFixed(2)}</div>
            </div>
          </div>
        </div>
      </main>
      <div className={styles.dashboardChild} />
      <div className={styles.dashboardItem} />
      <div className={styles.dashboardInner} />
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
          <div className={styles.component4}>
            <img
              className={styles.arrowUpLineIcon}
              alt=""
              src="/assets/dashboard.svg"
            />
            <div className={styles.past7Days}>Dashboard</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
          <a href="/Subscribers" className={styles.component5}>
            <img className={styles.arrowUpLineIcon} alt="" src="/assets/user-02.svg" />
            <div className={styles.dashboard3}>Subscribers</div>
            </a>
          <a href="/Subscriptions/subscription" className={styles.component5}>
            <img
              className={styles.arrowUpLineIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.dashboard3}>Subscriptions</div>
            </a>
          <div className={styles.gm}>
          <a href="/ManageGame">
  <div className={styles.component5}>
    <img
      className={styles.arrowUpLineIcon}
      alt=""
      src="/assets/component 5.svg"
    />
    <div className={styles.dashboard3}>Game Management</div>
    <img
      className={styles.dropdownDownIcon3}
      alt=""
      src="/assets/dropdown-down.svg"
    />
  </div>
</a>

          </div>
          <a href="/partners">
  <div className={styles.component5}>
    <img
      className={styles.arrowUpLineIcon}
      alt=""
      src="/assets/partnership.svg"
    />
    <div className={styles.dashboard3}>Partners</div>
    <img
      className={styles.dropdownDownIcon3}
      alt=""
      src="/assets/dropdown-down.svg"
    />
  </div>
</a>

<a href="/AdminSettings">
  <div className={styles.component5}>
    <img className={styles.arrowUpLineIcon} alt="" src="/assets/admin.svg" />
    <div className={styles.dashboard3}>Admin Management</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/dropdowndown5.svg"
    />
  </div>
</a>

<a href="/sms">
  <div className={styles.component5}>
    <img className={styles.arrowUpLineIcon} alt="" src="/assets/message.svg" />
    <div className={styles.dashboard3}>SMS</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/dropdowndown6.svg"
    />
  </div>
</a>

        </div>
        <a href="/AdminProfile">
  <div className={styles.component82}>
    <div className={styles.account1}>ACCOUNT</div>
    <div className={styles.rectangleParent1}>
      <img
        className={styles.frameChild1}
        alt=""
        src="/assets/Rectangle 342.png"
      />
      <div className={styles.adminOfficerParent1}>
        <div className={styles.date1}>ADMIN OFFICER</div>
        <b className={styles.gilbertAdams1}>Gilbert Adams</b>
      </div>
    </div>
    <img className={styles.setting02Icon1} alt="" src="/assets/setting-02.svg" />
  </div>
</a>

      </div>
    </div>
  );
};

export default Dashboard;
