import { FunctionComponent } from "react";
//import Filter from "./Filter";
//import PortalPopup from "./PortalPopup";
import styles from "./sms.module.css";

const BULKSMS: FunctionComponent = () => {




  return (
    <>
      <div className={styles.bulkSms}>
        <div className={styles.bulkSmsParent}>
          <div className={styles.bulkSms1}>BULK SMS</div>
          <div className={styles.recentSmsActivityParent}>
            <div className={styles.recentSmsActivity}>Recent SMS Activity</div>
            <div className={styles.rectangleParent}>
              <img
                className={styles.frameChild}
                alt=""
                src="/assets/Rectangle 342.png"
              />
              <div className={styles.adminOfficerParent}>
                <div className={styles.adminOfficer}>ADMIN OFFICER</div>
                <b className={styles.gilbertAdams}>Gilbert Adams</b>
              </div>
            </div>
            <div className={styles.phoneNumber234Container}>
              <span className={styles.phoneNumber}>
                <span>Phone Number:</span>
              </span>
              <span className={styles.span}>
                <span className={styles.phoneNumber}>{` `}</span>
                <span className={styles.span2}>+234 80 123 4456</span>
              </span>
            </div>
            <div className={styles.frameItem} />
            <div className={styles.frameInner} />
            <div className={styles.frameParent}>
              <div className={styles.march212023Parent}>
                <div className={styles.recipient}>March 21, 2023</div>
                <div className={styles.frameWrapper}>
                  <div className={styles.toPhonebook1Parent}>
                    <div className={styles.toPhonebook1Container}>
                      <span>
                        <span>To</span>
                      </span>
                      <span className={styles.phonebook1}>
                        <span>{`:  `}</span>
                        <span>Phonebook 1</span>
                      </span>
                    </div>
                    <div className={styles.getLoadsOf}>
                      Get loads of cash in the chopchop instant cash with as low
                      as 50 Naira and stand a cj=hance to be a winner
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.march212023Parent}>
                <div className={styles.recipient}>March 21, 2023</div>
                <div className={styles.frameWrapper}>
                  <div className={styles.toPhonebook1Parent}>
                    <div className={styles.toPhonebook1Container}>
                      <span>
                        <span>To</span>
                      </span>
                      <span className={styles.phonebook1}>
                        <span>{`:  `}</span>
                        <span>Phonebook 1</span>
                      </span>
                    </div>
                    <div className={styles.getLoadsOf}>
                      Get loads of cash in the chopchop instant cash with as low
                      as 50 Naira and stand a cj=hance to be a winner
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.march212023Parent}>
                <div className={styles.recipient}>March 21, 2023</div>
                <div className={styles.frameWrapper}>
                  <div className={styles.toPhonebook1Parent}>
                    <div className={styles.toPhonebook1Container}>
                      <span>
                        <span>To</span>
                      </span>
                      <span className={styles.phonebook1}>
                        <span>{`:  `}</span>
                        <span>Phonebook 1</span>
                      </span>
                    </div>
                    <div className={styles.getLoadsOf}>
                      Get loads of cash in the chopchop instant cash with as low
                      as 50 Naira and stand a cj=hance to be a winner
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.march212023Parent}>
                <div className={styles.recipient}>March 21, 2023</div>
                <div className={styles.frameWrapper}>
                  <div className={styles.toPhonebook1Parent}>
                    <div className={styles.toPhonebook1Container}>
                      <span>
                        <span>To</span>
                      </span>
                      <span className={styles.phonebook1}>
                        <span>{`:  `}</span>
                        <span>Phonebook 1</span>
                      </span>
                    </div>
                    <div className={styles.getLoadsOf}>
                      Get loads of cash in the chopchop instant cash with as low
                      as 50 Naira and stand a cj=hance to be a winner
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.march212023Parent}>
                <div className={styles.recipient}>March 21, 2023</div>
                <div className={styles.frameWrapper}>
                  <div className={styles.toPhonebook1Parent}>
                    <div className={styles.toPhonebook1Container}>
                      <span>
                        <span>To</span>
                      </span>
                      <span className={styles.phonebook1}>
                        <span>{`:  `}</span>
                        <span>Phonebook 1</span>
                      </span>
                    </div>
                    <div className={styles.getLoadsOf}>
                      Get loads of cash in the chopchop instant cash with as low
                      as 50 Naira and stand a cj=hance to be a winner
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.march212023Parent}>
                <div className={styles.recipient}>March 21, 2023</div>
                <div className={styles.frameWrapper}>
                  <div className={styles.toPhonebook1Parent}>
                    <div className={styles.toPhonebook1Container}>
                      <span>
                        <span>To</span>
                      </span>
                      <span className={styles.phonebook1}>
                        <span>{`:  `}</span>
                        <span>Phonebook 1</span>
                      </span>
                    </div>
                    <div className={styles.getLoadsOf}>
                      Get loads of cash in the chopchop instant cash with as low
                      as 50 Naira and stand a cj=hance to be a winner
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.march212023Parent}>
                <div className={styles.recipient}>March 21, 2023</div>
                <div className={styles.frameWrapper}>
                  <div className={styles.toPhonebook1Parent}>
                    <div className={styles.toPhonebook1Container}>
                      <span>
                        <span>To</span>
                      </span>
                      <span className={styles.phonebook1}>
                        <span>{`:  `}</span>
                        <span>Phonebook 1</span>
                      </span>
                    </div>
                    <div className={styles.getLoadsOf}>
                      Get loads of cash in the chopchop instant cash with as low
                      as 50 Naira and stand a cj=hance to be a winner
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.sendSmsParent}>
            <div className={styles.sendSms}>Send SMS</div>
            <div className={styles.recipientParent}>
              <div className={styles.recipient}>Recipient</div>
              <div className={styles.documentUploadParent}>
                <div className={styles.documentUpload}>
                  <div className={styles.uploadParent}>
                    <img
                      className={styles.uploadIcon}
                      alt=""
                      src="/assets/Upload.svg"
                    />
                    <div className={styles.march212023Parent}>
                      <div className={styles.toPhonebook1Container}>
                        Tap to Upload PhoneBook
                      </div>
                      <div className={styles.jpgPdfGifPngParent}>
                        <div className={styles.adminOfficer}>CSV</div>
                        <div className={styles.adminOfficer}>
                          Max Size: 10MB
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.smallButtonParent}>
                  <div className={styles.smallButton}>
                    <div
                      className={styles.adminOfficer}
                    >{`Input Manually `}</div>
                    <img
                      className={styles.smallButtonChild}
                      alt=""
                      src="/group-1.svg"
                    />
                  </div>
                  <div className={styles.smallButton}>
                    <div className={styles.adminOfficer}>Select PhoneBook</div>
                    <img
                      className={styles.smallButtonChild}
                      alt=""
                      src="/group-11.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.enterSmsParent}>
              <div className={styles.recipient}>Enter SMS</div>
              <div className={styles.enterSmsHereWrapper}>
                <div className={styles.enterSmsHere}>Enter SMS Here</div>
              </div>
            </div>
            <div className={styles.inputField}>
              <img className={styles.editIcon} alt="" src="/edit.svg" />
              <div className={styles.toPhonebook1Container}>Send SMS</div>
            </div>
          </div>
          <div className={styles.smsActivityParent}>
            <div className={styles.smsActivity}>SMS Activity</div>
            <div className={styles.smallButtonGroup}>
              <div className={styles.smallButton}>
                <div className={styles.adminOfficer}>Today</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-12.svg"
                />
              </div>
              <div className={styles.smallButton3}>
                <div className={styles.subscribers3}>7 Days</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-2.svg"
                />
              </div>
              <div className={styles.smallButton4}>
                <div className={styles.subscribers3}>30 Days</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-13.svg"
                />
              </div>
              <div className={styles.smallButton4}>
                <div className={styles.subscribers3}>90 Days</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-14.svg"
                />
              </div>
            </div>
            <div className={styles.values}>
              <div className={styles.march1}>March 1</div>
              <div className={styles.march2}>March 2</div>
              <div className={styles.march3}>March 3</div>
              <div className={styles.march2}>March 4</div>
              <div className={styles.march5}>March 5</div>
              <div className={styles.march1}>March 6</div>
            </div>
            <div className={styles.lineDiv} />
            <div className={styles.frameChild1} />
            <div className={styles.frameChild2} />
            <div className={styles.frameChild3} />
            <div className={styles.frameChild4} />
            <div className={styles.frameChild5} />
            <img
              className={styles.frameIcon}
              alt=""
              src="/assets/bg2 today.svg"
            />
            <div className={styles.frameGroup}>
              <div className={styles.rectangleGroup}>
                <div className={styles.rectangleDiv} />
                <div className={styles.sent}>Sent</div>
              </div>
              <div className={styles.rectangleGroup}>
                <div className={styles.frameChild6} />
                <div className={styles.sent}>Received</div>
              </div>
              <div className={styles.rectangleGroup}>
                <div className={styles.frameChild7} />
                <div className={styles.sent}>Delivered</div>
              </div>
              <div className={styles.rectangleGroup}>
                <div className={styles.frameChild8} />
                <div className={styles.sent}>Failed</div>
              </div>
              <div className={styles.rectangleGroup}>
                <div className={styles.frameChild9} />
                <div className={styles.sent}>Opt-out</div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.parent}>
                <div className={styles.sent}>5115</div>
                <div className={styles.sent1}>Sent</div>
              </div>
              <div className={styles.group}>
                <div className={styles.sent}>5100</div>
                <div className={styles.sent1}>Received</div>
              </div>
              <div className={styles.container}>
                <div className={styles.sent}>5001</div>
                <div className={styles.sent1}>Delivered</div>
              </div>
              <div className={styles.parent1}>
                <div className={styles.sent}>3500</div>
                <div className={styles.sent1}>Failed</div>
              </div>
              <div className={styles.parent2}>
                <div className={styles.sent}>2100</div>
                <div className={styles.sent1}>Opt-out</div>
              </div>
            </div>
          </div>
          <div className={styles.valuesWrapper}>
            <div className={styles.values1}>
              <div className={styles.div5}>0</div>
              <div className={styles.div6}>1000</div>
              <div className={styles.div7}>2000</div>
              <div className={styles.div8}>3000</div>
              <div className={styles.div9}>4000</div>
              <div className={styles.div10}>5000</div>
              <div className={styles.div11}>6000</div>
            </div>
          </div>
        </div>
        <div className={styles.bulkSmsChild} />
        <div className={styles.bulkSmsInner}>
          <div className={styles.frameParent2}>
            <div className={styles.leftIconParent}>
              <img className={styles.leftIcon} alt="" src="/assets/search-02.svg" />
              <div className={styles.text}>
                <div className={styles.textValue}>Search</div>
              </div>
            </div>
            <img className={styles.rightIcon} alt="" src="/righticon.svg" />
          </div>
        </div>
        <div className={styles.filter} >
          <img className={styles.filterIcon} alt="" src="/assets/filter.svg" />
          <div className={styles.adminOfficer}>Sort by</div>
          <img
            className={styles.chevronArrowDownIcon}
            alt=""
            src="/assets/chevron-arrow-down.svg"
          />
        </div>
        <div className={styles.navbar}>
          <div className={styles.buzzycash}>BuzzyCash</div>
          <div className={styles.navbarInner}>
            <div className={styles.frameWrapper5}>
              <div className={styles.component4Parent}>
              <a href="/dashboard">
  <div className={styles.component4}>
    <img
      className={styles.chevronArrowDownIcon}
      alt=""
      src="/assets/dashboard.svg"
    />
    <div className={styles.toPhonebook1Container}>Dashboard</div>
    <img
      className={styles.editIcon}
      alt=""
      src="/dropdowndown.svg"
    />
  </div>
</a>

<a href="/subscribers">
  <div className={styles.component4}>
    <img
      className={styles.chevronArrowDownIcon}
      alt=""
      src="/assets/user-02.svg"
    />
    <div className={styles.toPhonebook1Container}>
      Subscribers
    </div>
    <img
      className={styles.editIcon}
      alt=""
      src="/assets/dropdown-down.svg"
    />
  </div>
</a>

<a href="/subscriptions">
  <div className={styles.component4}>
    <img
      className={styles.chevronArrowDownIcon}
      alt=""
      src="/assets/subscription.svg"
    />
    <div className={styles.toPhonebook1Container}>
      Subscriptions
    </div>
    <img
      className={styles.editIcon}
      alt=""
      src="/dropdowndown2.svg"
    />
  </div>
</a>

                <div className={styles.gm}>
                <a href="/ManageGame">
  <div className={styles.component4}>
    <img
      className={styles.chevronArrowDownIcon}
      alt=""
      src="/assets/Component 5.svg"
    />
    <div className={styles.toPhonebook1Container}>
      Game Management
    </div>
    <img
      className={styles.filterIcon}
      alt=""
      src="/assets/dropdown-down.svg"
    />
  </div>
</a>

                </div>
                <div className={styles.component15}>
                <a href="/partners">
  <div className={styles.component4}>
    <img
      className={styles.chevronArrowDownIcon}
      alt=""
      src="/assets/partnership.svg"
    />
    <div className={styles.toPhonebook1Container}>
      Partners
    </div>
    <img
      className={styles.filterIcon}
      alt=""
      src="/assets/dropdown-down.svg"
    />
  </div>
</a>

                </div>
                <a href="/AdminSettings">
  <div className={styles.component4}>
    <img
      className={styles.chevronArrowDownIcon}
      alt=""
      src="/assets/admin.svg"
    />
    <div className={styles.toPhonebook1Container}>
      Admin Management
    </div>
    <img
      className={styles.editIcon}
      alt=""
      src="/dropdowndown4.svg"
    />
  </div>
</a>

                <div className={styles.component16}>
                  <img
                    className={styles.chevronArrowDownIcon}
                    alt=""
                    src="/assets/message.svg"
                  />
                  <div className={styles.subscribers3}>SMS</div>
                  <img
                    className={styles.editIcon}
                    alt=""
                    src="/dropdowndown5.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <a href="/AdminProfile">
          <div className={styles.component81}>
            <div className={styles.account}>ACCOUNT</div>
            <div className={styles.rectangleParent4}>
              <img
                className={styles.frameChild}
                alt=""
                src="/assets/Rectangle 342.png"
              />
              <div className={styles.adminOfficerParent}>
                <div className={styles.adminOfficer}>ADMIN OFFICER</div>
                <b className={styles.gilbertAdams}>Gilbert Adams</b>
              </div>
            </div>
            <img className={styles.setting02Icon} alt="" src="/assets/setting-02.svg" />
          </div>
          </a>
        </div>
      </div>

    </>
  );
};

export default BULKSMS;
