import { FunctionComponent } from "react";
import styles from "./Proceed.module.css";
import { Link } from 'react-router-dom';

const Proceed: FunctionComponent = () => {
  return (
    <div className={styles.partners}>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.arrowLeftLineParent}>
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/assets/arrow-left-line.svg"
            />
            <div className={styles.goBack}>Go back</div>
          </div>
          <div className={styles.partners1}>PARTNERS</div>
        </div>
        <img className={styles.frameChild} alt="" src="/assets/Rectangle 353.svg" />
        <div className={styles.frameContainer}>
          <div className={styles.disableenablePartnerParent}>
            <div className={styles.goBack}>Disable/Enable partner</div>
            <img className={styles.toggleIcon} alt="" src="/toggle.svg" />
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.inputFieldWrapper}>
              <div className={styles.inputField}>
                <img
                  className={styles.arrowLeftLineIcon}
                  alt=""
                  src="/component-6.svg"
                />
                <div className={styles.goBack}>Pay Partner</div>
                <img
                  className={styles.inputFieldChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.inputFieldContainer}>
              <div className={styles.inputField1}>
                <img
                  className={styles.arrowLeftLineIcon}
                  alt=""
                  src="/edit.svg"
                />
                <div className={styles.goBack}>Edit</div>
                <img
                  className={styles.inputFieldChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.frameParent1}>
            <div className={styles.companyNameParent}>
              <div className={styles.companyName}>Company name</div>
              <div className={styles.exxonMobile}>Exxon Mobile</div>
            </div>
            <div className={styles.companyNameParent}>
              <div className={styles.companyName}>ROI</div>
              <div className={styles.exxonMobile}>20% per game</div>
            </div>
            <div className={styles.companyNameParent}>
              <div className={styles.companyName}>Status</div>
              <div className={styles.exxonMobile}>Active Partner</div>
            </div>
            <div className={styles.companyNameParent}>
              <div className={styles.companyName}>Total Earnings</div>
              <div className={styles.exxonMobile}>₦1,00,000,00</div>
            </div>
          </div>
        </div>
        <div className={styles.inputFielsParent}>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue}>
              <div className={styles.becameAPartner}>Became a partner on</div>
            </div>
            <div className={styles.inputField2}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text}>
                  <div className={styles.sterlingBank}>
                    27th January 2023, 9:00PM
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputFiels1}>
            <div className={styles.headerValue1}>
              <div className={styles.becameAPartner}>Account Number</div>
            </div>
            <div className={styles.inputField2}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text1}>
                  <div className={styles.sterlingBank}>0000000001</div>
                </div>
              </div>
              <img className={styles.copyIcon} alt="" src="/copy.svg" />
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue2}>
              <div className={styles.becameAPartner}>Bank</div>
            </div>
            <div className={styles.inputField2}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text1}>
                  <div className={styles.sterlingBank}>Sterling Bank</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputFiels1}>
            <div className={styles.headerValue3}>
              <div className={styles.becameAPartner}>Cashed-Out Earnings</div>
            </div>
            <div className={styles.inputField2}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text3}>
                  <div className={styles.div2}>₦500,000,00</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue4}>
              <div className={styles.becameAPartner}>Contract</div>
            </div>
            <div className={styles.inputField6}>
              <div className={styles.uploadParent}>
                <img className={styles.uploadIcon} alt="" src="/upload.svg" />
                <div className={styles.pdf}>.pdf</div>
              </div>
            </div>
          </div>
          <div className={styles.inputFiels1}>
            <div className={styles.headerValue5}>
              <div className={styles.becameAPartner}>{`Amount Remaining `}</div>
            </div>
            <div className={styles.inputField2}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text3}>
                  <div className={styles.div2}>₦500,000,00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.partnersChild} />
      <div className={styles.partnersItem} />
      <div className={styles.pause}>
        <div className={styles.pauseChild} />
        <img className={styles.xCloseIcon} alt="" src="/xclose.svg" />
        <Link to="/PartnerDetails">
        <img className={styles.xCloseIcon1} alt="" src="/assets/x-close.svg" />
        </Link>
        <div className={styles.enterAdminCredentials}>
          Enter Admin Credentials
        </div>
        <div className={styles.inputFieldParent}>
          <div className={styles.inputField8}>
            <img className={styles.dropdownDownIcon} alt="" src="/edit.svg" />
            <div className={styles.goBack}>Continue</div>
          </div>
          <div className={styles.inputField9}>
            <img className={styles.dropdownDownIcon} alt="" src="/edit.svg" />
            <div className={styles.goBack}>Cancel</div>
          </div>
        </div>
        <div className={styles.inputFielsGroup}>
          <div className={styles.inputFiels6}>
            <div className={styles.headerValue6}>
              <div className={styles.becameAPartner}>Username</div>
            </div>
            <div>
            <input
          className={styles.inputField10}
          placeholder="Admin ID"
          type="email"
        />
            </div>
          </div>
          <div className={styles.inputFielsContainer}>
            <div className={styles.inputFiels7}>
              <div className={styles.headerValue7}>
                <div className={styles.becameAPartner}>Password</div>
              </div>
              <div>
              <input
          className={styles.inputField11}
          placeholder="Enter Admin Password"
          type="password"
        />
              </div>
            </div>
            <div className={styles.forgottenYourPasswordContainer}>
              <span
                className={styles.forgottenYourPassword}
              >{`Forgotten your password? `}</span>
              <b className={styles.resetNow}>
                <span>Reset now</span>
                <span className={styles.span}>{` `}</span>
              </b>
            </div>
          </div>
        </div>
        <div className={styles.toApplyChanges}>
          To apply changes made, you must enter your admin credentials.
        </div>
      </div>
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/dashboard.svg" />
            <div className={styles.goBack}>Dashboard</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/user-02.svg" />
            <div className={styles.goBack}>Subscribers</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.goBack}>Subscriptions</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
          <div className={styles.gm}>
            <div className={styles.component4}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.goBack}>Game Management</div>
              <img
                className={styles.arrowLeftLineIcon}
                alt=""
                src="/assets/dropdown-up.svg"
              />
            </div>
          </div>
          <div className={styles.component15}>
            <div className={styles.component14}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/partnership.svg"
              />
              <div className={styles.dashboard4}>Partners</div>
              <img
                className={styles.arrowLeftLineIcon}
                alt=""
                src="/assets/dropdown-up.svg"
              />
            </div>
            <div className={styles.subComponents}>
              <div className={styles.subNavComponents}>
                <img
                  className={styles.checklistIcon}
                  alt=""
                  src="/assets/checklist.svg"
                />
                <div className={styles.companyName}>View All Partners</div>
              </div>
              <div className={styles.subNavComponents1}>
                <div className={styles.addUserParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/add-user.svg"
                  />
                  <div className={styles.goBack}>Add Partner</div>
                </div>
              </div>
              <div className={styles.subNavComponents1}>
                <div className={styles.addUserParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/history.svg"
                  />
                  <div className={styles.goBack}>Payout History</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
            <div className={styles.goBack}>Admin Management</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/assets/dropdown-down.svg"
            />
          </div>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/message.svg" />
            <div className={styles.goBack}>SMS</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
        </div>
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameItem}
              alt=""
              src="/rectangle-342@2x.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.adminOfficer}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img className={styles.setting02Icon} alt="" src="/setting02.svg" />
        </div>
      </div>
    </div>
  );
};

export default Proceed;
