import { FunctionComponent, useCallback, useState } from "react";
import styles from "./Partners.module.css";
import { Link } from 'react-router-dom';

const Partners: FunctionComponent = () => {
  const onFrameContainer36Click = useCallback(() => {
    // Please sync "partners" to the project
  }, []);

  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <div className={styles.partners}>
      <div className={styles.partnersParent}>
        <div className={styles.partners1}>PARTNERS</div>
        <div className={styles.theBuzzycashPartners}>
          The BuzzyCash partners are listed below.
        </div>
        <div className={styles.frameChild} />
        <div className={styles.frameWrapper}>
          <div className={styles.frameParent}>
          <input
            className={styles.frameParent} // Apply appropriate styles here
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={handleInputChange}
          />
            <img className={styles.rightIcon} alt="" src="/righticon.svg" />
          </div>
        </div>
      </div>
      <div className={styles.frameGroup} onClick={onFrameContainer36Click}>
        <div className={styles.partnerCardParent}>
          <div className={styles.partnerCard}>
            <div className={styles.rectangleParent}>
              <img
                className={styles.frameItem}
                alt=""
                src="/assets/Rectangle 352.png"
              />
              <div className={styles.smallButton}>
                <Link to="/PartnerDetails">
                <div className={styles.subscribers}>More information</div>
                </Link>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
              <div className={styles.frameContainer}>
                <div className={styles.exxonmobilParent}>
                  <div className={styles.exxonmobil}>ExxonMobil</div>
                  <div className={styles.mapPinLineParent}>
                    <img
                      className={styles.mapPinLineIcon}
                      alt=""
                      src="/assets/map-pin-line.svg"
                    />
                    <div className={styles.exxonmobil}>Lagos</div>
                  </div>
                </div>
                <div className={styles.roi20Per}>ROI: 20% per game</div>
              </div>
            </div>
            <div className={styles.groupParent}>
              <div className={styles.rectangleGroup}>
                <div className={styles.groupChild} />
                <div className={styles.activePartner}>Active Partner</div>
              </div>
              <img className={styles.frameInner} alt="" src="/assets/Vector 1.svg" />
            </div>
          </div>
          <div className={styles.partnerCard}>
            <div className={styles.rectangleParent}>
              <img
                className={styles.frameItem}
                alt=""
                src="/assets/Rectangle 352.png"
              />
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More information</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-11.svg"
                />
              </div>
              <div className={styles.frameContainer}>
                <div className={styles.exxonmobilParent}>
                  <div className={styles.exxonmobil}>ExxonMobil</div>
                  <div className={styles.mapPinLineParent}>
                    <img
                      className={styles.mapPinLineIcon}
                      alt=""
                      src="/assets/map-pin-line.svg"
                    />
                    <div className={styles.exxonmobil}>Lagos</div>
                  </div>
                </div>
                <div className={styles.roi20Per}>ROI: 20% per game</div>
              </div>
            </div>
            <div className={styles.groupParent}>
              <div className={styles.rectangleGroup}>
                <div className={styles.groupChild} />
                <div className={styles.activePartner}>Active Partner</div>
              </div>
              <img className={styles.frameInner} alt="" src="/assets/Vector 1.svg" />
            </div>
          </div>
          <div className={styles.partnerCard}>
            <div className={styles.rectangleParent}>
              <img
                className={styles.frameItem}
                alt=""
                src="/assets/Rectangle 352.png"
              />
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More information</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-12.svg"
                />
              </div>
              <div className={styles.frameContainer}>
                <div className={styles.exxonmobilParent}>
                  <div className={styles.exxonmobil}>ExxonMobil</div>
                  <div className={styles.mapPinLineParent}>
                    <img
                      className={styles.mapPinLineIcon}
                      alt=""
                      src="/assets/map-pin-line.svg"
                    />
                    <div className={styles.exxonmobil}>Lagos</div>
                  </div>
                </div>
                <div className={styles.roi20Per}>ROI: 20% per game</div>
              </div>
            </div>
            <div className={styles.groupParent}>
              <div className={styles.rectangleGroup}>
                <div className={styles.groupChild} />
                <div className={styles.activePartner}>Active Partner</div>
              </div>
              <img className={styles.frameInner} alt="" src="/assets/Vector 1.svg" />
            </div>
          </div>
        </div>
        <div className={styles.partnerCardParent}>
          <div className={styles.partnerCard}>
            <div className={styles.rectangleParent}>
              <img
                className={styles.frameItem}
                alt=""
                src="/assets/Rectangle 352.png"
              />
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More information</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-13.svg"
                />
              </div>
              <div className={styles.frameContainer}>
                <div className={styles.exxonmobilParent}>
                  <div className={styles.exxonmobil}>ExxonMobil</div>
                  <div className={styles.mapPinLineParent}>
                    <img
                      className={styles.mapPinLineIcon}
                      alt=""
                      src="/assets/map-pin-line.svg"
                    />
                    <div className={styles.exxonmobil}>Lagos</div>
                  </div>
                </div>
                <div className={styles.roi20Per}>ROI: 20% per game</div>
              </div>
            </div>
            <div className={styles.groupParent}>
              <div className={styles.rectangleGroup}>
                <div className={styles.groupChild} />
                <div className={styles.activePartner}>Active Partner</div>
              </div>
              <img className={styles.frameInner} alt="" src="/assets/Vector 1.svg" />
            </div>
          </div>
          <div className={styles.partnerCard}>
            <div className={styles.rectangleParent}>
              <img
                className={styles.frameItem}
                alt=""
                src="/assets/Rectangle 352.png"
              />
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More information</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-14.svg"
                />
              </div>
              <div className={styles.frameContainer}>
                <div className={styles.exxonmobilParent}>
                  <div className={styles.exxonmobil}>ExxonMobil</div>
                  <div className={styles.mapPinLineParent}>
                    <img
                      className={styles.mapPinLineIcon}
                      alt=""
                      src="/assets/map-pin-line.svg"
                    />
                    <div className={styles.exxonmobil}>Lagos</div>
                  </div>
                </div>
                <div className={styles.roi20Per}>ROI: 20% per game</div>
              </div>
            </div>
            <div className={styles.groupParent}>
              <div className={styles.rectangleGroup}>
                <div className={styles.groupChild} />
                <div className={styles.activePartner}>Active Partner</div>
              </div>
              <img className={styles.frameInner} alt="" src="/assets/Vector 1.svg" />
            </div>
          </div>
          <div className={styles.partnerCard}>
            <div className={styles.rectangleParent}>
              <img
                className={styles.frameItem}
                alt=""
                src="/assets/Rectangle 352.png"
              />
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More information</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-15.svg"
                />
              </div>
              <div className={styles.frameContainer}>
                <div className={styles.exxonmobilParent}>
                  <div className={styles.exxonmobil}>ExxonMobil</div>
                  <div className={styles.mapPinLineParent}>
                    <img
                      className={styles.mapPinLineIcon}
                      alt=""
                      src="/assets/map-pin-line.svg"
                    />
                    <div className={styles.exxonmobil}>Lagos</div>
                  </div>
                </div>
                <div className={styles.roi20Per}>ROI: 20% per game</div>
              </div>
            </div>
            <div className={styles.groupParent}>
              <div className={styles.rectangleGroup}>
                <div className={styles.groupChild} />
                <div className={styles.activePartner}>Active Partner</div>
              </div>
              <img className={styles.frameInner} alt="" src="/assets/Vector 1.svg" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.partnersChild} />
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
        <a href="/dashboard">
  <div className={styles.component4}>
    <img className={styles.dashboardIcon} alt="" src="/assets/dashboard.svg" />
    <div className={styles.exxonmobil}>Dashboard</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/dropdowndown.svg"
    />
  </div>
</a>

<a href="/subscribers">
  <div className={styles.component4}>
    <img className={styles.dashboardIcon} alt="" src="/assets/user-02.svg" />
    <div className={styles.exxonmobil}>Subscribers</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/dropdowndown1.svg"
    />
  </div>
</a>

<a href="/subscriptions">
  <div className={styles.component4}>
    <img
      className={styles.dashboardIcon}
      alt=""
      src="/assets/subscription.svg"
    />
    <div className={styles.exxonmobil}>Subscriptions</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/dropdowndown2.svg"
    />
  </div>
</a>

          <div className={styles.gm}>
          <a href="/ManageGame">
  <div className={styles.component4}>
    <img
      className={styles.dashboardIcon}
      alt=""
      src="/assets/Component 5.svg"
    />
    <div className={styles.exxonmobil}>Game Management</div>
    <img
      className={styles.dropdownUpIcon}
      alt=""
      src="/assets/dropdown-down.svg"
    />
  </div>
</a>

          </div>
          <div className={styles.component15}>
            <div className={styles.component14}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/partnership.svg"
              />
              <div className={styles.dashboard4}>Partners</div>
              <img
                className={styles.dropdownUpIcon}
                alt=""
                src="/assets/dropdown-down.svg"
              />
            </div>
            <div className={styles.subComponents}>
              <div className={styles.subNavComponents}>
                <img className={styles.leftIcon} alt="" src="/assets/checklist.svg" />
                <div className={styles.dashboard5}>View All Partners</div>
              </div>
              <div className={styles.subNavComponents1}>
              <a href="/AddPartners">
  <div className={styles.addUserParent}>
    <img
      className={styles.dashboardIcon}
      alt=""
      src="/assets/add-user.svg"
    />
    <div className={styles.exxonmobil}>Add Partner</div>
  </div>
</a>

              </div>
              <div className={styles.subNavComponents1}>
              <a href="/PartnerHistory">
  <div className={styles.addUserParent}>
    <img
      className={styles.dashboardIcon}
      alt=""
      src="/assets/history.svg"
    />
    <div className={styles.exxonmobil}>Payout History</div>
  </div>
</a>

              </div>
            </div>
          </div>
          <a href="/AdminSettings">
  <div className={styles.component4}>
    <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
    <div className={styles.exxonmobil}>Admin Management</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/dropdowndown3.svg"
    />
  </div>
</a>

<a href="/sms">
  <div className={styles.component4}>
    <img className={styles.dashboardIcon} alt="" src="/assets/message.svg" />
    <div className={styles.exxonmobil}>SMS</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/assets/dropdown-down.svg"
    />
  </div>
</a>

        </div>
        <a href="/AdminProfile">
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent9}>
            <img
              className={styles.frameChild9}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.subscribers}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img className={styles.setting02Icon} alt="" src="/assets/setting-02.svg" />
        </div>
        </a>
      </div>
    </div>
  );
};

export default Partners;
