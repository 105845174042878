import { FunctionComponent, useCallback } from "react";
import styles from "./Confirmation.module.css";
import { Link } from 'react-router-dom';

const Confirmation: FunctionComponent = () => {
  const onFrameContainerClick = useCallback(() => {
    // Please sync "draw" to the project
  }, []);

  const onFrameContainer2Click = useCallback(() => {
    // Please sync "partners" to the project
  }, []);

  const onInputFieldContainer12Click = useCallback(() => {
    // Please sync "partners" to the project
  }, []);

  const onPauseContainerClick = useCallback(() => {
    // Please sync "partners" to the project
  }, []);

  return (
    <div className={styles.partners}>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div
            className={styles.arrowLeftLineParent}
            onClick={onFrameContainerClick}
          >
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/arrowleftline.svg"
            />
            <div className={styles.goBack}>Go back</div>
          </div>
          <div className={styles.partners1}>PARTNERS</div>
        </div>
        <img className={styles.frameChild} alt="" src="/rectangle-353@2x.png" />
        <div className={styles.frameContainer}>
          <div
            className={styles.disableenablePartnerParent}
            onClick={onFrameContainer2Click}
          >
            <div className={styles.goBack}>Disable/Enable partner</div>
            <img className={styles.toggleIcon} alt="" src="/toggle.svg" />
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.inputFieldWrapper}>
              <div className={styles.inputField}>
                <img
                  className={styles.arrowLeftLineIcon}
                  alt=""
                  src="/component-6.svg"
                />
                <div className={styles.goBack}>Pay Partner</div>
                <img
                  className={styles.inputFieldChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.inputFieldContainer}>
              <div className={styles.inputField1}>
                <img
                  className={styles.arrowLeftLineIcon}
                  alt=""
                  src="/edit.svg"
                />
                <div className={styles.goBack}>Edit</div>
                <img
                  className={styles.inputFieldChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.frameParent1}>
            <div className={styles.companyNameParent}>
              <div className={styles.companyName}>Company name</div>
              <div className={styles.exxonMobile}>Exxon Mobile</div>
            </div>
            <div className={styles.companyNameParent}>
              <div className={styles.companyName}>ROI</div>
              <div className={styles.exxonMobile}>20% per game</div>
            </div>
            <div className={styles.companyNameParent}>
              <div className={styles.companyName}>Status</div>
              <div className={styles.exxonMobile}>Active Partner</div>
            </div>
            <div className={styles.companyNameParent}>
              <div className={styles.companyName}>Total Earnings</div>
              <div className={styles.exxonMobile}>₦1,00,000,00</div>
            </div>
          </div>
        </div>
        <div className={styles.inputFielsParent}>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue}>
              <div className={styles.becameAPartner}>Became a partner on</div>
            </div>
            <div className={styles.inputField2}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text}>
                  <div className={styles.sterlingBank}>
                    27th January 2023, 9:00PM
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputFiels1}>
            <div className={styles.headerValue1}>
              <div className={styles.becameAPartner}>Account Number</div>
            </div>
            <div className={styles.inputField2}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text1}>
                  <div className={styles.sterlingBank}>0000000001</div>
                </div>
              </div>
              <img className={styles.copyIcon} alt="" src="/copy.svg" />
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue2}>
              <div className={styles.becameAPartner}>Bank</div>
            </div>
            <div className={styles.inputField2}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text1}>
                  <div className={styles.sterlingBank}>Sterling Bank</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputFiels1}>
            <div className={styles.headerValue3}>
              <div className={styles.becameAPartner}>Cashed-Out Earnings</div>
            </div>
            <div className={styles.inputField2}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text3}>
                  <div className={styles.div2}>₦500,000,00</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue4}>
              <div className={styles.becameAPartner}>Contract</div>
            </div>
            <div className={styles.inputField6}>
              <div className={styles.uploadParent}>
                <img className={styles.uploadIcon} alt="" src="/upload.svg" />
                <div className={styles.pdf}>.pdf</div>
              </div>
            </div>
          </div>
          <div className={styles.inputFiels1}>
            <div className={styles.headerValue5}>
              <div className={styles.becameAPartner}>{`Amount Remaining `}</div>
            </div>
            <div className={styles.inputField2}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text3}>
                  <div className={styles.div2}>₦500,000,00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.partnersChild} />
      <div className={styles.partnersItem} />
      <div className={styles.overlay}>
        <div className={styles.inputFielsGroup}>
          <div className={styles.inputFiels6}>
            <div className={styles.headerValue6}>
              <div className={styles.becameAPartner}>Company Name</div>
            </div>
            <div className={styles.inputField8}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text5}>
                  <div className={styles.sterlingBank}>ExxonMobil</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputFiels6}>
            <div className={styles.headerValue7}>
              <div className={styles.becameAPartner}>Company’s Address</div>
            </div>
            <div className={styles.inputField8}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text6}>
                  <div className={styles.sterlingBank}>Rada rada rada</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputFiels6}>
            <div className={styles.headerValue8}>
              <div className={styles.becameAPartner}>% of earnings</div>
            </div>
            <div className={styles.inputField8}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text7}>
                  <div className={styles.sterlingBank}>40</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameWrapper1}>
            <div className={styles.frameParent2}>
              <div className={styles.partnershipContractoptionalWrapper}>
                <div className={styles.partnershipContractoptional}>
                  Partnership Contract(Optional)
                </div>
              </div>
              <div className={styles.frameWrapper2}>
                <div className={styles.uploadFileParent}>
                  <div className={styles.goBack}>Upload file</div>
                  <div className={styles.documentUpload}>
                    <div className={styles.uploadGroup}>
                      <img
                        className={styles.uploadIcon1}
                        alt=""
                        src="/upload.svg"
                      />
                      <div className={styles.pdf1}>.pdf</div>
                    </div>
                    <div className={styles.documentUploadInner}>
                      <div className={styles.tapToUploadDocumentParent}>
                        <div className={styles.tapToUpload}>
                          Tap to Upload Document
                        </div>
                        <div className={styles.replaceParent}>
                          <div className={styles.replace}>Replace</div>
                          <div className={styles.remove}>Remove</div>
                        </div>
                        <div className={styles.jpgPdfGifPngParent}>
                          <div className={styles.remove}>
                            JPG, PDF, GIF, PNG
                          </div>
                          <div className={styles.remove}>Max Size: 10MB</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameWrapper1}>
            <div className={styles.frameParent2}>
              <div className={styles.partnershipContractoptionalWrapper}>
                <div className={styles.partnershipContractoptional}>
                  Logo (optional)
                </div>
              </div>
              <div className={styles.frameWrapper2}>
                <div className={styles.uploadFileParent}>
                  <div className={styles.goBack}>Upload file</div>
                  <div className={styles.documentUpload}>
                    <div className={styles.uploadGroup}>
                      <img
                        className={styles.uploadIcon1}
                        alt=""
                        src="/assets/upload.svg"
                      />
                      <div className={styles.pdf1}>.pdf</div>
                    </div>
                    <div className={styles.documentUploadInner}>
                      <div className={styles.tapToUploadDocumentParent}>
                        <div className={styles.tapToUpload}>
                          Tap to Upload Document
                        </div>
                        <div className={styles.replaceParent}>
                          <div className={styles.replace}>Replace</div>
                          <div className={styles.remove}>Remove</div>
                        </div>
                        <div className={styles.jpgPdfGifPngParent}>
                          <div className={styles.remove}>
                            JPG, PDF, GIF, PNG
                          </div>
                          <div className={styles.remove}>Max Size: 10MB</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.inputFieldParent}>
          <div className={styles.inputField11}>
            <img className={styles.dropdownDownIcon} alt="" src="/assets/edit.svg" />
            <div className={styles.goBack}>Apply Changes</div>
          </div>
          <div
            className={styles.inputField12}
            onClick={onInputFieldContainer12Click}
          >
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/assets/trash-2.svg"
            />
            <div className={styles.goBack}>Delete</div>
          </div>
        </div>
        <div className={styles.editPartnerInformation}>
          Edit Partner Information
        </div>
      </div>
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/dashboard.svg" />
            <div className={styles.goBack}>Dashboard</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/user-02.svg" />
            <div className={styles.goBack}>Subscribers</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.goBack}>Subscriptions</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
          <div className={styles.gm}>
            <div className={styles.component4}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.goBack}>Game Management</div>
              <img
                className={styles.arrowLeftLineIcon}
                alt=""
                src="/assets/dropdown-up.svg"
              />
            </div>
          </div>
          <div className={styles.component15}>
            <div className={styles.component14}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/partnership.svg"
              />
              <div className={styles.dashboard4}>Partners</div>
              <img
                className={styles.arrowLeftLineIcon}
                alt=""
                src="/assets/dropdown-up.svg"
              />
            </div>
            <div className={styles.subComponents}>
              <div className={styles.subNavComponents}>
                <img
                  className={styles.checklistIcon}
                  alt=""
                  src="/assets/checklist.svg"
                />
                <div className={styles.companyName}>View All Partners</div>
              </div>
              <div className={styles.subNavComponents1}>
                <div className={styles.addUserParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/add-user.svg"
                  />
                  <div className={styles.goBack}>Add Partner</div>
                </div>
              </div>
              <div className={styles.subNavComponents1}>
                <div className={styles.addUserParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/history.svg"
                  />
                  <div className={styles.goBack}>Payout History</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
            <div className={styles.goBack}>Admin Management</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/message.svg" />
            <div className={styles.goBack}>SMS</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
        </div>
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameItem}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.tapToUploadDocumentParent}>
              <div className={styles.remove}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img className={styles.setting02Icon} alt="" src="/assets/setting-02.svg" />
        </div>
      </div>
      <img className={styles.partnersInner} alt="" src="/rectangle-359.svg" />
      <div className={styles.pause} onClick={onPauseContainerClick}>
        <div className={styles.pauseChild} />
        <img className={styles.xCloseIcon} alt="" src="/xclose.svg" />
        <img className={styles.xCloseIcon1} alt="" src="/xclose.svg" />
        <div className={styles.youAreAbutContainer}>
          <span className={styles.youAreAbutContainer1}>
            <p className={styles.youAreAbut}>
              You are abut to make changes to this partner which could affect
              the earnings of this partner. Changes can always be made.
            </p>
            <p className={styles.continueAction}>Continue action?</p>
          </span>
        </div>
        <div className={styles.phwarningDuotoneParent}>
          <img
            className={styles.phwarningDuotoneIcon}
            alt=""
            src="/assets/bang.svg"
          />
          <div className={styles.youAreAbout}>
            You are about to make changes to this partner
          </div>
        </div>
        <div className={styles.inputFieldGroup}>
          <Link to="/PartnerDetails">
          <div className={styles.inputField11}>
            <img className={styles.dropdownDownIcon} alt="" src="/edit.svg" />
            <div className={styles.goBack}>Cancel</div>
          </div>
          </Link>
          <Link to="/Proceed">
          <div className={styles.inputField14}>
            <img className={styles.dropdownDownIcon} alt="" src="/edit.svg" />
            <div className={styles.goBack}>Proceed</div>
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
