import { FunctionComponent, useState, useRef, useEffect } from "react";
import styles from "./subscription.module.css";
//import './globalx.css'

const Subscriptions: FunctionComponent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef<HTMLDivElement>(null);
  const slideWidth = 40; // Adjust this value to show three slides in the viewport
  const totalSlides = 5; // Define the total number of slides
  
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      return nextIndex > totalSlides - 3 ? 0 : nextIndex;
    });
  };
  
  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex - 1;
      return nextIndex < 0 ? totalSlides - 3 : nextIndex;
    });
  };
  
  useEffect(() => {
    if (sliderRef.current) {
      const translateValue = -currentIndex * slideWidth;
      sliderRef.current.style.transform = `translateX(${translateValue}%)`;
    }
  }, [currentIndex, slideWidth]);

  return (
    <div className={styles.dashboard}>
      <div className={styles.dashboardChild} />
      <div className={styles.dashboardItem} />
      <div className={styles.frameParent}>
        <div className={styles.subscriptionsParent}>
          <div className={styles.subscriptions}>SUBSCRIPTIONS</div>
          <div className={styles.component11}>
            
            <div className="slider">
            <div className="slider-container"
      style={{ overflow: 'hidden', width: '1050px' }} 
    > 
            <div className="slides" ref={sliderRef} style={{ display: 'flex', transition: 'transform 0.5s ease' }}>
            {[...Array(totalSlides)].map((_, index) => (
              <div key={index} style={{ width: `${slideWidth}%`, flexShrink: 0 }}>
     
                {/* Your specific content for each slide */}
            {index === 0 && (
              <> 
                <div className={styles.dashboardItemsWrapper}>
                  <div className={styles.dashboardItems}>
                    <div className={styles.frameGroup}>
                      <div className={styles.oneMillionBagParent}>
                        <div className={styles.oneMillionBag}>
                          One million bag
                        </div>
                        <div className={styles.activeGames}>
                          300,000 Subscriptions
                        </div>
                      </div>
                      <img
                        className={styles.component5Icon}
                        alt=""
                        src="/assets/Component 5.svg"
                      />
                    </div>
                    <div className={styles.dashboardItemsChild} />
                    <div className={styles.past7DaysParent}>
                      <div className={styles.past7Days}>Past 7 days</div>
                      <div className={styles.frameContainer}>
                        <div className={styles.dashboardItemsWrapper}>
                          <div className={styles.oneMillionBag}>
                            +500 Renewals
                          </div>
                        </div>
                        <div className={styles.arrowUpLineParent}>
                          <img
                            className={styles.arrowUpLineIcon}
                            alt=""
                            src="/assets/arrow-up-line.svg"
                          />
                          <div className={styles.oneMillionBag}>
                            +500 New games
                          </div>
                        </div>
                        <div className={styles.arrowDownLineParent}>
                          <img
                            className={styles.arrowUpLineIcon}
                            alt=""
                            src="/assets/arrow-down-line.svg"
                          />
                          <div className={styles.oneMillionBag}>
                            20 Unsubscribed
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </>
                )}
                {index === 1 && (
              <> 
                <div className={styles.dashboardItemsWrapper}>
                  <div className={styles.dashboardItems}>
                    <div className={styles.frameGroup}>
                      <div className={styles.oneMillionBagParent}>
                        <div className={styles.oneMillionBag}>
                          ChopChop Instant Win
                        </div>
                        <div className={styles.activeGames}>
                          200,000 Subscriptions
                        </div>
                      </div>
                      <img
                        className={styles.component5Icon}
                        alt=""
                        src="/assets/Component 5.svg"
                      />
                    </div>
                    <div className={styles.dashboardItemsChild} />
                    <div className={styles.past7DaysParent}>
                      <div className={styles.past7Days}>Past 7 days</div>
                      <div className={styles.frameContainer}>
                        <div className={styles.dashboardItemsWrapper}>
                          <div className={styles.oneMillionBag}>
                            +500 Renewals
                          </div>
                        </div>
                        <div className={styles.arrowUpLineParent}>
                          <img
                            className={styles.arrowUpLineIcon}
                            alt=""
                            src="/assets/arrow-up-line.svg"
                          />
                          <div className={styles.oneMillionBag}>
                            +500 New games
                          </div>
                        </div>
                        <div className={styles.arrowDownLineParent}>
                          <img
                            className={styles.arrowUpLineIcon}
                            alt=""
                            src="/assets/arrow-down-line.svg"
                          />
                          <div className={styles.oneMillionBag}>
                            20 Unsubscribed
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </>
                )}
                {index === 2 && (
              <>
                <div className={styles.dashboardItemsWrapper}>
                  <div className={styles.dashboardItems}>
                    <div className={styles.frameGroup}>
                      <div className={styles.oneMillionBagParent}>
                        <div className={styles.oneMillionBag}>Mega Money</div>
                        <div className={styles.activeGames}>
                          300,000 Subscriptions
                        </div>
                      </div>
                      <img
                        className={styles.component5Icon}
                        alt=""
                        src="/assets/Component 5.svg"
                      />
                    </div>
                    <div className={styles.dashboardItemsChild} />
                    <div className={styles.past7DaysParent}>
                      <div className={styles.past7Days}>Past 7 days</div>
                      <div className={styles.frameContainer}>
                        <div className={styles.dashboardItemsWrapper}>
                          <div className={styles.oneMillionBag}>
                            +500 Renewals
                          </div>
                        </div>
                        <div className={styles.arrowUpLineParent}>
                          <img
                            className={styles.arrowUpLineIcon}
                            alt=""
                            src="/assets/arrow-up-line.svg"
                          />
                          <div className={styles.oneMillionBag}>
                            +500 New games
                          </div>
                        </div>
                        <div className={styles.arrowDownLineParent}>
                          <img
                            className={styles.arrowUpLineIcon}
                            alt=""
                            src="/assets/arrow-down-line.svg"
                          />
                          <div className={styles.oneMillionBag}>
                            20 Unsubscribed
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </>
                )}
                {index === 3 && (
              <>
                <div className={styles.dashboardItemsWrapper}>
                  <div className={styles.dashboardItems}>
                    <div className={styles.frameParent4}>
                      <div className={styles.oneMillionBagParent}>
                        <div className={styles.oneMillionBag}>Instant 3/4</div>
                        <div className={styles.activeGames}>
                          300,000 active games
                        </div>
                      </div>
                      <img
                        className={styles.component5Icon}
                        alt=""
                        src="/assets/component 5.svg"
                      />
                    </div>
                    <div className={styles.dashboardItemsChild} />
                    <div className={styles.past7DaysParent}>
                      <div className={styles.past7Days}>Past 7 days</div>
                      <div className={styles.frameContainer}>
                        <div className={styles.dashboardItemsWrapper}>
                          <div className={styles.oneMillionBag}>
                            +500 Renewals
                          </div>
                        </div>
                        <div className={styles.arrowUpLineParent}>
                          <img
                            className={styles.arrowUpLineIcon}
                            alt=""
                            src="/assets/arrow-up-line.svg"
                          />
                          <div className={styles.oneMillionBag}>
                            +500 New games
                          </div>
                        </div>
                        <div className={styles.arrowDownLineParent}>
                          <img
                            className={styles.arrowUpLineIcon}
                            alt=""
                            src="/assets/arrow-down-line.svg"
                          />
                          <div className={styles.oneMillionBag}>
                            20 Unsubscribed
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </>
                )}
                {index === 4 && (
              <>
                <div className={styles.dashboardItemsWrapper}>
                  <div className={styles.dashboardItems}>
                    <div className={styles.frameParent4}>
                      <div className={styles.oneMillionBagParent}>
                        <div className={styles.oneMillionBag}>Fast Cash</div>
                        <div className={styles.activeGames}>
                          300,000 active games
                        </div>
                      </div>
                      <img
                        className={styles.component5Icon}
                        alt=""
                        src="/assets/component 5.svg"
                      />
                    </div>
                    <div className={styles.dashboardItemsChild} />
                    <div className={styles.past7DaysParent}>
                      <div className={styles.past7Days}>Past 7 days</div>
                      <div className={styles.frameContainer}>
                        <div className={styles.dashboardItemsWrapper}>
                          <div className={styles.oneMillionBag}>
                            +500 Renewals
                          </div>
                        </div>
                        <div className={styles.arrowUpLineParent}>
                          <img
                            className={styles.arrowUpLineIcon}
                            alt=""
                            src="/assets/arrow-up-line.svg"
                          />
                          <div className={styles.oneMillionBag}>
                            +500 New games
                          </div>
                        </div>
                        <div className={styles.arrowDownLineParent}>
                          <img
                            className={styles.arrowUpLineIcon}
                            alt=""
                            src="/assets/arrow-down-line.svg"
                          />
                          <div className={styles.oneMillionBag}>
                            20 Unsubscribed
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </>
                )}
        
            </div>
            ))}
            </div>
            </div>
            </div>
            <img
              className={styles.component9Icon}
              alt=""
              src="/assets/chevron-arrow-right.svg"
              onClick={nextSlide}
            />
            <img
              className={styles.component10Icon}
              alt=""
              src="/assets/chevron-arrow-left.svg"
              onClick={prevSlide}
            />
          </div>
        </div>
        <div className={styles.table}>
          <div className={styles.column}>
            <div className={styles.cellHeader}>
              <div className={styles.gameType}>Game type</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag1}>One million bag</div>
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag1}>
                  ChopChop instant win
                </div>
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag1}>Mega money</div>
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag1}>Instant 3/4</div>
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag1}>Fast cash</div>
              </div>
            </div>
          </div>
          <div className={styles.column1}>
            <div className={styles.cellHeader1}>
              <div className={styles.gameType}>Total subscriptions</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.div}>300,000</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>20,000</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>30,000</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>200</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>1000</div>
            </div>
          </div>
          <div className={styles.column2}>
            <div className={styles.cellHeader1}>
              <div className={styles.gameType}>Renewals</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>30</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>100</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>100</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>100</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>100</div>
            </div>
          </div>
          <div className={styles.column3}>
            <div className={styles.cellHeader1}>
              <div className={styles.gameType}>One Shot</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>30</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>100</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>100</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>100</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>100</div>
            </div>
          </div>
          <div className={styles.column4}>
            <div className={styles.cellHeader1}>
              <div className={styles.gameType}>Total games played</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>27</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>100</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>50</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>1</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.gameType}>1777</div>
            </div>
          </div>
          <div className={styles.column5}>
            <div className={styles.cellHeader1}>
              <div className={styles.gameType}>Total amount in revenue</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.div20}>₦300,000</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.div20}>₦500,000</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.div20}>₦500,000</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.div20}>₦50,000</div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.div20}>₦52,000</div>
            </div>
          </div>
          <div className={styles.column6}>
            <div className={styles.cellHeader6}>
              <div className={styles.advancedInfo}>Advanced Info</div>
            </div>
            <div className={styles.cellItem6}>
            <a href="/onemillionbag">
  <div className={styles.smallButton}>
    <div className={styles.subscribers}>More info</div>
    <img
      className={styles.smallButtonChild}
      alt=""
      src="/group-1.svg"
    />
  </div>
</a>

            </div>
            <div className={styles.cellItem6}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-11.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-12.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-13.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem6}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-14.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.navbar}>
          <div className={styles.buzzycash}>BuzzyCash</div>
          <div className={styles.component4Parent}>
          <a href="/dashboard" className={styles.component4}>
  <img
    className={styles.arrowUpLineIcon}
    alt=""
    src="/assets/dashboard.svg"
  />
  <div className={styles.advancedInfo}>Dashboard</div>
  <img
    className={styles.dropdownDownIcon}
    alt=""
    src="/dropdowndown.svg"
  />
</a>

<a href="/subscribers" className={styles.component4}>
  <img
    className={styles.arrowUpLineIcon}
    alt=""
    src="/assets/user-02.svg"
  />
  <div className={styles.advancedInfo}>Subscribers</div>
  <img
    className={styles.dropdownDownIcon}
    alt=""
    src="/dropdowndown1.svg"
  />
</a>

<div className={styles.component8} >
      <img
        className={styles.arrowUpLineIcon}
        alt=""
        src="/assets/subscription.svg"
      />
      <div className={styles.past7Days}>Subscriptions</div>
      <img
        className={styles.dropdownDownIcon}
        alt=""
        src="/dropdowndown2.svg"
      />
    </div>

            <div className={styles.gm}>
            <a href="/ManageGame" className={styles.component4}>
  <img
    className={styles.arrowUpLineIcon}
    alt=""
    src="/assets/Component 5.svg"
  />
  <div className={styles.advancedInfo}>Game Management</div>
  <img
    className={styles.dropdownDownIcon3}
    alt=""
    src="/assets/dropdown-down.svg"
  />
</a>

            </div>
            <a href="/partners" className={styles.component4}>
  <img
    className={styles.arrowUpLineIcon}
    alt=""
    src="/assets/partnership.svg"
  />
  <div className={styles.advancedInfo}>Partners</div>
  <img
    className={styles.dropdownDownIcon3}
    alt=""
    src="/assets/dropdown-down.svg"
  />
</a>

<a href="/AdminSettings" className={styles.component4}>
  <img className={styles.arrowUpLineIcon} alt="" src="/assets/admin.svg" />
  <div className={styles.advancedInfo}>Admin Management</div>
  <img className={styles.dropdownDownIcon} alt="" src="/dropdowndown5.svg" />
</a>

<a href="/sms">
  <div className={styles.component4}>
    <img
      className={styles.arrowUpLineIcon}
      alt=""
      src="/assets/message.svg"
    />
    <div className={styles.advancedInfo}>SMS</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/dropdowndown6.svg"
    />
  </div>
</a>

          </div>
          <a href="/AdminProfile">
  <div className={styles.component81}>
    <div className={styles.account}>ACCOUNT</div>
    <div className={styles.rectangleParent}>
      <img
        className={styles.frameChild}
        alt=""
        src="/assets/Rectangle 342.png"
      />
      <div className={styles.adminOfficerParent}>
        <div className={styles.subscribers}>ADMIN OFFICER</div>
        <b className={styles.gilbertAdams}>Gilbert Adams</b>
      </div>
    </div>
    <img className={styles.setting02Icon} alt="" src="/assets/setting-02.svg" />
  </div>
</a>

        </div>
      </div>
    </div>
  );
};

export default Subscriptions;