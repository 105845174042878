import { FunctionComponent } from "react";
import styles from "./Details.module.css";
import {Link} from 'react-router-dom';

const Details: FunctionComponent = () => {
    const blurTextStyle = {
        color: 'transparent',
        textShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
      };
  return (
    <div className={styles.partners}>
      <div className={styles.partnersParent}>
        <div className={styles.partners1}>PARTNERS</div>
        <div className={styles.addBuzzycashPartners}>
          Add BuzzyCash Partners
        </div>
        <div className={styles.frameChild} />
        <div className={styles.inputFielsParent}>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue}>
              <div className={styles.companyName}>Company Name</div>
            </div>
            <div className={styles.inputField}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text}>
                  <div className={styles.veryverycol} style={blurTextStyle}>Veryverycol</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue1}>
              <div className={styles.companyName}>Bank</div>
            </div>
            <div className={styles.inputField1}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text1}>
                  <div className={styles.veryverycol} style={blurTextStyle}>Access Bank</div>
                </div>
              </div>
              <img
                className={styles.chevronArrowDownIcon}
                alt=""
                src="/chevronarrowdown.svg"
              />
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue2}>
              <div className={styles.companyName}>Account Number</div>
            </div>
            <div className={styles.inputField1}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text2}>
                  <div className={styles.veryverycol} style={blurTextStyle}>0000000001</div>
                </div>
              </div>
              <div className={styles.veryverycoolLtd}>Veryverycool LTD</div>
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue3}>
              <div className={styles.companyName}>Company’s Address</div>
            </div>
            <div className={styles.inputField1}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text3}>
                  <div className={styles.veryverycol} style={blurTextStyle}>
                    2 cool 4 school, just vibes and chives
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue4}>
              <div className={styles.companyName}>% of earnings</div>
            </div>
            <div className={styles.inputField1}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text4}>
                  <div className={styles.veryverycol} style={blurTextStyle}>40</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameWrapper}>
            <div className={styles.frameParent}>
              <div className={styles.partnershipContractWrapper}>
                <div className={styles.partnershipContract}>
                  Partnership Contract
                </div>
              </div>
              <div className={styles.frameContainer}>
                <div className={styles.uploadFileParent}>
                  <div className={styles.uploadFile}>Upload file</div>
                  <div className={styles.documentUpload}>
                    <div className={styles.uploadParent}>
                      <img
                        className={styles.uploadIcon}
                        alt=""
                        src="/upload.svg"
                      />
                      <div className={styles.pdf}>.pdf</div>
                    </div>
                    <div className={styles.documentUploadInner}>
                      <div className={styles.tapToUploadDocumentParent}>
                        <div className={styles.tapToUpload}>
                          Tap to Upload Document
                        </div>
                        <div className={styles.replaceParent}>
                          <div className={styles.replace}>Replace</div>
                          <div className={styles.remove}>Remove</div>
                        </div>
                        <div className={styles.jpgPdfGifPngParent}>
                          <div className={styles.remove}>
                            JPG, PDF, GIF, PNG
                          </div>
                          <div className={styles.remove}>Max Size: 10MB</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameWrapper}>
            <div className={styles.frameParent}>
              <div className={styles.partnershipContractWrapper}>
                <div className={styles.partnershipContract}>
                  Logo (optional)
                </div>
              </div>
              <div className={styles.frameContainer}>
                <div className={styles.uploadFileParent}>
                  <div className={styles.uploadFile}>Upload file</div>
                  <div className={styles.documentUpload}>
                    <div className={styles.uploadParent}>
                      <img
                        className={styles.uploadIcon}
                        alt=""
                        src="/upload.svg"
                      />
                      <div className={styles.pdf}>.pdf</div>
                    </div>
                    <div className={styles.documentUploadInner}>
                      <div className={styles.tapToUploadDocumentParent}>
                        <div className={styles.tapToUpload}>
                          Tap to Upload Document
                        </div>
                        <div className={styles.replaceParent}>
                          <div className={styles.replace}>Replace</div>
                          <div className={styles.remove}>Remove</div>
                        </div>
                        <div className={styles.jpgPdfGifPngParent}>
                          <div className={styles.remove}>
                            JPG, PDF, GIF, PNG
                          </div>
                          <div className={styles.remove}>Max Size: 10MB</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.partnersChild} />
      <div className={styles.inputFieldParent}>
        <div className={styles.inputField5}>
          <img className={styles.editIcon} alt="" src="/edit.svg" />
          <div className={styles.uploadFile}>Add this partner</div>
        </div>
        <div className={styles.inputField6}>
          <img className={styles.editIcon} alt="" src="/edit.svg" />
          <div className={styles.uploadFile}>{`Cancel `}</div>
        </div>
      </div>
      <div className={styles.partnersItem} />
      <div className={styles.pause}>
        <div className={styles.pauseChild} />
        <img className={styles.xCloseIcon} alt="" src="/xclose.svg" />
        <Link to="/AddPartners">
        <img className={styles.xCloseIcon1} alt="" src="/assets/x-close.svg" />
        </Link>
        <div className={styles.enterAdminCredentials}>
          Enter Admin Credentials
        </div>
        <div className={styles.inputFieldGroup}>
            <Link to="/Added">
          <div className={styles.inputField7}>
            <img className={styles.editIcon} alt="" src="/edit.svg" />
            <div className={styles.uploadFile}>Continue</div>
          </div>
          </Link>
          <Link to="/AddPartners">
          <div className={styles.inputField8}>
            <img className={styles.editIcon} alt="" src="/edit.svg" />
            <div className={styles.uploadFile}>Cancel</div>
          </div>
          </Link>
        </div>
        <div className={styles.inputFielsGroup}>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue5}>
              <div className={styles.companyName}>Username</div>
            </div>
            <div>
            <input
          className={styles.inputField9}
          placeholder="Enter Admin ID"
          type="email"
        />
            </div>
          </div>
          <div className={styles.inputFielsContainer}>
            <div className={styles.inputFiels6}>
              <div className={styles.headerValue6}>
                <div className={styles.companyName}>Password</div>
              </div>
              <div>
              <input
          className={styles.inputField10}
          placeholder="Enter Admin Password"
          type="password"
        />
              </div>
            </div>
            <div className={styles.forgottenYourPasswordContainer}>
              <span
                className={styles.forgottenYourPassword}
              >{`Forgotten your password? `}</span>
              <b className={styles.resetNow}>
                <span>Reset now</span>
                <span className={styles.span}>{` `}</span>
              </b>
            </div>
          </div>
        </div>
        <div className={styles.toAddThis}>
          To add this partner, you must provide the admin credentials.
        </div>
      </div>
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/dashboard.svg" />
            <div className={styles.uploadFile}>Dashboard</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/user-02.svg" />
            <div className={styles.uploadFile}>Subscribers</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.uploadFile}>Subscriptions</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
          <div className={styles.gm}>
            <div className={styles.component4}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.uploadFile}>Game Management</div>
              <img
                className={styles.dropdownUpIcon}
                alt=""
                src="/assets/dropdown-up.svg"
              />
            </div>
          </div>
          <div className={styles.component15}>
            <div className={styles.component14}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/partnership.svg"
              />
              <div className={styles.dashboard4}>Partners</div>
              <img
                className={styles.dropdownUpIcon}
                alt=""
                src="/assets/dropdown-up.svg"
              />
            </div>
            <div className={styles.subComponents}>
              <div className={styles.subNavComponents}>
                <div className={styles.checklistParent}>
                  <img
                    className={styles.checklistIcon}
                    alt=""
                    src="/assets/checklist.svg"
                  />
                  <div className={styles.uploadFile}>View All Partners</div>
                </div>
              </div>
              <div className={styles.subNavComponents1}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/add-user.svg"
                />
                <div className={styles.dashboard6}>Add Partner</div>
              </div>
              <div className={styles.subNavComponents}>
                <div className={styles.checklistParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/history.svg"
                  />
                  <div className={styles.uploadFile}>Payout History</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
            <div className={styles.uploadFile}>Admin Management</div>
            <img className={styles.editIcon} alt="" src="/assets/dropdown-down.svg" />
          </div>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/message.svg" />
            <div className={styles.uploadFile}>SMS</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
        </div>
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameItem}
              alt=""
              src="/rectangle-342@2x.png"
            />
            <div className={styles.tapToUploadDocumentParent}>
              <div className={styles.remove}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img className={styles.setting02Icon} alt="" src="/setting02.svg" />
        </div>
      </div>
    </div>
  );
};

export default Details;
