import { FunctionComponent, useCallback } from "react";
import styles from "./PayoutHistory.module.css";
import { Link } from 'react-router-dom';

const PayoutHistory: FunctionComponent = () => {
  const onCellItemContainerClick = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer10Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer20Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer30Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onColumnContainer5Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer60Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  return (
    <div className={styles.payoutHistory}>
      <div className={styles.managePayoutParent}>
        <div className={styles.managePayout}>MANAGE PAYOUT</div>
        <div className={styles.smallButtonParent}>
          <Link to="/ManagePayout">
          <div className={styles.smallButton}>
            <div className={styles.subscribers}>Payout</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-1@2x.png"
            />
          </div>
          </Link>
          <div className={styles.smallButton1}>
            <div className={styles.subscribers1}>Payout History</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-2@2x.png"
            />
          </div>
        </div>
        <div className={styles.component10Parent}>
          <img
            className={styles.component10Icon}
            alt=""
            src="/assets/Component 10.svg"
          />
          <div className={styles.smallButtonGroup}>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>One Million Bag</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>ChopChop Instant Win</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>Mega Money</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton1}>
              <div className={styles.subscribers1}>Instants 3/4</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-2@2x.png"
              />
            </div>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>Fast Cash</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
          </div>
          <img
            className={styles.component10Icon}
            alt=""
            src="/assets/Component 9.svg"
          />
        </div>
        <div className={styles.payoutsOnlyDisplay}>
          Payouts only display a list of winners; select a game and date, view
          all winners, and initiate payment.
        </div>
        <div className={styles.previousParent}>
          <div className={styles.previous}>Previous</div>
          <div className={styles.next}>Next</div>
          <div className={styles.last}>Last</div>
        </div>
        <div className={styles.frameWrapper}>
          <div>
          <input
    type="text"
    className={styles.frameParent} // Replace with your CSS class for the input field
    placeholder="Search"
  />
            <img className={styles.rightIcon} alt="" src="/righticon@2x.png" />
          </div>
        </div>
        <div className={styles.frameChild} />
        <div className={styles.table}>
          <div className={styles.column}>
            <div className={styles.cellHeader}>
              <div className={styles.sn}>S/N</div>
            </div>
            <div className={styles.cellItem} onClick={onCellItemContainerClick}>
              <div className={styles.sn}>1</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>2</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>3</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>4</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>5</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>6</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>7</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>8</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>9</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>10</div>
            </div>
          </div>
          <div className={styles.column1}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Mobile Number</div>
            </div>
            <div
              className={styles.cellItem10}
              onClick={onCellItemContainer10Click}
            >
              <div className={styles.previous}>08100000001</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
          </div>
          <div className={styles.column2}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Date and time</div>
            </div>
            <div
              className={styles.cellItem10}
              onClick={onCellItemContainer20Click}
            >
              <div className={styles.previous}>
                <p className={styles.p}>27/01/20023</p>
                <p className={styles.p}>9:00pm</p>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.column3}>
            <div className={styles.cellHeader1}>
              <div className={styles.previous}>Amount won</div>
            </div>
            <div
              className={styles.cellItem30}
              onClick={onCellItemContainer30Click}
            >
              <div className={styles.previous}>₦500,000,00</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
          </div>
          <div className={styles.column4}>
            <div className={styles.cellHeader4}>
              <div className={styles.previous}>Bank Account</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>Access Bank - 0000000001</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
          </div>
          <div className={styles.column5} onClick={onColumnContainer5Click}>
            <div className={styles.cellHeader5}>
              <div className={styles.previous}>Receipt</div>
            </div>
            <div className={styles.cellItem1}>
              <Link to="/Receipt">
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
              </Link>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
          </div>
          <div className={styles.column6}>
            <div className={styles.cellHeader6}>
              <div className={styles.previous}>State</div>
            </div>
            <div
              className={styles.cellItem}
              onClick={onCellItemContainer60Click}
            >
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
          </div>
        </div>
        <div className={styles.filterParent}>
          <img className={styles.filterIcon} alt="" src="/assets/filter.svg" />
          <div className={styles.subscribers}>Filter by</div>
          <img
            className={styles.chevronArrowDownIcon}
            alt=""
            src="/assets/chevron-arrow-down.svg"
          />
        </div>
      </div>
      <div className={styles.payoutHistoryChild} />
      <div className={styles.payoutHistoryItem} />
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
          <a href="/dashboard">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/dashboard.svg"
            />
            <div className={styles.previous}>Dashboard</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/subscribers">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/user-02.svg"
            />
            <div className={styles.previous}>Subscribers</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/subscriptions">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.previous}>Subscriptions</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <div className={styles.gm}>
            <div className={styles.component6}>
              <img
                className={styles.chevronArrowDownIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.subscribers1}>Game Management</div>
              <img
                className={styles.filterIcon}
                alt=""
                src="/assets/dropdown-up.svg"
              />
            </div>
            <div className={styles.subNav}>
              <a href="/ManageGames">
              <div className={styles.subNavComponents}>
                <div className={styles.component5Parent}>
                  <img
                    className={styles.chevronArrowDownIcon}
                    alt=""
                    src="/assets/Component 5.svg"
                  />
                  <div className={styles.previous}>Manage Games</div>
                </div>
              </div>
              </a>
              <a href="/ManageDraws">
              <div className={styles.subNavComponents}>
                <div className={styles.component5Parent}>
                  <img
                    className={styles.chevronArrowDownIcon}
                    alt=""
                    src="/assets/shuffle.svg"
                  />
                  <div className={styles.previous}>Manage Draws</div>
                </div>
              </div>
              </a>
              <a href="/DrawHistory">
              <div className={styles.subNavComponents}>
                <div className={styles.component5Parent}>
                  <img
                    className={styles.chevronArrowDownIcon}
                    alt=""
                    src="/assets/history.svg"
                  />
                  <div className={styles.previous}>Draw History</div>
                </div>
              </div>
              </a>
              <div className={styles.subNavComponents3}>
                <img
                  className={styles.chevronArrowDownIcon}
                  alt=""
                  src="/assets/Component 6.svg"
                />
                <div className={styles.dashboard7}>Manage Payout</div>
              </div>
            </div>
          </div>
          <a href="/partners">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/partnership.svg"
            />
            <div className={styles.previous}>Partners</div>
            <img
              className={styles.filterIcon}
              alt=""
              src="/assets/dropdown-down.svg"
            />
          </div>
          </a>
          <a href="/AdminSettings">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/admin.svg"
            />
            <div className={styles.previous}>Admin Management</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/assets/dropdown-down.svg"
            />
          </div>
          </a>
          <a href="/sms">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/message.svg"
            />
            <div className={styles.previous}>SMS</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
        </div>
        <a href="/AdminProfile">
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameItem}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.subscribers}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img
            className={styles.setting02Icon}
            alt=""
            src="/assets/setting-02.svg"
          />
        </div>
        </a>
      </div>
    </div>
  );
};

export default PayoutHistory;
