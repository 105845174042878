import { FunctionComponent, useCallback } from "react";
import styles from "./Instant.module.css";
import { Link } from 'react-router-dom';
//import './globalI.css'

const InstantCash: FunctionComponent = () => {
  const onColumnContainer1Click = useCallback(() => {
    // Please sync "dashboard" to the project
  }, []);

  const onColumnContainer2Click = useCallback(() => {
    // Please sync "dashboard" to the project
  }, []);

  return (
    <div className={styles.drawinstantCash}>
      <div className={styles.drawParent}>
        <div className={styles.draw}>DRAW</div>
        <div className={styles.component10Parent}>
          <img
            className={styles.component10Icon}
            alt=""
            src="/assets/Component 10.svg"
          />
          <div className={styles.smallButtonParent}>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>One Million Bag</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>ChopChop Instant Win</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>Mega Money</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton3}>
              <div className={styles.subscribers3}>Instants 3/4</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-2@2x.png"
              />
            </div>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>Fast Cash</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
          </div>
          <img
            className={styles.component10Icon}
            alt=""
            src="/assets/Component 9.svg"
          />
        </div>
        <div className={styles.smallButtonGroup}>
          <div className={styles.smallButton5}>
            <div className={styles.subscribers5}>Draw</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-2@2x.png"
            />
          </div>
          <Link to="/Pool">
          <div className={styles.smallButton6}>
            <div className={styles.subscribers6}>Pool</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-1@2x.png"
            />
          </div>
          </Link>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.frameGroup}>
            <div className={styles.wrapper}>
              <div className={styles.subscribers}>27</div>
            </div>
            <div className={styles.janWrapper}>
              <div className={styles.subscribers}>Jan</div>
            </div>
            <div className={styles.wrapper}>
              <div className={styles.subscribers}>2023</div>
            </div>
          </div>
          <div className={styles.smallButtonWrapper}>
            <div className={styles.smallButton7}>
              <div className={styles.subscribers7}>SUBSCRIBERS</div>
              <img
                className={styles.smallButtonChild4}
                alt=""
                src="/assets/Group 2.svg"
              />
            </div>
          </div>
        </div>
        <div className={styles.createRandomWinners}>
          Create random winners based on selected games and date input!
        </div>
        <a href="/NewDraw">
        <div className={styles.inputField}>
          <img className={styles.editIcon} alt="" src="/edit@2x.png" />
          <div className={styles.insertValue}>Schedule draws</div>
          <img
            className={styles.inputFieldChild}
            alt=""
            src="/group-1@2x.png"
          />
        </div>
        </a>
        <div className={styles.table}>
          <div className={styles.column}>
            <div className={styles.cellHeader}>
              <div className={styles.sn}>S/N</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>1</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>2</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>3</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>4</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>5</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>6</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>7</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>8</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>9</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>10</div>
            </div>
          </div>
          <div className={styles.column1} onClick={onColumnContainer1Click}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Mobile Number</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.insertValue}>08100000001</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
          </div>
          <div className={styles.column2} onClick={onColumnContainer2Click}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Guessed Number</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.insertValue}>4</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>10</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>23</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>6</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>3</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>6</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>12</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>3</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>7</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>8</div>
              </div>
            </div>
          </div>
          <div className={styles.column3}>
            <div className={styles.cellHeader3}>
              <div className={styles.sn}>Status</div>
            </div>
            <div className={styles.cellItem30}>
              <div className={styles.sn}>Winning number</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Wrong `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Wrong `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Wrong `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Wrong `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Wrong `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Wrong `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Wrong `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Wrong `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Wrong `}</div>
            </div>
          </div>
        </div>
        <div className={styles.previousParent}>
          <div className={styles.insertValue}>Previous</div>
          <div className={styles.next}>Next</div>
          <div className={styles.last}>Last</div>
        </div>
        <div className={styles.frameContainer}>
          <div>
          <input
    type="text"
    className={styles.frameDiv} // Replace with your CSS class for the input field
    placeholder="Search"
  />
          </div>
          <div className={styles.smallButtonContainer}>
            <div className={styles.smallButton3}>
              <div className={styles.subscribers3}>All players</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-2@2x.png"
              />
            </div>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>Winners</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>Wrong Numbers</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.drawinstantCashChild} />
      <div className={styles.drawinstantCashItem} />
      <div className={styles.drawinstantCashInner} />
      <div className={styles.lineDiv} />
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
            <a href="/dashboard">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/dashboard.svg"
            />
            <div className={styles.insertValue}>Dashboard</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/subscribers">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/user-02.svg" />
            <div className={styles.insertValue}>Subscribers</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/subscriptions">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.insertValue}>Subscriptions</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <div className={styles.gm}>
            <div className={styles.component6}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.subscribers3}>Game Management</div>
              <img
                className={styles.editIcon}
                alt=""
                src="/assets/dropdown-down.svg"
              />
            </div>
            <div className={styles.subNav}>
                <a href="/ManageGames">
              <div className={styles.subNavComponents}>
                <div className={styles.component5Parent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/Component 5.svg"
                  />
                  <div className={styles.insertValue}>Manage Games</div>
                </div>
              </div>
              </a>
              <div className={styles.subNavComponents1}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/shuffle.svg"
                />
                <div className={styles.dashboard5}>Manage Draws</div>
              </div>
              <a href="/DrawHistory">
              <div className={styles.subNavComponents}>
                <div className={styles.component5Parent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/history.svg"
                  />
                  <div className={styles.insertValue}>Draw History</div>
                </div>
              </div>
              </a>
              <a href="/ManagePayout">
              <div className={styles.subNavComponents}>
                <div className={styles.component5Parent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/Component 6.svg"
                  />
                  <div className={styles.insertValue}>Manage Payout</div>
                </div>
              </div>
              </a>
            </div>
          </div>
          <a href="/partners">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/partnership.svg"
            />
            <div className={styles.insertValue}>Partners</div>
            <img
              className={styles.dropdownDownIcon4}
              alt=""
              src="/assets/dropdown-down.svg"
            />
          </div>
          </a>
          <a href="/AdminSettings">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
            <div className={styles.insertValue}>Admin Management</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/sms">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/message.svg"
            />
            <div className={styles.insertValue}>SMS</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
        </div>
        <a href="/AdminProfile">
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameChild}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.subscribers}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img
            className={styles.setting02Icon}
            alt=""
            src="/assets/setting-02.svg"
          />
        </div>
        </a>
      </div>
    </div>
  );
};

export default InstantCash;
