import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import { verifyCode } from "../api"; // Import your verifyCode function
import styles from "./SendCode.module.css";
//import './global3.css';

const SendCode: FunctionComponent = () => {
  const history = useHistory(); // Obtain the history object using the useHistory hook
  const [verificationCode, setVerificationCode] = useState<string>("");

  const handleVerificationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Handle verification code input change here
    const input = e.target.value;
    if (/^\d{0,5}$/.test(input)) {
      setVerificationCode(input);
    }
  };

  const handleResetPasswordClick = async () => {
    try {
      await verifyCode(verificationCode);
      // Code verification successful, navigate to the password reset page
      history.push("/ChangePassword");
    } catch (error) {
      // Handle code verification error here
      console.error('Code verification error:', error);
    }
  };
  return (
    <div className={styles.passwordRecovery3}>
      <div className={styles.buzzycashParent}>
        <b className={styles.buzzycash}>BUZZYCASH</b>
        <div className={styles.frameChild} />
      </div>
      <div className={styles.forgottenPasswordParent}>
        <div className={styles.forgottenPassword}>Forgotten Password</div>
        <div className={styles.inputTheCode}>
          Input the code sent to your Email
        </div>
      </div>
      <div className={styles.frameParent}>
        {/* Handle input change event */}
        <input
          className={styles.frameItem}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength={1}
          value={verificationCode[0]}
          onChange={handleVerificationCodeChange}
        />
        <input
          className={styles.frameItem}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength={1}
          value={verificationCode[1]}
          onChange={handleVerificationCodeChange}
        />
        <input
          className={styles.frameItem}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength={1}
          value={verificationCode[2]}
          onChange={handleVerificationCodeChange}
        />
        <input
          className={styles.frameItem}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength={1}
          value={verificationCode[3]}
          onChange={handleVerificationCodeChange}
        />
        <input
          className={styles.frameItem}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength={1}
          value={verificationCode[4]}
          onChange={handleVerificationCodeChange}
        />
      </div>
      <div className={styles.ellipseParent}>
        <div className={styles.ellipseDiv} />
        <div className={styles.insertValue}>BuzzyCash</div>
      </div>
      {/* Use dynamic href based on code verification */}
      <div className={styles.inputField} onClick={handleResetPasswordClick}>
        <div className={styles.insertValue}>Reset Password</div>
      </div>
    </div>
  );
};

export default SendCode;
