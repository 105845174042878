import { FunctionComponent } from "react";
import styles from "./Pool.module.css";
import { Link } from 'react-router-dom';

const Pool: FunctionComponent = () => {
  return (
    <div className={styles.draw}>
      <div className={styles.drawParent}>
        <div className={styles.draw1}>DRAW</div>
        <div className={styles.component10Parent}>
          <img
            className={styles.component10Icon}
            alt=""
            src="/assets/Component 10.svg"
          />
          <div className={styles.smallButtonParent}>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>One Million Bag</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>ChopChop Instant Win</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>Mega Money</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton3}>
              <div className={styles.subscribers3}>Instants 3/4</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-2@2x.png"
              />
            </div>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>Fast Cash</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
          </div>
          <img
            className={styles.component10Icon}
            alt=""
            src="/assets/Component 9.svg"
          />
        </div>
        <div className={styles.smallButtonGroup}>
          <Link to="/ManageDraw">
          <div className={styles.smallButton5}>
            <div className={styles.subscribers5}>Draw</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-1@2x.png"
            />
          </div>
          </Link>
          <div className={styles.smallButton6}>
            <div className={styles.subscribers6}>Pool</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-2@2x.png"
            />
          </div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.frameGroup}>
            <div className={styles.wrapper}>
              <div className={styles.subscribers}>27</div>
            </div>
            <div className={styles.janWrapper}>
              <div className={styles.subscribers}>Jan</div>
            </div>
            <div className={styles.wrapper}>
              <div className={styles.subscribers}>2023</div>
            </div>
          </div>
          <div className={styles.smallButtonWrapper}>
            <div className={styles.smallButton7}>
              <div className={styles.subscribers7}>SUBSCRIBERS</div>
              <img
                className={styles.smallButtonChild4}
                alt=""
                src="/assets/Group 2.svg"
              />
            </div>
          </div>
        </div>
        <div className={styles.createRandomWinners}>
          Create random winners based on selected games and date input!
        </div>
        <div className={styles.inputField}>
          <img className={styles.editIcon} alt="" src="/assets/edit.svg" />
          <div className={styles.insertValue}>Schedule pool draws</div>
          <img
            className={styles.inputFieldChild}
            alt=""
            src="/group-1@2x.png"
          />
        </div>
        <div className={styles.component10Group}>
          <img
            className={styles.component10Icon}
            alt=""
            src="/assets/Component 10.svg"
          />
          <div className={styles.smallButtonContainer}>
            <Link to="/Pool1">
            <div className={styles.smallButton3}>
              <div className={styles.subscribers3}>Pool 1</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-2@2x.png"
              />
            </div>
            </Link>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>Pool 2</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>Pool 3</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>Pool 4</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>Pool 5</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
          </div>
          <img
            className={styles.component10Icon}
            alt=""
            src="/assets/Component 9.svg"
          />
        </div>
        <div className={styles.frameContainer}>
          <div className={styles.activePlayersParent}>
            <div className={styles.activePlayers}>
              <div className={styles.subscribers3}>Active Players</div>
            </div>
            <div className={styles.activePlayers2}>
              <div className={styles.listOfPlayersParent}>
                <div className={styles.listOfPlayers}>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                  <div className={styles.insertValue}>
                    <ul className={styles.ul}>
                      <li>08100000001</li>
                    </ul>
                  </div>
                </div>
                <div className={styles.frameChild} />
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <div className={styles.drawWinners}>
              <div className={styles.numberOfDraw}>Number of draw winners</div>
              <div className={styles.selectOOfDrawWinners}>
                <img
                  className={styles.increaseIcon}
                  alt=""
                  src="/assets/increase.svg"
                />
                <div className={styles.reduce}>
                  <img
                    className={styles.subtractIcon}
                    alt=""
                    src="/assets/subtract.svg"
                  />
                </div>
                <div className={styles.div42}>2</div>
              </div>
            </div>
            <div className={styles.inputField1}>
              <img className={styles.editIcon} alt="" src="/edit@2x.png" />
              <div className={styles.insertValue}>Draw Winner</div>
              <img
                className={styles.inputFieldChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
          </div>
          <div className={styles.drawWinners1}>
            <div className={styles.subscribers3}>Draw winners</div>
            <div className={styles.drawWinnersContent}>
              <div className={styles.inputField2}>
                <img className={styles.editIcon} alt="" src="/edit@2x.png" />
                <div className={styles.insertValue}>Proceed to Payout</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.drawChild} />
      <div className={styles.drawItem} />
      <div className={styles.drawInner} />
      <div className={styles.lineDiv} />
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
            <a href="/dashboard">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/dashboard.svg"
            />
            <div className={styles.insertValue}>Dashboard</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/subscribers">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/user-02.svg" />
            <div className={styles.insertValue}>Subscribers</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/subscriptions">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.insertValue}>Subscriptions</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <div className={styles.gm}>
            <div className={styles.component6}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.subscribers3}>Game Management</div>
              <img
                className={styles.dropdownUpIcon}
                alt=""
                src="/assets/dropdown-up.svg"
              />
            </div>
            <div className={styles.subNav}>
                <a href="/ManageGame">
              <div className={styles.subNavComponents}>
                <div className={styles.component5Parent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/Component 5.svg"
                  />
                  <div className={styles.insertValue}>Manage Games</div>
                </div>
              </div>
              </a>
              <div className={styles.subNavComponents1}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/shuffle.svg"
                />
                <div className={styles.numberOfDraw}>Manage Draws</div>
              </div>
              <a href="/DrawHistory">
              <div className={styles.subNavComponents}>
                <div className={styles.component5Parent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/history.svg"
                  />
                  <div className={styles.insertValue}>Draw History</div>
                </div>
              </div>
              </a>
              <a href="/ManagePayout">
              <div className={styles.subNavComponents}>
                <div className={styles.component5Parent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/Component 6.svg"
                  />
                  <div className={styles.insertValue}>Manage Payout</div>
                </div>
              </div>
              </a>
            </div>
          </div>
          <a href="/partners">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/partnership.svg"
            />
            <div className={styles.insertValue}>Partners</div>
            <img
              className={styles.dropdownUpIcon}
              alt=""
              src="/assets/dropdown-down.svg"
            />
          </div>
          </a>
          <a href="/AdminSettings">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
            <div className={styles.insertValue}>Admin Management</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/sms">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/message.svg"
            />
            <div className={styles.insertValue}>SMS</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
        </div>
        <a href="/AdminProfile">
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameItem}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.activePlayersParent}>
              <div className={styles.subscribers}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img
            className={styles.setting02Icon}
            alt=""
            src="/assets/setting-02.svg"
          />
        </div>
        </a>
      </div>
    </div>
  );
};

export default Pool;
