import { FunctionComponent } from "react";
import styles from "./PartnerReceipt.module.css";
import {Link} from 'react-router-dom';

const PartnerReceipt: FunctionComponent = () => {
  return (
    <div className={styles.payoutHistory}>
      <div className={styles.partnersParent}>
        <div className={styles.partners}>PARTNERS</div>
        <div className={styles.partnersPayoutHistory}>
          Partners Payout History
        </div>
        <div className={styles.frameChild} />
        <div className={styles.previousParent}>
          <div className={styles.previous}>Previous</div>
          <div className={styles.next}>Next</div>
          <div className={styles.last}>Last</div>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.frameParent}>
            <div className={styles.leftIconParent}>
              <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
              <div className={styles.text}>
                <div className={styles.textValue}>Search</div>
              </div>
            </div>
            <img className={styles.rightIcon} alt="" src="/righticon.svg" />
          </div>
        </div>
        <div className={styles.frameItem} />
        <div className={styles.table}>
          <div className={styles.column}>
            <div className={styles.cellHeader}>
              <div className={styles.sn}>S/N</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>1</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>2</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>3</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>4</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>5</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>6</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>7</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>8</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>9</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>10</div>
            </div>
          </div>
          <div className={styles.column1}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Company Name</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.previous}>ExxonMobil</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
          </div>
          <div className={styles.column2}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Date and time</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.previous}>
                <p className={styles.p}>27/01/20023</p>
                <p className={styles.p}>9:00pm</p>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.column3}>
            <div className={styles.cellHeader1}>
              <div className={styles.amountSent}>Amount Sent</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.previous}>₦500,000,00</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
          </div>
          <div className={styles.column4}>
            <div className={styles.cellHeader4}>
              <div className={styles.previous}>Bank Account</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>Access Bank - 0000000001</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
          </div>
          <div className={styles.column5}>
            <div className={styles.cellHeader5}>
              <div className={styles.previous}>Receipt</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
          </div>
          <div className={styles.column6}>
            <div className={styles.cellHeader6}>
              <div className={styles.previous}>State</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.previous}>Successful</div>
            </div>
          </div>
        </div>
        <div className={styles.filterParent}>
          <img className={styles.filterIcon} alt="" src="/filter.svg" />
          <div className={styles.subscribers}>Filter by</div>
          <img
            className={styles.chevronArrowDownIcon}
            alt=""
            src="/chevronarrowdown.svg"
          />
        </div>
      </div>
      <div className={styles.payoutHistoryChild} />
      <div className={styles.payoutHistoryItem} />
      <div className={styles.reciept}>
        <div className={styles.recieptChild} />
        <img className={styles.xCloseIcon} alt="" src="/xclose.svg" />
        <div className={styles.recieptItem} />
        <div className={styles.buzzycashParent}>
          <b className={styles.buzzycash}>BUZZYCASH</b>
          <div className={styles.groupChild} />
        </div>
        <div className={styles.buzzycashGroup}>
          <b className={styles.subscribers}>BUZZYCASH</b>
          <div className={styles.pm20}>
            <p className={styles.p}>27/01/20023</p>
            <p className={styles.p}>9:00pm</p>
          </div>
        </div>
        <div className={styles.recieptInner} />
        <div className={styles.frameGroup}>
          <div className={styles.companyNameParent}>
            <div className={styles.previous}>Company Name</div>
            <div className={styles.exxonmobile}>ExxonMobile</div>
          </div>
          <div className={styles.companyNameParent}>
            <div className={styles.previous}>Amount paid</div>
            <div className={styles.exxonmobile}>₦500,000,00</div>
          </div>
          <div className={styles.companyNameParent}>
            <div className={styles.previous}>Date deposited</div>
            <div className={styles.pm22}>
              <p className={styles.p}>27/01/20023</p>
              <p className={styles.p}>9:00pm</p>
            </div>
          </div>
          <div className={styles.companyNameParent}>
            <div className={styles.previous}>Bank</div>
            <div className={styles.pm22}>Access Bank</div>
          </div>
          <div className={styles.companyNameParent}>
            <div className={styles.previous}>Account Number</div>
            <div className={styles.pm22}>0000000001</div>
          </div>
        </div>
        <div className={styles.smallButton10}>
          <div className={styles.subscribers}>Download receipt</div>
          <img className={styles.smallButtonChild7} alt="" src="/group-1.svg" />
        </div>
        <Link to="/PartnerHistory">
        <img className={styles.xCloseIcon1} alt="" src="/assets/x-close.svg" />
        </Link>
      </div>
      <div className={styles.navbar}>
        <div className={styles.buzzycash2}>BuzzyCash</div>
        <div className={styles.component4Parent}>
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/dashboard.svg"
            />
            <div className={styles.previous}>Dashboard</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/user-02.svg"
            />
            <div className={styles.previous}>Subscribers</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.previous}>Subscriptions</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
          <div className={styles.gm}>
            <div className={styles.component4}>
              <img
                className={styles.chevronArrowDownIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.previous}>Game Management</div>
              <img className={styles.filterIcon} alt="" src="/assets/dropdown-up.svg" />
            </div>
          </div>
          <div className={styles.component15}>
            <div className={styles.component14}>
              <img
                className={styles.chevronArrowDownIcon}
                alt=""
                src="/assets/partnership.svg"
              />
              <div className={styles.dashboard4}>Partners</div>
              <img className={styles.filterIcon} alt="" src="/assets/dropdown-up.svg" />
            </div>
            <div className={styles.subComponents}>
              <div className={styles.subNavComponents}>
                <div className={styles.checklistParent}>
                  <img
                    className={styles.leftIcon}
                    alt=""
                    src="/assets/checklist.svg"
                  />
                  <div className={styles.previous}>View All Partners</div>
                </div>
              </div>
              <div className={styles.subNavComponents}>
                <div className={styles.checklistParent}>
                  <img
                    className={styles.chevronArrowDownIcon}
                    alt=""
                    src="/assets/add-user.svg"
                  />
                  <div className={styles.previous}>Add Partner</div>
                </div>
              </div>
              <div className={styles.subNavComponents2}>
                <img
                  className={styles.chevronArrowDownIcon}
                  alt=""
                  src="/assets/Component 6.svg"
                />
                <div className={styles.dashboard7}>Pay Partners</div>
              </div>
            </div>
          </div>
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/admin.svg"
            />
            <div className={styles.previous}>Admin Management</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/assets/dropdown-down.svg"
            />
          </div>
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/message.svg"
            />
            <div className={styles.previous}>SMS</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
        </div>
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameInner}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.subscribers}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img className={styles.setting02Icon} alt="" src="/assets/setting-02.svg" />
        </div>
      </div>
    </div>
  );
};

export default PartnerReceipt;
