import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from "./Login.module.css";
import { Link} from 'react-router-dom';
import { login } from '../api'; // Import the login function from the api.ts file

const Login: React.FC = () => {
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [error, setError] = useState<string | null>(null); // State for holding the error message
  const history = useHistory();

  const handleLogin = async () => {
    if (userEmail.trim() === '' || userPassword.trim() === '') {
      setError('Please enter both Admin ID and Password');
      return;
    }

    try {
      const response = await login(userEmail, userPassword);
      console.log('Authentication successful:', response);
      history.push('/dashboard');
    } catch (error) {
      setError('Invalid credentials. Please try again.'); // Set the error message on login failure
      console.error('Authentication error:', error);
    }
  };
  const handleCloseError = () => {
    setError('');
  };
  
  
  

  return (
    <div className={styles.login}>
      <div className={styles.buzzycashParent}>
        <h1 className={styles.buzzycash}>BUZZYCASH</h1>
        <div className={styles.frameChild} />
      </div>
      <div className={styles.welcomeBackParent}>
        <h2 className={styles.welcomeBack}>Welcome back</h2>
        <div className={styles.logInWith}>Log in with your admin details</div>
      </div>
      <div className={styles.frameParent}>
        <input
          className={styles.frameItem}
          placeholder="Admin ID"
          type="email"
          value={userEmail} // Use userEmail state variable here
          onChange={(e) => setUserEmail(e.target.value)} // Update userEmail when the input changes
        />
        <input
          className={styles.frameItem}
          placeholder="Password"
          type="password"
          value={userPassword} // Use userPassword state variable here
          onChange={(e) => setUserPassword(e.target.value)} // Update userPassword when the input changes
        />
      </div>
      <div className={styles.frameGroup}>
  <label className={styles.checkboxContainer}>
    <input type="checkbox" className={styles.checkbox} />
    <span className={styles.checkmark}></span>
  </label>
  <div className={styles.rememberMe}>Remember me</div>
</div>

      <div className={styles.ellipseParent}>
        <div className={styles.ellipseDiv} />
        <div className={styles.buzzycash1}>BuzzyCash</div>
      </div>
      <div className={styles.inputField}>
        {/* Add an onClick event to handle the sign-in click */}
<span className={styles.insertValue} onClick={handleLogin}>
  Sign in
</span>
      </div>
      {error && (
        <div className={styles.errorContainer}>
          <span className={styles.errorMessage}>{error}</span>
          <button className={styles.closeButton} onClick={handleCloseError}>
          &times; {/* "X" character for close button */}
        </button>
        </div>
      )}
      <div className={styles.forgottenYourPasswordContainer}>
        <span
          className={styles.forgottenYourPassword}
        >{`Forgotten your password? `}</span>
    <Link to="/ForgotPassword" className={styles.resetNow}>
      <span>Reset now</span>
      <span className={styles.span}>{` `}</span>
    </Link>
      </div>
    </div>
  );
};

export default Login;
