import { FunctionComponent, useCallback } from "react";
import { Link } from 'react-router-dom';
import styles from "./ScheduledDraws.module.css";

const ScheduledDraws: FunctionComponent = () => {
  const onFrameContainerClick = useCallback(() => {
    // Please sync "draw" to the project
  }, []);

  const onColumnContainer1Click = useCallback(() => {
    // Please sync "dashboard" to the project
  }, []);

  const onColumnContainer2Click = useCallback(() => {
    // Please sync "dashboard" to the project
  }, []);

  const onColumnContainer3Click = useCallback(() => {
    // Please sync "dashboard" to the project
  }, []);

  const onColumnContainer4Click = useCallback(() => {
    // Please sync "dashboard" to the project
  }, []);

  const onColumnContainer5Click = useCallback(() => {
    // Please sync "dashboard" to the project
  }, []);

  return (
    <div className={styles.scheduleDraw}>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div
            className={styles.arrowLeftLineParent}
            onClick={onFrameContainerClick}
          >
            <Link to="/ManageDraw">
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/assets/arrow-left-line.svg"
            />
            </Link>
            <div className={styles.goBack}>Go back</div>
          </div>
          <div className={styles.draw}>DRAW</div>
        </div>
        <div className={styles.scheduledDraws}>Scheduled draws</div>
        <div className={styles.smallButtonParent}>
            <Link to="/NewDraw">
          <div className={styles.smallButton}>
            <div className={styles.subscribers}>Schedule a draw</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-1@2x.png"
            />
          </div>
          </Link>
          <div className={styles.smallButton1}>
            <div className={styles.subscribers1}>View all scheduled draws</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-2@2x.png"
            />
          </div>
        </div>
        <div className={styles.table}>
          <div className={styles.column}>
            <div className={styles.cellHeader}>
              <div className={styles.sn}>S/N</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>1</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>2</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>3</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>4</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>5</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>6</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>7</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>8</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>9</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>10</div>
            </div>
          </div>
          <div className={styles.column1} onClick={onColumnContainer1Click}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Game type</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.goBack}>ChopChop Instant Cash</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>One Million Bag</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>Fast Cash</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>Mega Money</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>Fast Cash</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>One Million Bag</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>One Million Bag</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>Fast Cash</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>
                  ChopChop Instant Cash
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>Mega Money</div>
              </div>
            </div>
          </div>
          <div className={styles.column2} onClick={onColumnContainer2Click}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Recurring</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.goBack}>Daily</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>Daily</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>Weekly</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>-</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>Daily</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>Daily</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>Daily</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>-</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>Daily</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>-</div>
              </div>
            </div>
          </div>
          <div className={styles.column3} onClick={onColumnContainer3Click}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Active Players</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.goBack}>4</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>5</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>6</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>7</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>8</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>9</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>10</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>2</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>1</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>3</div>
              </div>
            </div>
          </div>
          <div className={styles.column2} onClick={onColumnContainer4Click}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Number of winners</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.goBack}>4</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>5</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>6</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>7</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>8</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>9</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>10</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>2</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>1</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.oneMillionBag}>3</div>
              </div>
            </div>
          </div>
          <div className={styles.column5} onClick={onColumnContainer5Click}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Initiated date and time</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.goBack}>
                <p className={styles.p}>27/01/2023</p>
                <p className={styles.p}>9pm</p>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/2023</p>
                  <p className={styles.p}>9pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/2023</p>
                  <p className={styles.p}>9pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/2023</p>
                  <p className={styles.p}>9pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/2023</p>
                  <p className={styles.p}>9pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/2023</p>
                  <p className={styles.p}>9pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/2023</p>
                  <p className={styles.p}>9pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/2023</p>
                  <p className={styles.p}>9pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/2023</p>
                  <p className={styles.p}>9pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/2023</p>
                  <p className={styles.p}>9pm</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.column6}>
            <div className={styles.cellHeader6}>
              <div className={styles.goBack}>Advanced Info</div>
            </div>
            <div className={styles.cellItem}>
                <Link to="/Info">
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
              </Link>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameContainer}>
          <div className={styles.frameDiv}>
            <div>
            <input
    type="text"
    className={styles.frameParent1} // Replace with your CSS class for the input field
    placeholder="Search"
  />
            </div>
            <div className={styles.filterParent}>
              <img
                className={styles.arrowLeftLineIcon}
                alt=""
                src="/assets/filter.svg"
              />
              <div className={styles.subscribers}>Filter by</div>
              <img
                className={styles.chevronArrowDownIcon}
                alt=""
                src="/assets/chevron-arrow-down.svg"
              />
            </div>
          </div>
          <div className={styles.showing12Of10000000EntriWrapper}>
            <div className={styles.goBack}>
              Showing 12 of 10,000,000 entries
            </div>
          </div>
        </div>
        <div className={styles.previousParent}>
          <div className={styles.goBack}>Previous</div>
          <div className={styles.next}>Next</div>
          <div className={styles.last}>Last</div>
        </div>
      </div>
      <div className={styles.scheduleDrawChild} />
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
            <a href="/dashboard">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/dashboard.svg"
            />
            <div className={styles.goBack}>Dashboard</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/subscriber">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/user-02.svg"
            />
            <div className={styles.goBack}>Subscribers</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/subscriptions">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.goBack}>Subscriptions</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <div className={styles.gm}>
            <div className={styles.component6}>
              <img
                className={styles.chevronArrowDownIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.subscribers1}>Game Management</div>
              <img
                className={styles.dropdownDownIcon}
                alt=""
                src="/assets/dropdown-down.svg"
              />
            </div>
            <div className={styles.subNav}>
                <a href="/ManageGames">
              <div className={styles.subNavComponents}>
                <div className={styles.component5Parent}>
                  <img
                    className={styles.chevronArrowDownIcon}
                    alt=""
                    src="/assets/Component 5.svg"
                  />
                  <div className={styles.goBack}>Manage Games</div>
                </div>
              </div>
              </a>
              <a href="/ManageDraw">
              <div className={styles.subNavComponents1}>
                <img
                  className={styles.chevronArrowDownIcon}
                  alt=""
                  src="/assets/shuffle.svg"
                />
                <div className={styles.dashboard5}>Manage Draws</div>
              </div>
              </a>
              <a href="/DrawHistory">
              <div className={styles.subNavComponents}>
                <div className={styles.component5Parent}>
                  <img
                    className={styles.chevronArrowDownIcon}
                    alt=""
                    src="/assets/history.svg"
                  />
                  <div className={styles.goBack}>Draw History</div>
                </div>
              </div>
              </a>
              <a href="/ManagePayout">
              <div className={styles.subNavComponents}>
                <div className={styles.component5Parent}>
                  <img
                    className={styles.chevronArrowDownIcon}
                    alt=""
                    src="/assets/Component 6.svg"
                  />
                  <div className={styles.goBack}>Manage Payout</div>
                </div>
              </div>
              </a>
            </div>
          </div>
          <a href="partners">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/partnership.svg"
            />
            <div className={styles.goBack}>Partners</div>
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/assets/dropdown-down.svg"
            />
          </div>
          </a>
          <a href="/AdminSettings">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/admin.svg"
            />
            <div className={styles.goBack}>Admin Management</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/sms">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/message.svg"
            />
            <div className={styles.goBack}>SMS</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
        </div>
        <a href="/AdminProfile">
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameChild}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.subscribers}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img
            className={styles.setting02Icon}
            alt=""
            src="/assets/setting-02.svg"
          />
        </div>
        </a>
      </div>
    </div>
  );
};

export default ScheduledDraws;
