import { FunctionComponent } from "react";
import { Link } from 'react-router-dom';
import styles from "./Info.module.css";

const Info: FunctionComponent = () => {
  return (
    <div className={styles.frameParent}>
      <div className={styles.frameGroup}>
        <div className={styles.frameContainer}>
          <div className={styles.frameDiv}>
            <div className={styles.arrowLeftLineParent}>
                <Link to="/ScheduledDraws">
              <img
                className={styles.arrowLeftLineIcon}
                alt=""
                src="/assets/arrow-left-line.svg"
              />
              </Link>
              <div className={styles.goBack}>Go back</div>
            </div>
            <div className={styles.draw}>DRAW</div>
          </div>
          <div className={styles.frameParent1}>
            <div className={styles.scheduleContentWrapper}>
              <div className={styles.scheduleContent}>
                <div className={styles.gameType}>Game type</div>
                <div className={styles.time}>
                  <div className={styles.time1}>
                    <div className={styles.chopchopInstantCash}>
                      ChopChop Instant Cash
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.scheduleContentWrapper}>
              <div className={styles.scheduleContent}>
                <div className={styles.gameType}>Date and time initiated</div>
                <div className={styles.time2}>
                  <div className={styles.time1}>
                    <div className={styles.chopchopInstantCash}>
                      27th January 2023
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.scheduleContentWrapper}>
              <div className={styles.scheduleContent}>
                <div className={styles.gameType}>Recurring</div>
                <div className={styles.time}>
                  <div className={styles.time1}>
                    <div className={styles.chopchopInstantCash}>Daily</div>
                  </div>
                </div>
              </div>
              <img
                className={styles.chevronArrowDownIcon}
                alt=""
                src="/assets/chevron-arrow-down.svg"
              />
            </div>
            <div className={styles.scheduleContentWrapper}>
              <div className={styles.scheduleContent}>
                <div className={styles.gameType}>Active players</div>
                <div className={styles.time}>
                  <div className={styles.time1}>
                    <div className={styles.chopchopInstantCash}>50</div>
                  </div>
                </div>
              </div>
              <img className={styles.moreIcon} alt="" src="/assets/more.svg" />
            </div>
            <div className={styles.scheduleContentWrapper}>
              <div className={styles.scheduleContent}>
                <div className={styles.gameType}>Number of winners</div>
                <div className={styles.time}>
                  <div className={styles.time1}>
                    <div className={styles.chopchopInstantCash}>8</div>
                  </div>
                </div>
              </div>
              <img
                className={styles.chevronArrowDownIcon}
                alt=""
                src="/assets/chevron-arrow-down.svg"
              />
            </div>
          </div>
          <div className={styles.scheduledContent}>
            <div className={styles.adminOfficerParent}>
              <div className={styles.gameType}>Status</div>
              <div className={styles.time10}>
                <div className={styles.time1}>
                  <div className={styles.chopchopInstantCash}>In Progress</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.component9variant3Parent}>
            <img
              className={styles.component9variant3Icon}
              alt=""
              src="/assets/Component 10.svg"
            />
            <div className={styles.frameParent2}>
              <div className={styles.frameParent3}>
                <div className={styles.todaysGameParent}>
                  <div className={styles.todaysGame}>Today’s game</div>
                  <div className={styles.div3}>28/01/2023</div>
                </div>
                <img
                  className={styles.arrowLeftLineIcon}
                  alt=""
                  src="/assets/chevron-arrow-down.svg"
                />
              </div>
              <div className={styles.tomorrowsGameParent}>
                <div className={styles.tomorrowsGame}>Tomorrow’s game</div>
                <div className={styles.div4}>29/01/2023</div>
                <div className={styles.frameChild} />
              </div>
            </div>
            <img
              className={styles.component9variant3Icon}
              alt=""
              src="/assets/Component 9.svg"
            />
          </div>
          <div className={styles.inputFieldParent}>
            <div className={styles.inputField}>
              <img
                className={styles.arrowLeftLineIcon}
                alt=""
                src="/assets/edit.svg"
              />
              <div className={styles.goBack}>Edit draw</div>
            </div>
            <div className={styles.inputField1}>
              <img
                className={styles.arrowLeftLineIcon}
                alt=""
                src="/assets/pause.svg"
              />
              <div className={styles.goBack}>Pause draw</div>
            </div>
            <div className={styles.inputField2}>
              <img
                className={styles.arrowLeftLineIcon}
                alt=""
                src="/assets/x-close.svg"
              />
              <div className={styles.goBack}>Terminate draw</div>
            </div>
          </div>
        </div>
        <div className={styles.navbar}>
          <div className={styles.buzzycash}>BuzzyCash</div>
          <div className={styles.component4Parent}>
            <a href="/dashboard">
            <div className={styles.component4}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/dashboard.svg"
              />
              <div className={styles.goBack}>Dashboard</div>
              <img
                className={styles.dropdownDownIcon}
                alt=""
                src="/dropdowndown@2x.png"
              />
            </div>
            </a>
            <a href="/subscribers">
            <div className={styles.component4}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/user-02.svg"
              />
              <div className={styles.goBack}>Subscribers</div>
              <img
                className={styles.dropdownDownIcon}
                alt=""
                src="/dropdowndown@2x.png"
              />
            </div>
            </a>
            <a href="/subscriptions">
            <div className={styles.component4}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/subscription.svg"
              />
              <div className={styles.goBack}>Subscriptions</div>
              <img
                className={styles.dropdownDownIcon}
                alt=""
                src="/dropdowndown@2x.png"
              />
            </div>
            </a>
            <div className={styles.gm}>
              <div className={styles.component6}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/Component 5.svg"
                />
                <div className={styles.gameType}>Game Management</div>
                <img
                  className={styles.dropdownDownIcon}
                  alt=""
                  src="/assets/dropdown-down.svg"
                />
              </div>
              <div className={styles.subNav}>
                <a href="/ManageGames">
                <div className={styles.subNavComponents}>
                  <div className={styles.component5Parent}>
                    <img
                      className={styles.dashboardIcon}
                      alt=""
                      src="/assets/Component 5.svg"
                    />
                    <div className={styles.goBack}>Manage Games</div>
                  </div>
                </div>
                </a>
                <a href="/ManageDraws">
                <div className={styles.subNavComponents1}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/shuffle.svg"
                  />
                  <div className={styles.todaysGame}>Manage Draws</div>
                </div>
                </a>
                <a href="/DrawHistory">
                <div className={styles.subNavComponents}>
                  <div className={styles.component5Parent}>
                    <img
                      className={styles.dashboardIcon}
                      alt=""
                      src="/assets/history.svg"
                    />
                    <div className={styles.goBack}>Draw History</div>
                  </div>
                </div>
                </a>
                <a href="/ManagePayout">
                <div className={styles.subNavComponents}>
                  <div className={styles.component5Parent}>
                    <img
                      className={styles.dashboardIcon}
                      alt=""
                      src="/assets/Component 6.svg"
                    />
                    <div className={styles.goBack}>Manage Payout</div>
                  </div>
                </div>
                </a>
              </div>
            </div>
            <a href="/partners">
            <div className={styles.component4}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/partnership.svg"
              />
              <div className={styles.goBack}>Partners</div>
              <img
                className={styles.arrowLeftLineIcon}
                alt=""
                src="/assets/dropdown-down.svg"
              />
            </div>
            </a>
            <a href="/AdminSettings">
            <div className={styles.component4}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/admin.svg"
              />
              <div className={styles.goBack}>Admin Management</div>
              <img
                className={styles.dropdownDownIcon}
                alt=""
                src="/dropdowndown@2x.png"
              />
            </div>
            </a>
            <a href="/sms">
            <div className={styles.component4}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/message.svg"
              />
              <div className={styles.goBack}>SMS</div>
              <img
                className={styles.dropdownDownIcon}
                alt=""
                src="/dropdowndown@2x.png"
              />
            </div>
            </a>
          </div>
          <a href="/AdminProfile">
          <div className={styles.component81}>
            <div className={styles.account}>ACCOUNT</div>
            <div className={styles.rectangleParent}>
              <img
                className={styles.frameItem}
                alt=""
                src="/assets/Rectangle 342.png"
              />
              <div className={styles.adminOfficerParent}>
                <div className={styles.chopchopInstantCash}>ADMIN OFFICER</div>
                <b className={styles.gilbertAdams}>Gilbert Adams</b>
              </div>
            </div>
            <img
              className={styles.setting02Icon}
              alt=""
              src="/assets/setting-02.svg"
            />
          </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Info;
