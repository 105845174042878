import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './components/SignIn/Login';
//import SignUp from './components/SigIn/Signup';
import ForgotPassword from './components/SignIn/ForgotPassword';
import Dashboard from './components/Dashboard/Dashboard';
import Subscriber from './components/Subscribers/subscriber';
//import ResetPassword from './components/ResetPassword';
import SendCode from './components/SignIn/SendCode';
import ChangePassword from './components/SignIn/ChangePassword';
import Subscription from './components/Subscriptions/subscription';
import GameManagement from './components/GameManagement/ManageGames/GameManagement';
import NewGame from './components/GameManagement/ManageGames/NewGame';
import Draw from './components/GameManagement/ManageDraws/Draw';
import NewDraw from './components/GameManagement/ManageDraws/NewDraw';
import Payout from './components/GameManagement/ManagePayout/Payout';
import History1 from './components/GameManagement/DrawHistory/History1';
import PayoutHistory from './components/GameManagement/ManagePayout/PayoutHistory';
import Partners from './components/Partners/View Partners/Partners'
import AddPartners from './components/Partners/Add Partners/AddPartners'
import AdminMgmt from './components/AdminManagement/Settings'
//import Dashboard1 from './components/Subscribers/ui'
import onemillionbag from './components/Subscriptions/onemillionbag'
import BULKSMS from './components/SMS/sms'
//import PayPartner from './components/Partners/Pay Partners/PayPartner'
import GameInformation from './components/GameManagement/ManageGames/GameInformation';
import PartnerDetails from './components/Partners/View Partners/PartnerDetails';
import PayPartner from './components/Partners/View Partners/PayPartners';
import PayPopup from './components/Partners/View Partners/PaymentPopup';
import AdminCred from './components/Partners/View Partners/AdminCred';
//import Historymodal1 from './components/GameManagement/ManageGames/ActivePlayers';
import InstantCash from './components/GameManagement/ManageDraws/Instant';
//import ConfirmSchedule from './components/GameManagement/ManageDraws/Confirm';
import ScheduledDraws from './components/GameManagement/ManageDraws/ScheduledDraws';
import Info from './components/GameManagement/ManageDraws/Info';
import Pool from './components/GameManagement/ManageDraws/Pool';
import Pool1 from './components/GameManagement/ManageDraws/Pool1';
import Mark from './components/GameManagement/ManagePayout/Mark';
import Confirm from './components/GameManagement/ManagePayout/Payment';
import Cred from './components/GameManagement/ManagePayout/Cred';
import Success from './components/GameManagement/ManagePayout/Success';
import Receipt from './components/GameManagement/ManagePayout/Receipt';
import Paid from './components/Partners/View Partners/Paid';
import Add from './components/Partners/Add Partners/Add';
import Details from './components/Partners/Add Partners/Details';
import Added from './components/Partners/Add Partners/Added';
import Edit from './components/Partners/View Partners/EditPartner';
import Confirmation from './components/Partners/View Partners/Confirmation';
import Proceed from './components/Partners/View Partners/Proceed';
import PartnerHistory from './components/Partners/PartnerHistory/PartnerHistory';
import PartnerReceipt from './components/Partners/PartnerHistory/PartnerReceipt';
import AdminProfile from './components/AdminProfile/AdminProfile';
import Security from './components/AdminProfile/Security';
import PasswordChange from './components/AdminProfile/PasswordChange';

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <Route path="/login" component={Login} />
        <Route path="/ForgotPassword" component={ForgotPassword} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/Subscribers" component={Subscriber} />
        <Route path="/ResetPassword" component={ChangePassword} />
        <Route path="/SendCode" component={SendCode} />
        <Route path="/Subscriptions" component={Subscription} />
        <Route path="/ManageGame" component={GameManagement} />
        <Route path="/GameManagement/NewGame" component={NewGame} />
        <Route path="/ManageDraw" component={Draw} />
        <Route path="/NewDraw" component={NewDraw} />
        <Route path="/ManagePayout" component={Payout} />
        <Route path="/DrawHistory" component={History1} />
        <Route path="/PayoutHistory" component={PayoutHistory} />
        <Route path="/Partners" component={Partners} />
        <Route path="/AddPartners" component={AddPartners} />
        <Route path="/AdminSettings" component={AdminMgmt} />
        <Route path="/onemillionbag" component={onemillionbag} />
        <Route path="/sms" component={BULKSMS} />
        <Route path="/PayPartner" component={PayPartner} />
        <Route path="/PartnerDetails" component={PartnerDetails} />
        <Route path="/GameInformation" component={GameInformation} />
        <Route path="/PayPopup" component={PayPopup} />
        <Route path="/AdminCred" component={AdminCred} />
        <Route path="/InstantCash" component={InstantCash} />
        <Route path="/ScheduledDraws" component={ScheduledDraws} />
        <Route path="/Info" component={Info} />
        <Route path="/Pool" component={Pool} />
        <Route path="/Pool1" component={Pool1} />
        <Route path="/Mark" component={Mark} />
        <Route path="/Confirm" component={Confirm} />
        <Route path="/Cred" component={Cred} />
        <Route path="/Success" component={Success} />
        <Route path="/Receipt" component={Receipt} />
        <Route path="/Paid" component={Paid} />
        <Route path="/Add" component={Add} />
        <Route path="/Details" component={Details} />
        <Route path="/Added" component={Added} />
        <Route path="/Edit" component={Edit} />
        <Route path="/Confirmation" component={Confirmation} />
        <Route path="/Proceed" component={Proceed} />
        <Route path="/PartnerHistory" component={PartnerHistory} />
        <Route path="/PartnerReceipt" component={PartnerReceipt} />
        <Route path="/AdminProfile" component={AdminProfile} />
        <Route path="/Security" component={Security} />
        <Route path="/PasswordChange" component={PasswordChange} />


        {/* Add more routes here */}
      </Switch>
    </Router>
  );
};

export default App;
