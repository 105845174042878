import { FunctionComponent, useCallback } from "react";
import styles from "./Settings.module.css";

const AdminMgmt: FunctionComponent = () => {
  const onCellItemContainerClick = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer3Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer6Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer9Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer12Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer15Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer18Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer21Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer24Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer27Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  return (
    <div className={styles.adminMgmt}>
      <div className={styles.administratorManagementParent}>
        <div
          className={styles.administratorManagement}
        >{`ADMINISTRATOR MANAGEMENT `}</div>
        <div className={styles.administratorsParent}>
          <div className={styles.administrators}>Administrators</div>
          <div className={styles.component10Parent}>
            <img
              className={styles.component10Icon}
              alt=""
              src="/assets/Component 10.svg"
            />
            <div className={styles.hofAdamsParent}>
              <div className={styles.hofAdams}>Hof Adams</div>
              <div className={styles.perfAdams}>Perf Adams</div>
            </div>
            <img
              className={styles.component10Icon}
              alt=""
              src="/assets/Component 9.svg"
            />
          </div>
          <div className={styles.loginSessionsParent}>
            <div className={styles.loginSessions}>Login Sessions</div>
            <div className={styles.frameParent}>
              <div className={styles.frameGroup}>
                <div className={styles.frameWrapper}>
                  <div className={styles.frameContainer}>
                    <div className={styles.leftIconParent}>
                      <img
                        className={styles.leftIcon}
                        alt=""
                        src="/assets/search-02.svg"
                      />
                      <div className={styles.text}>
                        <div className={styles.textValue}>Search</div>
                      </div>
                    </div>
                    <img
                      className={styles.rightIcon}
                      alt=""
                      src="/righticon@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.smallButtonParent}>
                  <div className={styles.smallButton}>
                    <div className={styles.subscribers}>Today</div>
                    <img
                      className={styles.smallButtonChild}
                      alt=""
                      src="/group-2@2x.png"
                    />
                  </div>
                  <div className={styles.smallButton1}>
                    <div className={styles.randomemailmailcom}>Past 7 Days</div>
                    <img
                      className={styles.smallButtonChild}
                      alt=""
                      src="/group-1@2x.png"
                    />
                  </div>
                  <div className={styles.smallButton1}>
                    <div className={styles.randomemailmailcom}>
                      Past 30 Days
                    </div>
                    <img
                      className={styles.smallButtonChild}
                      alt=""
                      src="/group-1@2x.png"
                    />
                  </div>
                  <div className={styles.smallButton1}>
                    <div className={styles.randomemailmailcom}>Past 1 Year</div>
                    <img
                      className={styles.smallButtonChild}
                      alt=""
                      src="/group-1@2x.png"
                    />
                  </div>
                  <div className={styles.smallButton1}>
                    <div className={styles.randomemailmailcom}>All Time</div>
                    <img
                      className={styles.smallButtonChild}
                      alt=""
                      src="/group-1@2x.png"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.table}>
                <div className={styles.column}>
                  <div
                    className={styles.cellItem}
                    onClick={onCellItemContainerClick}
                  >
                    <div className={styles.am}>27/01/2023, 9:00AM</div>
                  </div>
                  <div className={styles.cellItem1}>
                    <div className={styles.am}>2</div>
                  </div>
                  <div className={styles.cellItem1}>
                    <div className={styles.am}>2</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <div
                    className={styles.cellItem}
                    onClick={onCellItemContainer3Click}
                  >
                    <div className={styles.am}>27/01/2023, 9:00AM</div>
                  </div>
                  <div className={styles.cellItem1}>
                    <div className={styles.am}>2</div>
                  </div>
                  <div className={styles.cellItem1}>
                    <div className={styles.am}>2</div>
                  </div>
                </div>
                <div className={styles.column2}>
                  <div
                    className={styles.cellItem}
                    onClick={onCellItemContainer6Click}
                  >
                    <div className={styles.am}>27/01/2023, 9:00AM</div>
                  </div>
                  <div className={styles.cellItem1}>
                    <div className={styles.am}>2</div>
                  </div>
                  <div className={styles.cellItem1}>
                    <div className={styles.am}>2</div>
                  </div>
                </div>
                <div className={styles.column3}>
                  <div
                    className={styles.cellItem}
                    onClick={onCellItemContainer9Click}
                  >
                    <div className={styles.am}>27/01/2023, 9:00AM</div>
                  </div>
                  <div className={styles.cellItem1}>
                    <div className={styles.am}>2</div>
                  </div>
                  <div className={styles.cellItem1}>
                    <div className={styles.am}>2</div>
                  </div>
                </div>
                <div className={styles.column4}>
                  <div
                    className={styles.cellItem}
                    onClick={onCellItemContainer12Click}
                  >
                    <div className={styles.am}>27/01/2023, 9:00AM</div>
                  </div>
                  <div className={styles.cellItem1}>
                    <div className={styles.am}>2</div>
                  </div>
                  <div className={styles.cellItem1}>
                    <div className={styles.am}>2</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.frameParent1}>
              <div className={styles.scheduleContentParent}>
                <div className={styles.scheduleContent}>
                  <div className={styles.subscribers}>Admin Email</div>
                  <div className={styles.time}>
                    <div className={styles.time1}>
                      <div className={styles.randomemailmailcom}>
                        randomemail@mail.com
                      </div>
                    </div>
                  </div>
                </div>
                <img className={styles.copyIcon} alt="" src="/assets/copy.svg" />
              </div>
              <div className={styles.scheduleContentParent}>
                <div className={styles.scheduleContent}>
                  <div className={styles.subscribers}>Admin Password</div>
                  <div className={styles.time2}>
                    <div className={styles.time1}>
                      <div className={styles.oodgmkrealdmfvald}>
                        oodgmkrealdmfvald
                      </div>
                    </div>
                  </div>
                </div>
                <img className={styles.copyIcon} alt="" src="/assets/copy.svg" />
              </div>
            </div>
            <div className={styles.inputFieldParent}>
              <div className={styles.inputField}>
                <img className={styles.editIcon} alt="" src="/assets/edit.svg" />
                <div className={styles.oodgmkrealdmfvald}>Disable Admin</div>
              </div>
              <div className={styles.inputField1}>
                <img className={styles.editIcon} alt="" src="/assets/trash-2.svg" />
                <div className={styles.oodgmkrealdmfvald}>Delete Admin</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameWrapper1}>
          <div className={styles.frameParent2}>
            <div className={styles.inviteAdministratorParent}>
              <div
                className={styles.oodgmkrealdmfvald}
              >{`Invite Administrator `}</div>
              <div className={styles.frameParent3}>
          
  <input
    type="text"
    className={styles.separateMultipleEmailsWithWrapper} // Replace with your CSS class for the input field
    placeholder="Separate Multiple Emails with a comma"
  />

                <div className={styles.sendInviteWrapper}>
                  <div className={styles.sendInvite}>Send Invite</div>
                </div>
              </div>
            </div>
            <div className={styles.table1}>
              <div className={styles.column5}>
                <div className={styles.cellHeader}>
                  <div className={styles.am}>S/N</div>
                </div>
                <div
                  className={styles.cellItem}
                  onClick={onCellItemContainer15Click}
                >
                  <div className={styles.am}>1</div>
                </div>
                <div className={styles.cellItem1}>
                  <div className={styles.am}>2</div>
                </div>
                <div className={styles.cellItem1}>
                  <div className={styles.am}>3</div>
                </div>
              </div>
              <div className={styles.column6}>
                <div className={styles.cellHeader1}>
                  <div className={styles.am}>Invitation Sent To</div>
                </div>
                <div
                  className={styles.cellItem18}
                  onClick={onCellItemContainer18Click}
                >
                  <div className={styles.oodgmkrealdmfvald}>
                    randomperson@mail.com
                  </div>
                </div>
                <div className={styles.cellItem19}>
                  <div className={styles.text1}>
                    <div className={styles.textValue}>
                      randomperson2@mail.com
                    </div>
                  </div>
                </div>
                <div className={styles.cellItem19}>
                  <div className={styles.text1}>
                    <div className={styles.textValue}>
                      randomperson3@mail.com
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.column7}>
                <div className={styles.cellHeader1}>
                  <div className={styles.am}>{`Invitation Sent On `}</div>
                </div>
                <div
                  className={styles.cellItem18}
                  onClick={onCellItemContainer21Click}
                >
                  <div className={styles.oodgmkrealdmfvald}>
                    <p className={styles.p}>27/01/20023</p>
                    <p className={styles.p}>9:00pm</p>
                  </div>
                </div>
                <div className={styles.cellItem19}>
                  <div className={styles.text1}>
                    <div className={styles.pm2}>
                      <p className={styles.p}>27/01/20023</p>
                      <p className={styles.p}>9:00pm</p>
                    </div>
                  </div>
                </div>
                <div className={styles.cellItem19}>
                  <div className={styles.text1}>
                    <div className={styles.pm2}>
                      <p className={styles.p}>27/01/20023</p>
                      <p className={styles.p}>9:00pm</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.column8}>
                <div className={styles.cellHeader1}>
                  <div className={styles.am}>Invitation Status</div>
                </div>
                <div
                  className={styles.cellItem18}
                  onClick={onCellItemContainer24Click}
                >
                  <div className={styles.oodgmkrealdmfvald}>Pending</div>
                </div>
                <div className={styles.cellItem19}>
                  <div className={styles.text1}>
                    <div className={styles.textValue}>Rejected</div>
                  </div>
                </div>
                <div className={styles.cellItem19}>
                  <div className={styles.text1}>
                    <div className={styles.textValue}>Accepted</div>
                  </div>
                </div>
              </div>
              <div className={styles.column3}>
                <div className={styles.cellHeader}>
                  <div className={styles.moreActions}>More Actions</div>
                </div>
                <div
                  className={styles.cellItem}
                  onClick={onCellItemContainer27Click}
                >
                  <div className={styles.moreWrapper}>
                    <img
                      className={styles.editIcon}
                      alt=""
                      src="/assets/more.svg"
                    />
                  </div>
                </div>
                <div className={styles.cellItem1}>
                  <div className={styles.moreWrapper}>
                    <img
                      className={styles.editIcon}
                      alt=""
                      src="/assets/more.svg"
                    />
                  </div>
                </div>
                <div className={styles.cellItem1}>
                  <div className={styles.moreWrapper}>
                    <img
                      className={styles.editIcon}
                      alt=""
                      src="/assets/more.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameChild} />
        <div className={styles.frameItem} />
        <div className={styles.frameInner} />
      </div>
      <div className={styles.adminMgmtChild} />
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.navbarInner}>
          <div className={styles.frameWrapper2}>
            <div className={styles.component4Parent}>
              <a href="dashboard" >
              <div className={styles.component4}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/dashboard.svg"
                />
                <div className={styles.oodgmkrealdmfvald}>Dashboard</div>
                <img
                  className={styles.dropdownDownIcon}
                  alt=""
                  src="/assets/dropdown-down.svg"
                />
              </div>
              </a>
              <a href="Subscribers" >
              <div className={styles.component4}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/user-02.svg"
                />
                <div className={styles.oodgmkrealdmfvald}>Subscribers</div>
                <img
                  className={styles.dropdownDownIcon}
                  alt=""
                  src="/assets/dropdown-down.svg"
                />
              </div>
              </a>
              <a href="Subscriptions/subscription" >
              <div className={styles.component4}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/subscription.svg"
                />
                <div className={styles.oodgmkrealdmfvald}>Subscriptions</div>
                <img
                  className={styles.dropdownDownIcon}
                  alt=""
                  src="/assets/dropdown-down.svg"
                />
              </div>
              </a>
              <a href="ManageGame" >
              <div className={styles.gm}>
                <div className={styles.component4}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/Component 5.svg"
                  />
                  <div className={styles.oodgmkrealdmfvald}>
                    Game Management
                  </div>
                  <img
                    className={styles.editIcon}
                    alt=""
                    src="/assets/dropdown-down.svg"
                  />
                </div>
              </div>
              </a>
              <a href="/partners">
              <div className={styles.time1}>
                <div className={styles.component4}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/partnership.svg"
                  />
                  <div className={styles.oodgmkrealdmfvald}>Partners</div>
                  <img
                    className={styles.editIcon}
                    alt=""
                    src="/assets/dropdown-down.svg"
                  />
                </div>
              </div>
              </a>
              <div className={styles.component17}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/admin.svg"
                />
                <div className={styles.subscribers}>Admin Management</div>
                <img
                  className={styles.dropdownDownIcon}
                  alt=""
                  src="/dropdowndown@2x.png"
                />
              </div>
              <a href="/sms">
  <div className={styles.component4}>
    <img
      className={styles.dashboardIcon}
      alt=""
      src="/assets/message.svg"
    />
    <div className={styles.oodgmkrealdmfvald}>SMS</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/assets/dropdowndown6.svg"
    />
  </div>
</a>

            </div>
          </div>
        </div>
        <a href="/AdminProfile">
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.wrapperRectangle342Parent}>
            <div className={styles.wrapperRectangle342}>
              <img
                className={styles.wrapperRectangle342Child}
                alt=""
                src="/assets/Rectangle 342.png"
              />
            </div>
            <div className={styles.adminOfficerParent}>
              <div className={styles.randomemailmailcom}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img
            className={styles.setting02Icon}
            alt=""
            src="/assets/setting-02.svg"
          />
        </div>
        </a>
      </div>
    </div>
  );
};

export default AdminMgmt;
