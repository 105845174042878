import { FunctionComponent, useCallback } from "react";
import styles from "./PartnerHistory.module.css";
import {Link} from 'react-router-dom';

const PartnerHistory: FunctionComponent = () => {
  const onCellItemContainerClick = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer10Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer20Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer30Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer40Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onCellItemContainer60Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  return (
    <div className={styles.payoutHistory}>
      <div className={styles.partnersParent}>
        <div className={styles.partners}>PARTNERS</div>
        <div className={styles.partnersPayoutHistory}>
          Partners Payout History
        </div>
        <div className={styles.frameChild} />
        <div className={styles.previousParent}>
          <div className={styles.previous}>Previous</div>
          <div className={styles.next}>Next</div>
          <div className={styles.last}>Last</div>
        </div>
        <div className={styles.frameWrapper}>
        <input
    type="text"
    className={styles.frameParent} // Replace with your CSS class for the input field
    placeholder="Search"
  />
        </div>
        <div className={styles.frameItem} />
        <div className={styles.table}>
          <div className={styles.column}>
            <div className={styles.cellHeader}>
              <div className={styles.sn}>S/N</div>
            </div>
            <div className={styles.cellItem} onClick={onCellItemContainerClick}>
              <div className={styles.sn}>1</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>2</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>3</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>4</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>5</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>6</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>7</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>8</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>9</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>10</div>
            </div>
          </div>
          <div className={styles.column1}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Company Name</div>
            </div>
            <div
              className={styles.cellItem10}
              onClick={onCellItemContainer10Click}
            >
              <div className={styles.previous}>ExxonMobil</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>ExxonMobil</div>
              </div>
            </div>
          </div>
          <div className={styles.column2}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Date and time</div>
            </div>
            <div
              className={styles.cellItem10}
              onClick={onCellItemContainer20Click}
            >
              <div className={styles.previous}>
                <p className={styles.p}>27/01/20023</p>
                <p className={styles.p}>9:00pm</p>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.pm2}>
                  <p className={styles.p}>27/01/20023</p>
                  <p className={styles.p}>9:00pm</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.column3}>
            <div className={styles.cellHeader1}>
              <div className={styles.amountSent}>Amount Sent</div>
            </div>
            <div
              className={styles.cellItem30}
              onClick={onCellItemContainer30Click}
            >
              <div className={styles.previous}>₦500,000,00</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text1}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
          </div>
          <div className={styles.column4}>
            <div className={styles.cellHeader4}>
              <div className={styles.previous}>Bank Account</div>
            </div>
            <div
              className={styles.cellItem}
              onClick={onCellItemContainer40Click}
            >
              <div className={styles.sn}>Access Bank - 0000000001</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
          </div>
          <div className={styles.column5}>
            <div className={styles.cellHeader5}>
              <div className={styles.previous}>Receipt</div>
            </div>
            <div className={styles.cellItem1}>
                <Link to="/PartnerReceipt">
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
              </Link>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.smallButton}>
                <div className={styles.subscribers}>View receipt</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1.svg"
                />
              </div>
            </div>
          </div>
          <div className={styles.column6}>
            <div className={styles.cellHeader6}>
              <div className={styles.previous}>State</div>
            </div>
            <div
              className={styles.cellItem}
              onClick={onCellItemContainer60Click}
            >
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
            <div className={styles.cellItem1}>
              <div className={styles.previous}>Successful</div>
            </div>
          </div>
        </div>
        <div className={styles.filterParent}>
          <img className={styles.filterIcon} alt="" src="/assets/filter.svg" />
          <div className={styles.subscribers}>Filter by</div>
          <img
            className={styles.chevronArrowDownIcon}
            alt=""
            src="/assets/chevron-arrow-down.svg"
          />
        </div>
      </div>
      <div className={styles.payoutHistoryChild} />
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
            <a href="/dashboard">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/dashboard.svg"
            />
            <div className={styles.previous}>Dashboard</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
          </a>
          <a href="/subscribers">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/user-02.svg"
            />
            <div className={styles.previous}>Subscribers</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
          </a>
          <a href="/subscriptions">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.previous}>Subscriptions</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
          </a>
          <div className={styles.gm}>
            <a href="/ManageGames">
            <div className={styles.component4}>
              <img
                className={styles.chevronArrowDownIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.previous}>Game Management</div>
              <img className={styles.filterIcon} alt="" src="/assets/dropdown-up.svg" />
            </div>
            </a>
          </div>
          <div className={styles.component15}>
            <div className={styles.component14}>
              <img
                className={styles.chevronArrowDownIcon}
                alt=""
                src="/assets/partnership.svg"
              />
              <div className={styles.dashboard4}>Partners</div>
              <img className={styles.filterIcon} alt="" src="/assets/dropdown-up.svg" />
            </div>
            <div className={styles.subComponents}>
                <a href="/partners">
              <div className={styles.subNavComponents}>
                <div className={styles.checklistParent}>
                  <img
                    className={styles.leftIcon}
                    alt=""
                    src="/assets/checklist.svg"
                  />
                  <div className={styles.previous}>View All Partners</div>
                </div>
              </div>
              </a>
              <a href="/AddPartners">
              <div className={styles.subNavComponents}>
                <div className={styles.checklistParent}>
                  <img
                    className={styles.chevronArrowDownIcon}
                    alt=""
                    src="/assets/add-user.svg"
                  />
                  <div className={styles.previous}>Add Partner</div>
                </div>
              </div>
              </a>
              <div className={styles.subNavComponents2}>
                <img
                  className={styles.chevronArrowDownIcon}
                  alt=""
                  src="/assets/Component 6.svg"
                />
                <div className={styles.dashboard7}>Pay Partners</div>
              </div>
            </div>
          </div>
          <a href="/AdminSettings">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/admin.svg"
            />
            <div className={styles.previous}>Admin Management</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/assets/dropdown-down.svg"
            />
          </div>
          </a>
          <a href="/sms">
          <div className={styles.component4}>
            <img
              className={styles.chevronArrowDownIcon}
              alt=""
              src="/assets/message.svg"
            />
            <div className={styles.previous}>SMS</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown.svg"
            />
          </div>
          </a>
        </div>
        <a href="/AdminProfile">
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameInner}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.subscribers}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img className={styles.setting02Icon} alt="" src="/assets/setting-02.svg" />
        </div>
        </a>
      </div>
    </div>
  );
};

export default PartnerHistory;
