import { FunctionComponent, useCallback, useState, useRef, useEffect } from "react";
import styles from "./GameManagement.module.css";
//import './globalGM.css'
import { Link } from 'react-router-dom';

const GameManagement: FunctionComponent = () => {
  const onInputFieldContainerClick = useCallback(() => {
    // Please sync "game management" to the project
  }, []);

  const onColumnContainer7Click = useCallback(() => {
    // Please sync "game information" to the project
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
const sliderRef = useRef<HTMLDivElement>(null);
const slideWidth = 33.33; // Adjust this value to show three slides in the viewport
const totalSlides = 5; // Define the total number of slides

const nextSlide = () => {
  setCurrentIndex((prevIndex) => {
    const nextIndex = prevIndex + 1;
    return nextIndex > totalSlides - 3 ? 0 : nextIndex;
  });
};

const prevSlide = () => {
  setCurrentIndex((prevIndex) => {
    const nextIndex = prevIndex - 1;
    return nextIndex < 0 ? totalSlides - 3 : nextIndex;
  });
};

useEffect(() => {
  if (sliderRef.current) {
    const translateValue = -currentIndex * slideWidth;
    sliderRef.current.style.transform = `translateX(${translateValue}%)`;
  }
}, [currentIndex, slideWidth]);



  return (
    <div className={styles.gameManagement}>
      <div className={styles.gameManagementParent}>
        <div className={styles.gameManagement1}>{`GAME MANAGEMENT `}</div>
        <div className={styles.carousel}>
          <img
            className={styles.component9variant3Icon}
            alt=""
            src="/assets/chevron-arrow-left.svg"
            onClick={prevSlide}
          />
          <div className="slider">
          <div className="slider-container"
      style={{ overflow: 'hidden', width: '1000px' }} 
    >
          <div className="slides" ref={sliderRef} style={{ display: 'flex', transition: 'transform 0.5s ease' }}>
          {[...Array(totalSlides)].map((_, index) => (
            <div key={index} style={{ width: `${slideWidth}%`, flexShrink: 0 }}>

          <div className={styles.carouselLayout}>
            <div className={styles.carousel1}>
              {/* Your specific content for each slide */}
            {index === 0 && (
              <>

              <div className={styles.dashboardItems}>
                <div className={styles.frameParent}>
                  <div className={styles.instant34Parent}>
                    <div className={styles.fastCash}>Instant 3/4</div>
                    <div className={styles.numberOfActiveGamesParent}>
                      <div className={styles.numberOfActive}>
                        Number of active games
                      </div>
                      <div className={styles.div}>20,000</div>
                    </div>
                  </div>
                  <img
                    className={styles.component5Icon}
                    alt=""
                    src="/assets/user-01.svg"
                  />
                </div>
              </div>
              </>
              )}
              {index === 1 && (
              <>

              <div className={styles.dashboardItems}>
                <div className={styles.frameParent}>
                  <div className={styles.instant34Parent}>
                    <div className={styles.fastCash}>FAST CASH</div>
                    <div className={styles.numberOfActiveGamesParent}>
                      <div className={styles.numberOfActive}>
                        Number of active games
                      </div>
                      <div className={styles.div}>20,000</div>
                    </div>
                  </div>
                  <img
                    className={styles.component5Icon}
                    alt=""
                    src="/assets/user-01.svg"
                  />
                </div>
              </div>
              </>
              )}
              {index === 2 && (
              <>

              <div className={styles.dashboardItems}>
                <div className={styles.frameParent}>
                  <div className={styles.instant34Parent}>
                    <div className={styles.fastCash}>ONE million bag</div>
                    <div className={styles.numberOfActiveGamesParent}>
                      <div className={styles.numberOfActive}>
                        Number of active games
                      </div>
                      <div className={styles.div}>20,000</div>
                    </div>
                  </div>
                  <img
                    className={styles.component5Icon}
                    alt=""
                    src="/assets/user-01.svg"
                  />
                </div>
              </div>
              </>
              )}
              {index === 3 && (
              <>

              <div className={styles.dashboardItems}>
                <div className={styles.frameParent}>
                  <div className={styles.instant34Parent}>
                    <div className={styles.fastCash}>CHOPCHOP INSTANT WIN</div>
                    <div className={styles.numberOfActiveGamesParent}>
                      <div className={styles.numberOfActive}>
                        Number of active games
                      </div>
                      <div className={styles.div}>20,000</div>
                    </div>
                  </div>
                  <img
                    className={styles.component5Icon}
                    alt=""
                    src="/assets/user-01.svg"
                  />
                </div>
              </div>
              </>
              )}
              {index === 4 && (
              <>

              <div className={styles.dashboardItems}>
                <div className={styles.frameParent}>
                  <div className={styles.instant34Parent}>
                    <div className={styles.fastCash}>mega money</div>
                    <div className={styles.numberOfActiveGamesParent}>
                      <div className={styles.numberOfActive}>
                        Number of active games
                      </div>
                      <div className={styles.div}>20,000</div>
                    </div>
                  </div>
                  <img
                    className={styles.component5Icon}
                    alt=""
                    src="/assets/user-01.svg"
                  />
                </div>
              </div>
              </>
              )}

            </div>
          </div>

          </div>
          ))}
          </div>
          </div>
          </div>

          <img
            className={styles.component9variant3Icon}
            alt=""
            src="/assets/chevron-arrow-right.svg"
            onClick={nextSlide}
          />
        </div>
        <div className={styles.inputField} onClick={onInputFieldContainerClick}>
          <img className={styles.editIcon} alt="" src="/edit.svg" />
          <Link to="/GameManagement/NewGame">
          <div className={styles.numberOfActive} style={{ color: 'white' }}>Start a New Game</div>
          </Link>
          <img className={styles.inputFieldChild} alt="" src="/group-1.svg" />
        </div>
        <div className={styles.component10Parent}>
          <img
            className={styles.component9variant3Icon}
            alt=""
            src="/assets/Component 10.svg"
          />
          <div className={styles.smallButtonParent}>
            <div className={styles.smallButton}>
              <div className={styles.subscribers}>All</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-2.svg"
              />
            </div>
            <div className={styles.smallButton1}>
              <div className={styles.filterBy}>One Million Bag</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-11.svg"
              />
            </div>
            <div className={styles.smallButton1}>
              <div className={styles.filterBy}>ChopChop Instant Win</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-12.svg"
              />
            </div>
            <div className={styles.smallButton1}>
              <div className={styles.filterBy}>Mega Money</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-13.svg"
              />
            </div>
            <div className={styles.smallButton1}>
              <div className={styles.filterBy}>Instants 3/4</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-14.svg"
              />
            </div>
            <div className={styles.smallButton1}>
              <div className={styles.filterBy}>Fast Cash</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-15.svg"
              />
            </div>
          </div>
          <img
            className={styles.component9variant3Icon}
            alt=""
            src="/assets/Component 9.svg"
          />
        </div>
        <div className={styles.frameParent2}>
          <div className={styles.frameParent3}>
            <div>
            <input
    type="text"
    className={styles.frameParent4} // Replace with your CSS class for the input field
    placeholder="Search"
  />
            </div>
            <div className={styles.filterParent}>
              <img className={styles.filterIcon} alt="" src="/assets/filter.svg" />
              <div className={styles.filterBy}>Filter by</div>
              <img
                className={styles.chevronArrowDownIcon}
                alt=""
                src="/assets/chevron-arrow-down.svg"
              />
            </div>
          </div>
          <div className={styles.showing12Of10000000EntriWrapper}>
            <div className={styles.numberOfActive}>
              Showing 12 of 10,000,000 entries
            </div>
          </div>
        </div>
        <div className={styles.previousParent}>
          <div className={styles.numberOfActive}>Previous</div>
          <div className={styles.next}>Next</div>
          <div className={styles.last}>Last</div>
        </div>
        <div className={styles.table}>
          <div className={styles.column}>
            <div className={styles.cellHeader}>
              <div className={styles.sn}>S/N</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>1</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>2</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>3</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>4</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>5</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>6</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>7</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>8</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>9</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>10</div>
            </div>
          </div>
          <div className={styles.column1}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Game</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>One Million Bag</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>Instant 3/4</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>Fast Cash</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>Mega Money</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>ChopChop Instant Cash</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>Instant 3/4</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>Mega Money</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>ChopChop Instant Cash</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>Fast Cash</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>One Million Bag</div>
              </div>
            </div>
          </div>
          <div className={styles.column2}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Repeat</div>
            </div>
            <div className={styles.cellItem20}>
              <div className={styles.numberOfActive}>Daily</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>Weekly</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>30%</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>10%</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>30%</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>40%</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>30%</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>30%</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>30%</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>30%</div>
              </div>
            </div>
          </div>
          <div className={styles.column2}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Winning %</div>
            </div>
            <div className={styles.cellItem20}>
              <div className={styles.numberOfActive}>20%</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>40%</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>30%</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>10%</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>30%</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>40%</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>30%</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>30%</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>30%</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.textValue}>30%</div>
              </div>
            </div>
          </div>
          <div className={styles.column4}>
            <div className={styles.cellHeader}>
              <div className={styles.sn}>Date/time initiated</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>
                <p className={styles.p}>12/01/2023</p>
                <p className={styles.p}>9:23pm</p>
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>
                <p className={styles.p}>12/01/2023</p>
                <p className={styles.p}>9:23pm</p>
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>
                <p className={styles.p}>12/01/2023</p>
                <p className={styles.p}>9:23pm</p>
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>
                <p className={styles.p}>12/01/2023</p>
                <p className={styles.p}>9:23pm</p>
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>
                <p className={styles.p}>12/01/2023</p>
                <p className={styles.p}>9:23pm</p>
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>
                <p className={styles.p}>12/01/2023</p>
                <p className={styles.p}>9:23pm</p>
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>
                <p className={styles.p}>12/01/2023</p>
                <p className={styles.p}>9:23pm</p>
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>
                <p className={styles.p}>12/01/2023</p>
                <p className={styles.p}>9:23pm</p>
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>
                <p className={styles.p}>12/01/2023</p>
                <p className={styles.p}>9:23pm</p>
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>
                <p className={styles.p}>12/01/2023</p>
                <p className={styles.p}>9:23pm</p>
              </div>
            </div>
          </div>
          <div className={styles.column5}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Active players</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>50</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>50</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>50</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>50</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>50</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>50</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>50</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>50</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>50</div>
              </div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.text1}>
                <div className={styles.div15}>50</div>
              </div>
            </div>
          </div>
          <div className={styles.column6}>
            <div className={styles.cellHeader6}>
              <div className={styles.sn}>Status</div>
            </div>
            <div className={styles.cellItem60}>
              <div className={styles.numberOfActive}>In progress</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.numberOfActive}>Completed</div>
            </div>
            <div className={styles.cellItem62}>
              <div className={styles.numberOfActive}>Terminated</div>
            </div>
            <div className={styles.cellItem63}>
              <div className={styles.numberOfActive}>Paused</div>
            </div>
            <div className={styles.cellItem60}>
              <div className={styles.numberOfActive}>In progress</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.numberOfActive}>Completed</div>
            </div>
            <div className={styles.cellItem66}>
              <div className={styles.numberOfActive}>At full capacity</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.numberOfActive}>Completed</div>
            </div>
            <div className={styles.cellItem66}>
              <div className={styles.numberOfActive}>At full capacity</div>
            </div>
            <div className={styles.cellItem60}>
              <div className={styles.numberOfActive}>In progress</div>
            </div>
          </div>
          <div className={styles.column7} onClick={onColumnContainer7Click}>
            <div className={styles.cellHeader7}>
              <div className={styles.numberOfActive}>Advanced Info</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton1}>
              <Link to="/gameinformation">
  <div className={styles.filterBy}>More info</div>
</Link>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-16.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton1}>
                <div className={styles.filterBy}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-17.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton1}>
                <div className={styles.filterBy}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-18.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton1}>
                <div className={styles.filterBy}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-19.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton1}>
                <div className={styles.filterBy}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-110.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton1}>
                <div className={styles.filterBy}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-111.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton1}>
                <div className={styles.filterBy}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-112.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton1}>
                <div className={styles.filterBy}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-113.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton1}>
                <div className={styles.filterBy}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-114.svg"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton1}>
                <div className={styles.filterBy}>More info</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-115.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.gameManagementChild} />
      <div className={styles.gameManagementItem} />
      <div className={styles.gameManagementInner} />
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
        <a href="/dashboard">
  <div className={styles.component4}>
    <img
      className={styles.chevronArrowDownIcon}
      alt=""
      src="/assets/dashboard.svg"
    />
    <div className={styles.numberOfActive}>Dashboard</div>
    <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
  </div>
</a>

<a href="/subscribers">
  <div className={styles.component4}>
    <img
      className={styles.chevronArrowDownIcon}
      alt=""
      src="/assets/user-02.svg"
    />
    <div className={styles.numberOfActive}>Subscribers</div>
    <img
      className={styles.editIcon}
      alt=""
      src="/dropdowndown1.svg"
    />
  </div>
</a>

<a href="/subscriptions">
  <div className={styles.component4}>
    <img
      className={styles.chevronArrowDownIcon}
      alt=""
      src="/assets/subscription.svg"
    />
    <div className={styles.numberOfActive}>Subscriptions</div>
    <img
      className={styles.editIcon}
      alt=""
      src="/dropdowndown2.svg"
    />
  </div>
</a>

          <div className={styles.gm}>
            <div className={styles.component6}>
              <img
                className={styles.chevronArrowDownIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.subscribers}>Game Management</div>
              <img
                className={styles.editIcon}
                alt=""
                src="/dropdowndown3.svg"
              />
            </div>
            <div className={styles.subNav}>
              <div className={styles.subNavComponents}>
                <img
                  className={styles.chevronArrowDownIcon}
                  alt=""
                  src="/assets/Component 5.svg"
                />
                <div className={styles.dashboard4}>Manage Games</div>
              </div>
              <div className={styles.subNavComponents1}>
              <a href="/ManageDraw">
  <div className={styles.shuffleParent}>
    <img
      className={styles.chevronArrowDownIcon}
      alt=""
      src="/assets/shuffle.svg"
    />
    <div className={styles.numberOfActive}>Manage Draws</div>
  </div>
</a>

              </div>
              <div className={styles.subNavComponents1}>
              <a href="/DrawHistory">
  <div className={styles.shuffleParent}>
    <img
      className={styles.chevronArrowDownIcon}
      alt=""
      src="/assets/history.svg"
    />
    <div className={styles.numberOfActive}>Draw History</div>
  </div>
</a>

              </div>
              <div className={styles.subNavComponents1}>
              <a href="/ManagePayout">
  <div className={styles.shuffleParent}>
    <img
      className={styles.chevronArrowDownIcon}
      alt=""
      src="/assets/Component 6.svg"
    />
    <div className={styles.numberOfActive}>Manage Payout</div>
  </div>
</a>

              </div>
            </div>
          </div>
          <a href="/partners">
  <div className={styles.component4}>
    <img
      className={styles.chevronArrowDownIcon}
      alt=""
      src="/assets/partnership.svg"
    />
    <div className={styles.numberOfActive}>Partners</div>
    <img
      className={styles.filterIcon}
      alt=""
      src="/assets/dropdown-down.svg"
    />
  </div>
</a>

<a href="/AdminManagement">
  <div className={styles.component4}>
    <img
      className={styles.chevronArrowDownIcon}
      alt=""
      src="/assets/admin.svg"
    />
    <div className={styles.numberOfActive}>Admin Management</div>
    <img
      className={styles.editIcon}
      alt=""
      src="/dropdowndown5.svg"
    />
  </div>
</a>

<a href="/sms">
  <div className={styles.component4}>
    <img
      className={styles.chevronArrowDownIcon}
      alt=""
      src="/assets/message.svg"
    />
    <div className={styles.numberOfActive}>SMS</div>
    <img
      className={styles.editIcon}
      alt=""
      src="/dropdowndown6.svg"
    />
  </div>
</a>

        </div>
        <a href="/AdminProfile">
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameChild}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.filterBy}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img className={styles.setting02Icon} alt="" src="/assets/setting-02.svg" />
        </div>
        </a>
      </div>
    </div>
  );
};

export default GameManagement;
