import { FunctionComponent } from "react";
import { Link } from 'react-router-dom';
import styles from "./AdminProfile.module.css";

const AdminProfile: FunctionComponent = () => {
  return (
    <div className={styles.settings}>
      <div className={styles.profileSettingsParent}>
        <div className={styles.profileSettings}>PROFILE SETTINGS</div>
        <div className={styles.smallButtonParent}>
          <div className={styles.smallButton}>
            <div className={styles.subscribers}>General Settings</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-2.svg"
            />
          </div>
          <Link to="/Security">
          <div className={styles.smallButton1}>
            <div className={styles.adminOfficer}>Security Settings</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-1.svg"
            />
          </div>
          </Link>
        </div>
        <div className={styles.inputFielsParent}>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue}>
              <div className={styles.firstName}>First Name</div>
            </div>
            <div className={styles.inputField}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text}>
                  <div className={styles.gillbert}>Gillbert</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue1}>
              <div className={styles.firstName}>Middle Name(Optional)</div>
            </div>
            <div className={styles.inputField1}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text1}>
                  <div className={styles.gillbert}>Enter admin username</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue2}>
              <div className={styles.firstName}>Last Name</div>
            </div>
            <div className={styles.inputField}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text2}>
                  <div className={styles.gillbert}>Adams</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue3}>
              <div className={styles.firstName}>{`Position `}</div>
            </div>
            <div className={styles.inputField3}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text3}>
                  <div className={styles.gillbert}>Administrator</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.inputFieldParent}>
          <div className={styles.inputField4}>
            <img className={styles.editIcon} alt="" src="/edit.svg" />
            <div className={styles.insertValue}>Apply Changes</div>
          </div>
          <div className={styles.inputField5}>
            <img className={styles.editIcon} alt="" src="/edit.svg" />
            <div className={styles.insertValue}>{`Cancel `}</div>
          </div>
        </div>
      </div>
      <div className={styles.settingsChild} />
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.navbarInner}>
          <div className={styles.frameWrapper}>
            <div className={styles.component4Parent}>
                <a href="/dashboard">
              <div className={styles.component4}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/dashboard.svg"
                />
                <div className={styles.insertValue}>Dashboard</div>
                <img
                  className={styles.editIcon}
                  alt=""
                  src="/dropdowndown.svg"
                />
              </div>
              </a>
              <a href="/subscribers">
              <div className={styles.component4}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/user-02.svg"
                />
                <div className={styles.insertValue}>Subscribers</div>
                <img
                  className={styles.editIcon}
                  alt=""
                  src="/dropdowndown.svg"
                />
              </div>
              </a>
              <a href="/subscriptions">
              <div className={styles.component4}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/subscription.svg"
                />
                <div className={styles.insertValue}>Subscriptions</div>
                <img
                  className={styles.editIcon}
                  alt=""
                  src="/dropdowndown.svg"
                />
              </div>
              </a>
              <div className={styles.gm}>
                <a href="/ManageGames">
                <div className={styles.component4}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/Component 5.svg"
                  />
                  <div className={styles.insertValue}>Game Management</div>
                  <img
                    className={styles.dropdownDownIcon3}
                    alt=""
                    src="/assets/dropdown-down.svg"
                  />
                </div>
                </a>
              </div>
              <a href="/partners">
              <div className={styles.component15}>
                <div className={styles.component4}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/partnership.svg"
                  />
                  <div className={styles.insertValue}>Partners</div>
                  <img
                    className={styles.dropdownDownIcon3}
                    alt=""
                    src="/assets/dropdown-down.svg"
                  />
                </div>
              </div>
              </a>
              <a href="/AdminSettings">
              <div className={styles.component4}>
                <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
                <div className={styles.insertValue}>Admin Management</div>
                <img
                  className={styles.editIcon}
                  alt=""
                  src="/assets/dropdown-down.svg"
                />
              </div>
              </a>
              <a href="/sms">
              <div className={styles.component4}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/message.svg"
                />
                <div className={styles.insertValue}>SMS</div>
                <img
                  className={styles.editIcon}
                  alt=""
                  src="/dropdowndown.svg"
                />
              </div>
              </a>
            </div>
          </div>
        </div>
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameChild}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.adminOfficer}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img className={styles.setting02Icon} alt="" src="/assets/setting-02.svg" />
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
