import React, { FunctionComponent, useState } from "react";
import { changePassword } from "../api"; // Import the changePassword function from the api.ts file
import { useHistory } from "react-router-dom"; // Assuming you're using React Router
import styles from "./ChangePassword.module.css";
import './trialglobal.css';

const ChangePassword: FunctionComponent = () => {
  const history = useHistory();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChangePassword = async () => {
    try {
      // Ensure newPassword and confirmPassword match before sending the request
      if (newPassword !== confirmPassword) {
        console.error("Passwords do not match");
        return;
      }

      // Call the changePassword function with the new password
      await changePassword(newPassword);

      // Password change successful, navigate the user to the login page
      history.push("/login");
    } catch (error) {
      // Handle password change error here
      console.error("Password change error:", error);
    }
  };

  return (
    <div className={styles.passwordRecovery5}>
      <div className={styles.buzzycashParent}>
        <b className={styles.buzzycash}>BUZZYCASH</b>
        <div className={styles.frameChild} />
      </div>
      <div className={styles.resetPasswordParent}>
        <div className={styles.resetPassword}>Reset Password</div>
        <div className={styles.changeYourPassword}>Change your password</div>
      </div>
      <div className={styles.ellipseParent}>
        <div className={styles.frameItem} />
        <div className={styles.buzzycash1}>BuzzyCash</div>
      </div>
      <div className={styles.inputField}>
        <button className={styles.insertValue} onClick={handleChangePassword}>
          Change Password
        </button>
      </div>
      <div className={styles.frameParent}>
        <input
          className={styles.frameInner}
          placeholder="Create New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <input
          className={styles.frameInner}
          placeholder="Confirm New Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
    </div>
  );
};

export default ChangePassword;
