import { FunctionComponent } from "react";
import { Link } from 'react-router-dom';
import styles from "./Cred.module.css";

const Cred: FunctionComponent = () => {
  return (
    <div className={styles.payout}>
      <div className={styles.managePayoutParent}>
        <div className={styles.managePayout}>MANAGE PAYOUT</div>
        <div className={styles.smallButtonParent}>
          <div className={styles.smallButton}>
            <div className={styles.subscribers}>Payout</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-2@2x.png"
            />
          </div>
          <div className={styles.smallButton1}>
            <div className={styles.div}>Payout History</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-1@2x.png"
            />
          </div>
        </div>
        <div className={styles.component10Parent}>
          <img
            className={styles.component10Icon}
            alt=""
            src="/assets/Component 10.svg"
          />
          <div className={styles.smallButtonGroup}>
            <div className={styles.smallButton2}>
              <div className={styles.div}>One Million Bag</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton2}>
              <div className={styles.div}>ChopChop Instant Win</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton2}>
              <div className={styles.div}>Mega Money</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
            <div className={styles.smallButton5}>
              <div className={styles.subscribers}>Instants 3/4</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-2@2x.png"
              />
            </div>
            <div className={styles.smallButton2}>
              <div className={styles.div}>Fast Cash</div>
              <img
                className={styles.smallButtonChild}
                alt=""
                src="/group-1@2x.png"
              />
            </div>
          </div>
          <img
            className={styles.component10Icon}
            alt=""
            src="/assets/Component 9.svg"
          />
        </div>
        <div className={styles.frameParent}>
          <div className={styles.frameGroup}>
            <div className={styles.wrapper}>
              <div className={styles.div}>27</div>
            </div>
            <div className={styles.janWrapper}>
              <div className={styles.div}>Jan</div>
            </div>
            <div className={styles.wrapper}>
              <div className={styles.div}>2023</div>
            </div>
          </div>
          <div className={styles.smallButtonWrapper}>
            <div className={styles.smallButton}>
              <div className={styles.subscribers7}>SUBSCRIBERS</div>
              <img
                className={styles.smallButtonChild4}
                alt=""
                src="/group-2@2x.png"
              />
            </div>
          </div>
        </div>
        <div className={styles.payoutsOnlyDisplay}>
          Payouts only display a list of winners; select a game and date, view
          all winners, and initiate payment.
        </div>
        <div className={styles.table}>
          <div className={styles.column}>
            <div className={styles.cellHeader}>
              <div className={styles.sn}>S/N</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>1</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>2</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>3</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>4</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>5</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>6</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>7</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>8</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>9</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>10</div>
            </div>
          </div>
          <div className={styles.column1}>
            <div className={styles.cellHeader1}>
              <div className={styles.sn}>Mobile Number</div>
            </div>
            <div className={styles.cellItem10}>
              <div className={styles.amountWon}>08100000001</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>08100000001</div>
              </div>
            </div>
          </div>
          <div className={styles.column2}>
            <div className={styles.cellHeader1}>
              <div className={styles.amountWon}>Amount won</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.amountWon}>₦500,000,00</div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
            <div className={styles.cellItem11}>
              <div className={styles.text}>
                <div className={styles.textValue}>₦500,000,00</div>
              </div>
            </div>
          </div>
          <div className={styles.column3}>
            <div className={styles.cellHeader3}>
              <div className={styles.amountWon}>Bank Account</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>Access Bank - 0000000001</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.sn}>{`Access Bank - 0000000001 `}</div>
            </div>
          </div>
          <div className={styles.column4}>
            <div className={styles.cellHeader4}>
              <div className={styles.amountWon}>Initiate Payment</div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton2}>
                <div className={styles.div}>Initiate payment</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton2}>
                <div className={styles.div}>Initiate payment</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton2}>
                <div className={styles.div}>Initiate payment</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton2}>
                <div className={styles.div}>Initiate payment</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton2}>
                <div className={styles.div}>Initiate payment</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton2}>
                <div className={styles.div}>Initiate payment</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton2}>
                <div className={styles.div}>Initiate payment</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton2}>
                <div className={styles.div}>Initiate payment</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton2}>
                <div className={styles.div}>Initiate payment</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
            <div className={styles.cellItem}>
              <div className={styles.smallButton2}>
                <div className={styles.div}>Initiate payment</div>
                <img
                  className={styles.smallButtonChild}
                  alt=""
                  src="/group-1@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.previousParent}>
          <div className={styles.amountWon}>Previous</div>
          <div className={styles.next}>Next</div>
          <div className={styles.last}>Last</div>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.frameContainer}>
            <div className={styles.leftIconParent}>
              <img className={styles.leftIcon} alt="" src="/lefticon@2x.png" />
              <div className={styles.text18}>
                <div className={styles.textValue}>Search</div>
              </div>
            </div>
            <img className={styles.rightIcon} alt="" src="/righticon@2x.png" />
          </div>
        </div>
      </div>
      <div className={styles.payoutChild} />
      <div className={styles.payoutItem} />
      <div className={styles.payoutInner} />
      <div className={styles.pause}>
        <div className={styles.pauseChild} />
        <Link to="/ManagePayout">
        <img className={styles.xCloseIcon1} alt="" src="/xclose@2x.png" />
        </Link>
        <div className={styles.enterAdminCredentials}>
          Enter Admin Credentials
        </div>
        <div className={styles.inputFieldParent}>
            <Link to="/Success">
          <div className={styles.inputField}>
            <img className={styles.editIcon} alt="" src="/edit@2x.png" />
            <div className={styles.amountWon}>Continue</div>
          </div>
          </Link>
          <Link to='/ManagePayout'>
          <div className={styles.inputField1}>
            <img className={styles.editIcon} alt="" src="/edit@2x.png" />
            <div className={styles.amountWon}>Cancel</div>
          </div>
          </Link>
        </div>
        <div className={styles.inputFielsParent}>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue}>
              <div className={styles.username}>Username</div>
            </div>
            <div className={styles.inputField2}>
              <div className={styles.leftIconGroup}>
                <img
                  className={styles.leftIcon1}
                  alt=""
                  src="/lefticon@2x.png"
                />
                <div className={styles.text19}>
                  <div className={styles.textValue}>Enter admin username</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputFielsGroup}>
            <div className={styles.inputFiels1}>
              <div className={styles.headerValue1}>
                <div className={styles.username}>Password</div>
              </div>
              <div className={styles.inputField3}>
                <div className={styles.frameDiv}>
                  <div className={styles.leftIconContainer}>
                    <img
                      className={styles.leftIcon1}
                      alt=""
                      src="/lefticon@2x.png"
                    />
                    <div className={styles.text20}>
                      <div className={styles.textValue}>
                        Enter admin password
                      </div>
                    </div>
                  </div>
                  <img
                    className={styles.rightIcon1}
                    alt=""
                    src="/righticon@2x.png"
                  />
                </div>
              </div>
            </div>
            <div className={styles.forgottenYourPasswordContainer}>
              <span
                className={styles.forgottenYourPassword}
              >{`Forgotten your password? `}</span>
              <b className={styles.resetNow}>
                <span>Reset now</span>
                <span className={styles.span}>{` `}</span>
              </b>
            </div>
          </div>
        </div>
        <div
          className={styles.toCompleteThis}
        >{`To complete this payment you must enter your admin credentials `}</div>
      </div>
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/dashboard.svg"
            />
            <div className={styles.amountWon}>Dashboard</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/user-02.svg" />
            <div className={styles.amountWon}>Subscribers</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.amountWon}>Subscriptions</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          <div className={styles.gm}>
            <div className={styles.component6}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.subscribers}>Game Management</div>
              <img
                className={styles.dropdownUpIcon}
                alt=""
                src="/assets/dropdown-up.svg"
              />
            </div>
            <div className={styles.subNav}>
              <div className={styles.subNavComponents}>
                <div className={styles.leftIconContainer}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/Component 5.svg"
                  />
                  <div className={styles.amountWon}>Manage Games</div>
                </div>
              </div>
              <div className={styles.subNavComponents}>
                <div className={styles.leftIconContainer}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/shuffle.svg"
                  />
                  <div className={styles.amountWon}>Manage Draws</div>
                </div>
              </div>
              <div className={styles.subNavComponents}>
                <div className={styles.leftIconContainer}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/history.svg"
                  />
                  <div className={styles.amountWon}>Draw History</div>
                </div>
              </div>
              <div className={styles.subNavComponents}>
                <div className={styles.leftIconContainer}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/Component 6.svg"
                  />
                  <div className={styles.amountWon}>Manage Payout</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/partnership.svg"
            />
            <div className={styles.amountWon}>Partners</div>
            <img
              className={styles.dropdownUpIcon}
              alt=""
              src="/assets/dropdown-down.svg"
            />
          </div>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
            <div className={styles.amountWon}>Admin Management</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/assets/dropdown-down.svg"
            />
          </div>
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/message.svg"
            />
            <div className={styles.amountWon}>SMS</div>
            <img
              className={styles.editIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
        </div>
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameChild}
              alt=""
              src="/assets/Rectangle 342.svg"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.div}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img
            className={styles.setting02Icon}
            alt=""
            src="/assets/setting-02.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default Cred;
