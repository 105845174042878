import { FunctionComponent, useCallback } from "react";
import styles from "./GameInformation.module.css";
//import './globalGI.css'
import { Link } from 'react-router-dom';


const GameInformation: FunctionComponent = () => {
  const onFrameContainer3Click = useCallback(() => {
    // Please sync "game information-complete" to the project
  }, []);

  const onFrameContainer17Click = useCallback(() => {
    // Please sync "game management" to the project
  }, []);

  const onInputFieldContainer1Click = useCallback(() => {
    // Please sync "game information" to the project
  }, []);

  const onInputFieldContainer2Click = useCallback(() => {
    // Please sync "game information" to the project
  }, []);

  const onFrameContainer22Click = useCallback(() => {
    // Please sync "game information" to the project
  }, []);

  return (
    <div className={styles.gameInformation}>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.generalInformationParent}>
            <div className={styles.generalInformation}>General Information</div>
            <div className={styles.frameContainer}>
              <div className={styles.scheduleContentWrapper}>
                <div className={styles.scheduleContent}>
                  <div className={styles.game}>Game</div>
                  <div className={styles.time}>
                    <div className={styles.time1}>
                      <div className={styles.oneMillionDollar}>
                        One million dollar bag
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.scheduleContentContainer}>
                <div className={styles.scheduleContent1}>
                  <div className={styles.game}>Date and time initiated</div>
                  <div className={styles.date}>
                    <div className={styles.time1}>
                      <div className={styles.oneMillionDollar}>27</div>
                      <div className={styles.th}>th</div>
                    </div>
                    <div className={styles.oneMillionDollar}>
                      January 2023, 9:00pm
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.scheduleContentWrapper}>
                <div className={styles.scheduleContent}>
                  <div className={styles.game}>Recurrence</div>
                  <div className={styles.time}>
                    <div className={styles.time1}>
                      <div className={styles.oneMillionDollar}>Daily</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={styles.scheduleContentParent}
                onClick={onFrameContainer3Click}
              >
                <div className={styles.scheduleContent}>
                  <div className={styles.game}>Active players</div>
                  <div className={styles.time}>
                    <div className={styles.time1}>
                      <div className={styles.oneMillionDollar}>50</div>
                    </div>
                  </div>
                </div>
                <img className={styles.moreIcon} alt="" src="/assets/more.svg" />
              </div>
            </div>
          </div>
          <div className={styles.frameChild} />
          <div className={styles.generalInformationParent}>
            <div className={styles.generalInformation}>
              Financial information
            </div>
            <div className={styles.frameDiv}>
              <div className={styles.scheduleContentWrapper}>
                <div className={styles.scheduleContent}>
                  <div className={styles.totalAmountIn}>
                    Total amount in game
                  </div>
                  <div className={styles.time6}>
                    <div className={styles.time1}>
                      <div className={styles.oneMillionDollar}>₦500,000,00</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.scheduleContentWrapper}>
                <div className={styles.scheduleContent}>
                  <div className={styles.game}>Maximum amount per pool</div>
                  <div className={styles.time6}>
                    <div className={styles.time1}>
                      <div className={styles.oneMillionDollar}>
                        ₦1,000,000,00
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.scheduleContentWrapper}>
                <div className={styles.scheduleContent}>
                  <div className={styles.game}>Winning %</div>
                  <div className={styles.time6}>
                    <div className={styles.time1}>
                      <div className={styles.oneMillionDollar}>20%</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.scheduleContentWrapper}>
                <div className={styles.scheduleContent}>
                  <div className={styles.game}>Current amount in pool</div>
                  <div className={styles.time6}>
                    <div className={styles.time1}>
                      <div className={styles.oneMillionDollar}>₦500,000,00</div>
                    </div>
                  </div>
                </div>
                <img
                  className={styles.moreIcon}
                  alt=""
                  src="/assets/chevron-arrow-down.svg"
                />
              </div>
            </div>
          </div>
          <div className={styles.frameChild} />
          <div className={styles.generalInformationParent}>
            <div className={styles.generalInformation}>Game attributes</div>
            <div className={styles.frameParent1}>
              <div className={styles.scheduleContentWrapper}>
                <div className={styles.scheduleContent}>
                  <div className={styles.game}>Game type</div>
                  <div className={styles.time}>
                    <div className={styles.time1}>
                      <div className={styles.oneMillionDollar}>
                        Multiple pool
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.scheduleContentWrapper}>
                <div className={styles.scheduleContent}>
                  <div className={styles.game}>Number of players per pool</div>
                  <div className={styles.time}>
                    <div className={styles.time1}>
                      <div className={styles.oneMillionDollar}>100,000</div>
                    </div>
                  </div>
                </div>
                <img
                  className={styles.moreIcon}
                  alt=""
                  src="/assets/chevron-arrow-down.svg"
                />
              </div>
              <div className={styles.scheduleContentWrapper}>
                <div className={styles.scheduleContent}>
                  <div className={styles.game}>Current number of players</div>
                  <div className={styles.time}>
                    <div className={styles.time1}>
                      <div className={styles.oneMillionDollar}>200</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.scheduleContentWrapper}>
                <div className={styles.scheduleContent}>
                  <div className={styles.game}>Number of pools</div>
                  <div className={styles.time}>
                    <div className={styles.time1}>
                      <div className={styles.oneMillionDollar}>4</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameParent2}>
          <div
            className={styles.arrowLeftLineParent}
            onClick={onFrameContainer17Click}
          >
            <Link to="/ManageGame">
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/assets/arrow-left-line.svg"
            />
            </Link>
            <div className={styles.goBack}>Go back</div>
          </div>
          <div className={styles.gameManagement}>{`GAME MANAGEMENT `}</div>
        </div>
        <div className={styles.inputFieldParent}>
          <div className={styles.inputField}>
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/assets/pen.svg"
            />
            <div className={styles.goBack}>Edit game</div>
          </div>

          <div
            className={styles.inputField1}
            onClick={onInputFieldContainer1Click}
          >
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/assets/pause.svg"
            />
            <div className={styles.goBack}>Pause game</div>
          </div>
      
          <div
            className={styles.inputField2}
            onClick={onInputFieldContainer2Click}
          >
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/assets/terminate.svg"
            />
            <div className={styles.goBack}>Terminate game</div>
          </div>
        </div>
        <div className={styles.scheduledContent}>
          <div className={styles.adminOfficerParent}>
            <div className={styles.game}>Status</div>
            <div className={styles.time22}>
              <div className={styles.time1}>
                <div className={styles.oneMillionDollar}>In Progress</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.component9variant3Parent}>
          <img
            className={styles.component9variant3Icon}
            alt=""
            src="/assets/Component 10.svg"
          />
          <div className={styles.frameParent3}>
            <div className={styles.yesterdaysGameParent}>
              <div className={styles.yesterdaysGame}>Yesterday’s game</div>
              <div className={styles.div9}>27/01/2023</div>
              <div className={styles.frameInner} />
            </div>
            <div
              className={styles.frameParent4}
              onClick={onFrameContainer22Click}
            >
              <div className={styles.todaysGameParent}>
                <div className={styles.todaysGame}>Today’s game</div>
                <div className={styles.div10}>28/01/2023</div>
              </div>
              <img
                className={styles.arrowLeftLineIcon}
                alt=""
                src="/assets/chevron-arrow-down.svg"
              />
            </div>
            <div className={styles.yesterdaysGameParent}>
              <div className={styles.yesterdaysGame}>Tomorrow’s game</div>
              <div className={styles.div11}>29/01/2023</div>
              <div className={styles.rectangleDiv} />
            </div>
          </div>
          <img
            className={styles.component9variant3Icon}
            alt=""
            src="/assets/Component 9.svg"
          />
        </div>
      </div>
      <div className={styles.gameInformationChild} />
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
            <a href="/dashboard">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/dashboard.svg"
            />
            <div className={styles.goBack}>Dashboard</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/subscribers">
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/user-02.svg" />
            <div className={styles.goBack}>Subscribers</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/subscriptions">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.goBack}>Subscriptions</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <div className={styles.gm}>
            <div className={styles.component6}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.game}>Game Management</div>
              <img
                className={styles.dropdownDownIcon}
                alt=""
                src="/assets/dropdown-down.svg"
              />
            </div>
            <div className={styles.subNav}>
              <div className={styles.subNavComponents}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/Component 5.svg"
                />
                <div className={styles.todaysGame}>Manage Games</div>
              </div>
              <a href="/ManageDraw">
              <div className={styles.subNavComponents1}>
                <div className={styles.shuffleParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/shuffle.svg"
                  />
                  <div className={styles.goBack}>Manage Draws</div>
                </div>
              </div>
              </a>
              <a href="DrawHistory">
              <div className={styles.subNavComponents1}>
                <div className={styles.shuffleParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/history.svg"
                  />
                  <div className={styles.goBack}>Draw History</div>
                </div>
              </div>
              </a>
              <a href="ManagePayout">
              <div className={styles.subNavComponents1}>
                <div className={styles.shuffleParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/Component 6.svg"
                  />
                  <div className={styles.goBack}>Manage Payout</div>
                </div>
              </div>
              </a>
            </div>
          </div>
          <a href="/partners"> 
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/partnership.svg"
            />
            <div className={styles.goBack}>Partners</div>
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/assets/dropdown-down.svg"
            />
          </div>
          </a>
          <a href="/AdminSettings"> 
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
            <div className={styles.goBack}>Admin Management</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
          <a href="/sms">
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/message.svg"
            />
            <div className={styles.goBack}>SMS</div>
            <img
              className={styles.dropdownDownIcon}
              alt=""
              src="/dropdowndown@2x.png"
            />
          </div>
          </a>
        </div>
        <a href="/AdminProfile">
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.rectangleIcon}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.oneMillionDollar}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img
            className={styles.setting02Icon}
            alt=""
            src="/assets/setting-02.svg"
          />
        </div>
        </a>
      </div>
    </div>
  );
};

export default GameInformation;
