import React, { useState } from "react";
import { useHistory } from "react-router-dom";
//import { forgotPassword } from "../api"; // Import the forgotPassword function from the api.ts file
import styles from "./ForgotPassword.module.css";
import { sendVerificationCode } from '../api'; 
//import './global1.css';

const ForgotPassword = () => {
  const history = useHistory();
  const [adminId, setAdminId] = useState("");
  const [resetRequestSent, setResetRequestSent] = useState(false);

  const handleSendCode = async () => {
    try {
      // Call the API to send the verification code
      await sendVerificationCode(adminId);

      // Password reset request successful, update state to indicate request sent
      setResetRequestSent(true);

      // Redirect to the SendCode page after sending the verification code
      history.push("/SendCode");
    } catch (error) {
      // Handle password reset request error here
      console.error('Verification code sending error:', error);
    }
  };

  return (
    <div className={styles.passwordRecovery1}>
      <div className={styles.buzzycashParent}>
        <b className={styles.buzzycash}>BUZZYCASH</b>
        <div className={styles.frameChild} />
      </div>
      <div className={styles.forgottenPasswordParent}>
        <div className={styles.forgottenPassword}>Forgotten Password</div>
        <div className={styles.inputYourAdmin}>
          Input your Admin ID to receive your verification code
        </div>
      </div>
      <div className={styles.passwordRecovery1Inner}>
        <input
          className={styles.frameItem}
          placeholder="Admin ID"
          type="text"
          value={adminId} // Use adminId state variable here
          onChange={(e) => setAdminId(e.target.value)} // Update adminId when the input changes
        />
      </div>
      <div className={styles.ellipseParent}>
        <div className={styles.frameInner} />
        <div className={styles.insertValue}>BuzzyCash</div>
      </div>
      <button className={styles.inputField} onClick={handleSendCode}>
        <div className={styles.insertValue}>Send Code</div>
      </button>

      {resetRequestSent && <p>Verification code sent! Check your email for further instructions.</p>}
    </div>
  );
};

export default ForgotPassword;
