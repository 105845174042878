import { FunctionComponent, useCallback } from "react";
import { Link } from 'react-router-dom';
import styles from "./Security.module.css";

const Security: FunctionComponent = () => {
  const onFrameContainer3Click = useCallback(() => {
    // Please sync "settings" to the project
  }, []);

  return (
    <div className={styles.settings}>
      <div className={styles.profileSettingsParent}>
        <div className={styles.profileSettings}>PROFILE SETTINGS</div>
        <div className={styles.smallButtonParent}>
            <Link to="/AdminProfile">
          <div className={styles.smallButton}>
            <div className={styles.subscribers}>General Settings</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-1.svg"
            />
          </div>
          </Link>
          <div className={styles.smallButton1}>
            <div className={styles.subscribers1}>Security Settings</div>
            <img
              className={styles.smallButtonChild}
              alt=""
              src="/group-2.svg"
            />
          </div>
        </div>
        <div className={styles.inputFielsWrapper}>
          <div className={styles.inputFiels}>
            <div className={styles.headerValue}>
              <div
                className={styles.currentPassword}
              >{`Current Password  `}</div>
            </div>
            <div className={styles.inputField}>
              <div className={styles.leftIconParent}>
                <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                <div className={styles.text}>
                  <div className={styles.div}>***********************</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link to="/PasswordChange">
        <div
          className={styles.changePasswordWrapper}
          onClick={onFrameContainer3Click}
        >
          <div className={styles.changePassword}>Change Password</div>
        </div>
        </Link>
      </div>
      <div className={styles.settingsChild} />
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.navbarInner}>
          <div className={styles.frameWrapper}>
            <div className={styles.component4Parent}>
                <a href="/dashboard">
              <div className={styles.component4}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/dashboard.svg"
                />
                <div className={styles.dashboard}>Dashboard</div>
                <img
                  className={styles.dropdownDownIcon}
                  alt=""
                  src="/dropdowndown.svg"
                />
              </div>
              </a>
              <a href="/subscribers">
              <div className={styles.component4}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/user-02.svg"
                />
                <div className={styles.dashboard}>Subscribers</div>
                <img
                  className={styles.dropdownDownIcon}
                  alt=""
                  src="/dropdowndown.svg"
                />
              </div>
              </a>
              <a href="/subscriptions">
              <div className={styles.component4}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/subscription.svg"
                />
                <div className={styles.dashboard}>Subscriptions</div>
                <img
                  className={styles.dropdownDownIcon}
                  alt=""
                  src="/dropdowndown.svg"
                />
              </div>
              </a>
              <a href="/ManageGames">
              <div className={styles.gm}>
                <div className={styles.component4}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/Component 5.svg"
                  />
                  <div className={styles.dashboard}>Game Management</div>
                  <img
                    className={styles.dropdownDownIcon3}
                    alt=""
                    src="/assets/dropdown-down.svg"
                  />
                </div>
              </div>
              </a>
              <div className={styles.component15}>
                <a href="/partners">
                <div className={styles.component4}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/partnership.svg"
                  />
                  <div className={styles.dashboard}>Partners</div>
                  <img
                    className={styles.dropdownDownIcon3}
                    alt=""
                    src="/assets/dropdown-down.svg"
                  />
                </div>
                </a>
              </div>
              <a href="/AdminSettings">
              <div className={styles.component4}>
                <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
                <div className={styles.dashboard}>Admin Management</div>
                <img
                  className={styles.dropdownDownIcon}
                  alt=""
                  src="/assets/dropdown-down.svg"
                />
              </div>
              </a>
              <a href="/sms">
              <div className={styles.component4}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/message.svg"
                />
                <div className={styles.dashboard}>SMS</div>
                <img
                  className={styles.dropdownDownIcon}
                  alt=""
                  src="/dropdowndown.svg"
                />
              </div>
              </a>
            </div>
          </div>
        </div>
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameChild}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.subscribers}>ADMIN OFFICER</div>
              <b className={styles.gilbertAdams}>Gilbert Adams</b>
            </div>
          </div>
          <img className={styles.setting02Icon} alt="" src="/assets/setting-02.svg" />
        </div>
      </div>
    </div>
  );
};

export default Security;
