import { FunctionComponent, useCallback } from "react";
import styles from "./Paid.module.css";
import { Link } from 'react-router-dom';

const Paid: FunctionComponent = () => {
  const onFrameContainerClick = useCallback(() => {
    // Please sync "draw" to the project
  }, []);

  const onInputFieldContainer6Click = useCallback(() => {
    // Please sync "payout history" to the project
  }, []);

  const onInputFieldContainer7Click = useCallback(() => {
    // Please sync "partners" to the project
  }, []);

  return (
    <div className={styles.partners}>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div
            className={styles.arrowLeftLineParent}
            onClick={onFrameContainerClick}
          >
            <img
              className={styles.arrowLeftLineIcon}
              alt=""
              src="/assets/arrow-left-line.svg"
            />
            <div className={styles.goBack}>Go back</div>
          </div>
          <div className={styles.partners1}>PARTNERS</div>
        </div>
        <div className={styles.frameContainer}>
          <div className={styles.frameDiv}>
            <div className={styles.payingPartnersParent}>
              <div className={styles.payingPartners}>Paying Partners</div>
              <div className={styles.sendMoneyTo}>
                Send money to BuzzyCash Partners with ease
              </div>
            </div>
            <div className={styles.exxonmobilAccountBalanceParent}>
              <div className={styles.exxonmobilAccountBalance}>
                ExxonMobil Account Balance
              </div>
              <div className={styles.wallet}>
                <div className={styles.walletChild} />
                <img
                  className={styles.wallet01Icon}
                  alt=""
                  src="/assets/wallet-01.svg"
                />
                <div className={styles.div}>₦500,000,00</div>
              </div>
            </div>
          </div>
          <div className={styles.inputFielsParent}>
            <div className={styles.inputFiels}>
              <div className={styles.headerValue}>
                <div className={styles.bank}>Bank</div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.leftIconParent}>
                  <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                  <div className={styles.text}>
                    <div className={styles.sterlingBank}>Sterling Bank</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.payingPartnersParent}>
              <div className={styles.headerValue1}>
                <div className={styles.bank}>Account Number</div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.leftIconParent}>
                  <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                  <div className={styles.text}>
                    <div className={styles.sterlingBank}>0000000001</div>
                  </div>
                </div>
                <div className={styles.exxonmobil}>ExxonMobil</div>
              </div>
            </div>
            <div className={styles.inputFiels}>
              <div className={styles.headerValue2}>
                <div className={styles.bank}>Amount to Send</div>
              </div>
              <div className={styles.inputField2}>
                <div className={styles.inputFieldChild} />
                <div className={styles.div2}>₦</div>
                <div className={styles.div3}>500,000,00</div>
              </div>
            </div>
            <div className={styles.payingPartnersParent}>
              <div className={styles.headerValue3}>
                <div className={styles.bank}>Narration (Optional)</div>
              </div>
              <div className={styles.inputField3}>
                <div className={styles.leftIconParent}>
                  <img className={styles.leftIcon} alt="" src="/lefticon.svg" />
                  <div className={styles.text2}>
                    <div className={styles.sterlingBank}>
                      Enter short narration
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.partnersChild} />
      <div className={styles.inputFieldParent}>
        <div className={styles.inputField4}>
          <img className={styles.editIcon} alt="" src="/edit.svg" />
          <div className={styles.goBack}>Send Money</div>
        </div>
        <div className={styles.inputField5}>
          <img className={styles.editIcon} alt="" src="/edit.svg" />
          <div className={styles.goBack}>{`Cancel `}</div>
        </div>
      </div>
      <div className={styles.navbar}>
        <div className={styles.buzzycash}>BuzzyCash</div>
        <div className={styles.component4Parent}>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/dashboard.svg" />
            <div className={styles.goBack}>Dashboard</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/user-02.svg" />
            <div className={styles.goBack}>Subscribers</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
          <div className={styles.component4}>
            <img
              className={styles.dashboardIcon}
              alt=""
              src="/assets/subscription.svg"
            />
            <div className={styles.goBack}>Subscriptions</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
          <div className={styles.gm}>
            <div className={styles.component4}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/Component 5.svg"
              />
              <div className={styles.goBack}>Game Management</div>
              <img
                className={styles.arrowLeftLineIcon}
                alt=""
                src="/assets/dropdown-up.svg"
              />
            </div>
          </div>
          <div className={styles.component15}>
            <div className={styles.component14}>
              <img
                className={styles.dashboardIcon}
                alt=""
                src="/assets/partnership.svg"
              />
              <div className={styles.dashboard4}>Partners</div>
              <img
                className={styles.arrowLeftLineIcon}
                alt=""
                src="/assets/dropdown-up.svg"
              />
            </div>
            <div className={styles.subComponents}>
              <div className={styles.subNavComponents}>
                <img
                  className={styles.checklistIcon}
                  alt=""
                  src="/assets/checklist.svg"
                />
                <div className={styles.dashboard5}>View All Partners</div>
              </div>
              <div className={styles.subNavComponents1}>
                <div className={styles.addUserParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/add-user.svg"
                  />
                  <div className={styles.goBack}>Add Partner</div>
                </div>
              </div>
              <div className={styles.subNavComponents1}>
                <div className={styles.addUserParent}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/history.svg"
                  />
                  <div className={styles.goBack}>Payout History</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
            <div className={styles.goBack}>Admin Management</div>
            <img className={styles.editIcon} alt="" src="/assets/dropdown-down.svg" />
          </div>
          <div className={styles.component4}>
            <img className={styles.dashboardIcon} alt="" src="/assets/message.svg" />
            <div className={styles.goBack}>SMS</div>
            <img className={styles.editIcon} alt="" src="/dropdowndown.svg" />
          </div>
        </div>
        <div className={styles.component81}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameChild}
              alt=""
              src="/assets/Rectangle 342.png"
            />
            <div className={styles.adminOfficerParent}>
              <div className={styles.payingPartners}>ADMIN OFFICER</div>
              <b className={styles.sendMoneyTo}>Gilbert Adams</b>
            </div>
          </div>
          <img className={styles.setting02Icon} alt="" src="/assets/setting-02.svg" />
        </div>
      </div>
      <div className={styles.partnersItem} />
      <div className={styles.pause}>
        <div className={styles.pauseChild} />
        <img className={styles.xCloseIcon} alt="" src="/xclose.svg" />
        <img
          className={styles.verificationIcon}
          alt=""
          src="/assets/verification.svg"
        />
        <div className={styles.transactionProcessedSuccessf}>
          Transaction processed successfully!
        </div>
        <div className={styles.thisTransactionHas}>
          This transaction has been complete successfully, Receipt can be
          generated in the “payout history” tab.
        </div>
        <div className={styles.inputFieldGroup}>
          <div
            className={styles.inputField6}
            onClick={onInputFieldContainer6Click}
          >
            <img className={styles.editIcon} alt="" src="/edit.svg" />
            <div className={styles.goBack}>Go to Receipt</div>
          </div>
          <Link to="/partners">
          <div
            className={styles.inputField7}
            onClick={onInputFieldContainer7Click}
          >
            <img className={styles.editIcon} alt="" src="/edit.svg" />
            <div className={styles.goBack}>Go to Partner Page</div>
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Paid;
