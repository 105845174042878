import { FunctionComponent } from "react";
//import Historymodal from "./Historymodal";
//import PortalPopup from "./PortalPopup";
import styles from "./History1.module.css";
import './globalH.css'

const History1: FunctionComponent = () => {

  return (
    <>
      <div className={styles.history}>
        <div className={styles.dashboardItemsParent}>
          <div className={styles.dashboardItems}>
            <div className={styles.frameParent}>
              <div className={styles.totalNumberOfDrawsParent}>
                <div className={styles.totalNumberOf}>
                  Total NUMBER OF DRAWS
                </div>
                <div className={styles.div}>20,000</div>
              </div>
              <img className={styles.shuffleIcon} alt="" src="/assets/user-01.svg" />
            </div>
          </div>
          <div className={styles.export}>
            <div className={styles.exportAsCsv}>Export draw history.csv</div>
          </div>
          <div className={styles.tableWrapper}>
            <div className={styles.table}>
              <div className={styles.column}>
                <div className={styles.cellHeader}>
                  <div className={styles.sn}>S/N</div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>1</div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>2</div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>3</div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>4</div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>5</div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>6</div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>7</div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>8</div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>9</div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>10</div>
                </div>
              </div>
              <div className={styles.column1}>
                <div className={styles.cellHeader}>
                  <div className={styles.sn}>Date/time</div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>
                    <p className={styles.p}>12/01/2023</p>
                    <p className={styles.p}>9:23pm</p>
                  </div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>
                    <p className={styles.p}>12/01/2023</p>
                    <p className={styles.p}>9:23pm</p>
                  </div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>
                    <p className={styles.p}>12/01/2023</p>
                    <p className={styles.p}>9:23pm</p>
                  </div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>
                    <p className={styles.p}>12/01/2023</p>
                    <p className={styles.p}>9:23pm</p>
                  </div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>
                    <p className={styles.p}>12/01/2023</p>
                    <p className={styles.p}>9:23pm</p>
                  </div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>
                    <p className={styles.p}>12/01/2023</p>
                    <p className={styles.p}>9:23pm</p>
                  </div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>
                    <p className={styles.p}>12/01/2023</p>
                    <p className={styles.p}>9:23pm</p>
                  </div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>
                    <p className={styles.p}>12/01/2023</p>
                    <p className={styles.p}>9:23pm</p>
                  </div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>
                    <p className={styles.p}>12/01/2023</p>
                    <p className={styles.p}>9:23pm</p>
                  </div>
                </div>
                <div className={styles.cellItem}>
                  <div className={styles.sn}>
                    <p className={styles.p}>12/01/2023</p>
                    <p className={styles.p}>9:23pm</p>
                  </div>
                </div>
              </div>
              <div className={styles.column2} >
                <div className={styles.cellHeader2}>
                  <div className={styles.sn}>Game type</div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>Mega Money</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>Instant 3/4</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>Instant 3/4</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>On Million Bag</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>Mega Money</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>Mega Money</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>Mega Money</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>Instant 3/4</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>Instant 3/4</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>ChopChop Instant win</div>
                  </div>
                </div>
              </div>
              <div className={styles.column3}>
                <div className={styles.cellHeader2}>
                  <div className={styles.sn}>Number of active players</div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
              </div>
              <div className={styles.column4}>
                <div className={styles.cellHeader2}>
                  <div className={styles.sn}>Pool Type</div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.exportAsCsv}>Single</div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>50</div>
                  </div>
                </div>
              </div>
              <div className={styles.column5}>
                <div className={styles.cellHeader2}>
                  <div className={styles.sn}>Number of pools</div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.exportAsCsv}>Single</div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.exportAsCsv}>Single</div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.exportAsCsv}>Multiple</div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.exportAsCsv}>Multiple</div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.exportAsCsv}>Single</div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.exportAsCsv}>Multiple</div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.exportAsCsv}>Single</div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.exportAsCsv}>Multiple</div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.exportAsCsv}>Multiple</div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.exportAsCsv}>Single</div>
                </div>
              </div>
              <div className={styles.column6}>
                <div className={styles.cellHeader2}>
                  <div className={styles.sn}>Number of draw winners</div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>2</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>2</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>2</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>2</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>2</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>2</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>2</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>2</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>2</div>
                  </div>
                </div>
                <div className={styles.cellItem20}>
                  <div className={styles.text}>
                    <div className={styles.div11}>2</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.previousParent}>
            <div className={styles.exportAsCsv}>Previous</div>
            <div className={styles.next}>Next</div>
            <div className={styles.last}>Last</div>
          </div>
          <div className={styles.showing12Of10000000EntriWrapper}>
            <div className={styles.exportAsCsv}>
              Showing 12 of 10,000,000 entries
            </div>
          </div>
          <div className={styles.select}>
            <div className={styles.component10Wrapper}>
              <img
                className={styles.component10Icon}
                alt=""
                src="/assets/Component 10.svg"
              />
            </div>
            <div className={styles.selectInner}>
              <div className={styles.smallButtonParent}>
                <div className={styles.smallButton}>
                  <div className={styles.subscribers}>All</div>
                  <img
                    className={styles.smallButtonChild}
                    alt=""
                    src="/group-2.svg"
                  />
                </div>
                <div className={styles.smallButton1}>
                  <div className={styles.adminOfficer}>One million bag</div>
                  <img
                    className={styles.smallButtonChild}
                    alt=""
                    src="/group-1.svg"
                  />
                </div>
                <div className={styles.smallButton2}>
                  <div className={styles.adminOfficer}>
                    ChopChop Instant Win
                  </div>
                  <img
                    className={styles.smallButtonChild}
                    alt=""
                    src="/group-11.svg"
                  />
                </div>
                <div className={styles.smallButton2}>
                  <div className={styles.adminOfficer}>Mega Money</div>
                  <img
                    className={styles.smallButtonChild}
                    alt=""
                    src="/group-12.svg"
                  />
                </div>
                <div className={styles.smallButton2}>
                  <div className={styles.adminOfficer}>Instants 3/4</div>
                  <img
                    className={styles.smallButtonChild}
                    alt=""
                    src="/group-13.svg"
                  />
                </div>
                <div className={styles.smallButton2}>
                  <div className={styles.adminOfficer}>Fast Cash</div>
                  <img
                    className={styles.smallButtonChild}
                    alt=""
                    src="/group-14.svg"
                  />
                </div>
              </div>
            </div>
            <img
              className={styles.component10Icon}
              alt=""
              src="/assets/Component 9.svg"
            />
          </div>
          <div>
          <input
    type="text"
    className={styles.frameGroup} // Replace with your CSS class for the input field
    placeholder="Search"
  />
          </div>
          <div className={styles.drawHistory}>DRAW HISTORY</div>
        </div>
        <div className={styles.historyChild} />
        <div className={styles.navbar}>
          <div className={styles.buzzycash}>BuzzyCash</div>
          <div className={styles.component4Parent}>
          <a href="/dashboard">
  <div className={styles.component4}>
    <img
      className={styles.dashboardIcon}
      alt=""
      src="/assets/dashboard.svg"
    />
    <div className={styles.exportAsCsv}>Dashboard</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/dropdowndown.svg"
    />
  </div>
</a>

<a href="/subscribers">
  <div className={styles.component4}>
    <img
      className={styles.dashboardIcon}
      alt=""
      src="/assets/user-02.svg"
    />
    <div className={styles.exportAsCsv}>Subscribers</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/dropdowndown1.svg"
    />
  </div>
</a>

<a href="/subscriptions">
  <div className={styles.component4}>
    <img
      className={styles.dashboardIcon}
      alt=""
      src="/assets/subscription.svg"
    />
    <div className={styles.exportAsCsv}>Subscriptions</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/dropdowndown2.svg"
    />
  </div>
</a>

            <div className={styles.gm}>
              <div className={styles.component6}>
                <img
                  className={styles.dashboardIcon}
                  alt=""
                  src="/assets/Component 5.svg"
                />
                <div className={styles.subscribers}>Game Management</div>
                <img
                  className={styles.dropdownUpIcon}
                  alt=""
                  src="/assets/dropdown-down.svg"
                />
              </div>
              <div className={styles.subNav}>
                <div className={styles.subNavComponents}>
                <a href="/ManageGame">
  <div className={styles.component5Parent}>
    <img
      className={styles.dashboardIcon}
      alt=""
      src="/assets/Component 5.svg"
    />
    <div className={styles.exportAsCsv}>Manage Games</div>
  </div>
</a>

                </div>
                <div className={styles.subNavComponents}>
                <a href="/ManageDraw">
  <div className={styles.component5Parent}>
    <img
      className={styles.dashboardIcon}
      alt=""
      src="/assets/shuffle.svg"
    />
    <div className={styles.exportAsCsv}>Manage Draws</div>
  </div>
</a>

                </div>
                <div className={styles.subNavComponents2}>
                  <img
                    className={styles.dashboardIcon}
                    alt=""
                    src="/assets/history.svg"
                  />
                  <div className={styles.dashboard6}>Draw History</div>
                </div>
                <div className={styles.subNavComponents}>
                <a href="/ManagePayout">
  <div className={styles.component5Parent}>
    <img
      className={styles.dashboardIcon}
      alt=""
      src="/assets/Component 6.svg"
    />
    <div className={styles.exportAsCsv}>Manage Payout</div>
  </div>
</a>

                </div>
              </div>
            </div>
            <a href="/partners">
  <div className={styles.component4}>
    <img
      className={styles.dashboardIcon}
      alt=""
      src="/assets/partnership.svg"
    />
    <div className={styles.exportAsCsv}>Partners</div>
    <img
      className={styles.dropdownUpIcon}
      alt=""
      src="/assets/dropdown-down.svg"
    />
  </div>
</a>

<a href="/AdminSettings">
  <div className={styles.component4}>
    <img className={styles.dashboardIcon} alt="" src="/assets/admin.svg" />
    <div className={styles.exportAsCsv}>Admin Management</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/dropdowndown4.svg"
    />
  </div>
</a>

<a href="/sms">
  <div className={styles.component4}>
    <img className={styles.dashboardIcon} alt="" src="/assets/message.svg" />
    <div className={styles.exportAsCsv}>SMS</div>
    <img
      className={styles.dropdownDownIcon}
      alt=""
      src="/dropdowndown5.svg"
    />
  </div>
</a>

          </div>
          <a href="/AdminProfile">
          <div className={styles.component81}>
            <div className={styles.account}>ACCOUNT</div>
            <div className={styles.rectangleParent}>
              <img
                className={styles.frameChild}
                alt=""
                src="/assets/Rectangle 342.png"
              />
              <div className={styles.adminOfficerParent}>
                <div className={styles.adminOfficer}>ADMIN OFFICER</div>
                <b className={styles.gilbertAdams}>Gilbert Adams</b>
              </div>
            </div>
            <img className={styles.setting02Icon} alt="" src="/assets/setting-02.svg" />
          </div>
          </a>
        </div>
      </div>

    </>
  );
};

export default History1;
